import LocationComponent from '../Returns Components/LocationComponent';
import ManifestDate from '../Returns Components/ManifestDate';
import PriorityFillComponent from '../Returns Components/PriorityFillComponent';
import ReasonComponent from '../Returns Components/ReasonComponent';
import TruckISTField from '../Returns Components/TruckISTField';
import { GetReturnTypeResponse, Truck } from '../../Services/app.service';
import Utilities from '../Utils/Utilities';
import { useState } from 'react';

interface UnscheduledReturnsProps {
  returnTypeResponse: GetReturnTypeResponse;
  updateTruckEntry: (truck: Truck) => void;
  updateLocationEntry: (location: string) => void;
  updatePriorityFillEntry: (priority: string) => void;
  updateReasonEntry: (reason: string) => void;
}

function UnscheduledReturns(props: UnscheduledReturnsProps) {
  let [dateString, setDateString] = useState(props.returnTypeResponse.manifestDate);

  function updateTruckAndDate(truck: Truck) {
    props.updateTruckEntry(truck);
    if (truck) {
      setDateString(truck.manifestDate);
    }
  }

  return (
    <>
      <TruckISTField label="Truck IST" updateTruckEntry={updateTruckAndDate} />
      <LocationComponent updateLocation={props.updateLocationEntry} />
      <ReasonComponent updateReason={props.updateReasonEntry} />
      <PriorityFillComponent
        updatePriorityFill={props.updatePriorityFillEntry}
        returnTypeResponse={props.returnTypeResponse}
      />
      <ManifestDate manifestDateValue={dateString} />
    </>
  );
}
export default UnscheduledReturns;
