import { SessionManager } from '../component/SessionManager';
import AppService from './app.service';
import {
  IDType,
  POIDTwoDashValidationType,
  POIDOneDashValidationType,
  IDValidationType,
  EPCValidationType,
  SKUValidationType,
  PICKIDValidationType,
  ACK2EPCValidationType,
  EPCWithVendor,
} from './IDValidationTypes';

export interface ValidationResult {
  message: string;
  isValid: boolean;
}

export interface IDValidationResult {
  isValid: boolean;
  message: string;
  type: IDType;
  id: string;
}

let epcWithVendor = new EPCWithVendor();
let poidTwoDash = new POIDTwoDashValidationType();
let poidOneDash = new POIDOneDashValidationType();
let epc = new EPCValidationType();
let sku = new SKUValidationType();
let pickId = new PICKIDValidationType();
let ack2pec = new ACK2EPCValidationType();

let validationTypes = [
  epcWithVendor,
  poidTwoDash,
  poidOneDash,
  epc,
  sku,
  pickId,
  ack2pec,
];

class Validation {
  validateBOL(boltext: string): Promise<ValidationResult> {
    if (boltext.length === 0) {
      return Promise.resolve({ isValid: true, message: '' });
    }

    if (boltext.length > 15) {
      return Promise.resolve({ isValid: false, message: 'BOL exceeds 15 characters.' });
    }

    if (boltext.charAt(0).match(/[A-Za-z]/)) {
      return Promise.resolve({ isValid: true, message: '' });
    }

    let sessionManager: SessionManager = new SessionManager();
    const profitCenterID: string = sessionManager.getProfitCenterID();
    let appService: AppService = new AppService();
    return appService.validateBOL(profitCenterID, boltext).then((isValid: boolean) => {
      return { isValid: isValid, message: isValid ? '' : 'BOL is invalid.' };
    });
  }

  validateId(id: string): IDValidationResult {
    if (id.length > 24) {
      return {
        isValid: false,
        message: 'Id exceeds 24 characters.',
        type: IDType.INVALID,
        id: '',
      };
    }

    for (let index = 0; index < validationTypes.length; index++) {
      let type: IDValidationType = validationTypes[index];
      const currentType = type.isOfThisType(id);
      if (currentType.type !== IDType.INVALID) {
        return {
          isValid: currentType.isValid,
          message: currentType.message,
          type: currentType.type,
          id: currentType.isValid ? type.formattedID(id).toUpperCase() : '',
        };
      }
    }

    return { isValid: false, message: 'ID :Invalid', type: IDType.INVALID, id: '' };
  }

  validateLocation(location: string): Promise<ValidationResult> {
    if (location.length > 9) {
      return Promise.resolve({
        isValid: false,
        message: 'Location exceeds 9 characters.',
      });
    }
    const sessionManager = new SessionManager();
    const profitCenterID = sessionManager.getProfitCenterID();
    const appService = new AppService();
    return appService
      .validateLocation(profitCenterID, location)
      .then((isValid: boolean) => {
        if (isValid === true) {
          return { isValid: true, message: '' };
        } else {
          return { isValid: false, message: 'Location is invalid' };
        }
      });
  }

  validateExternalOrderLocation(location: string): ValidationResult {
    if (location.length < 1) {
      return {
        isValid: false,
        message: 'Location is required to Unload an item.',
      };
    }
    if (location.length > 10) {
      return {
        isValid: false,
        message: 'Location cannot exceed 10 characters.',
      };
    }

    return { isValid: true, message: '' };
  }

  validateExternalOrderNumber(externalOrderNumber: string): ValidationResult {
    if (externalOrderNumber.length > 25) {
      return {
        isValid: false,
        message: 'External Order Number exceeds\n 25 characters.',
      };
    }
    return { isValid: true, message: '' };
  }

  validateReceiveSKUFields(
    quantity: string,
    poNumber: string,
    sku: string
  ): ValidationResult {
    if (poNumber.length < 1) {
      return { isValid: false, message: 'Enter the PO' };
    }
    if (sku.match(/^[0-9]+-[0-9]{5,5}-[0-9]{1,1}/)) {
      return { isValid: true, message: '' };
    } else {
      return { isValid: false, message: 'Not a valid SKU' };
    }
  }

  validateTruck(truck: string): ValidationResult {
    if (truck.length > 15) {
      return {
        isValid: false,
        message: 'Truck exceeds 15 characters.',
      };
    } else {
      return {
        isValid: true,
        message: '',
      };
    }
  }

  validatePrintQuantity(quanity: string): ValidationResult {
    const validResult: ValidationResult = {
      isValid: true,
      message: '',
    };

    const invalidResult: ValidationResult = {
      isValid: false,
      message: 'Invalid Quantity',
    };

    if (quanity === '') {
      return validResult;
    }

    const quantityNumber: number = parseInt(quanity);

    if (isNaN(quantityNumber)) {
      return invalidResult;
    }

    if (/^[1-9]/.test(quanity)) {
      return validResult;
    } else {
      return invalidResult;
    }
  }
}

export default Validation;
