import axios, { AxiosResponse } from 'axios';
import { SessionManager } from '../component/SessionManager';
import { DateWrapper } from '../component/Utils/DateWrapper';
import Utilities from '../component/Utils/Utilities';
import { IDType } from './IDValidationTypes';

export class ReturnType {
  static ScheduledReturn: ReturnType = new ReturnType('ScheduledReturn');
  static UnscheduledReturn: ReturnType = new ReturnType('UnscheduledReturn');
  static InStoreTransfer: ReturnType = new ReturnType('InStoreTransfer');
  static NULL: ReturnType = new ReturnType('NULL');

  private typeName: string;

  constructor(name: string) {
    this.typeName = name;
  }

  toString() {
    return this.typeName.trim();
  }
}

export interface Printer {
  name: string;
  id?: string;
}

export interface ReturnReason {
  key: string;
  value: string;
}

export interface Vendor {
  upcCompany: string;
  vendorCode: string;
}

export interface ScanResponse {
  valid: boolean;
  error: string;
}

export interface UpdateReturnsResponse {
  valid: boolean;
  error: string;
}
export interface ValidateCycleCountLocationResponse {
  valid: boolean;
  stocklocationid: string;
  description: string;
  nailed: string;
  to: string;
}

type xmlNode = string | null | undefined;
export interface ValidateStockResponse {
  valid: boolean;
  error: string;
  stockid: string;
  polineitemlabel: string;
  vsn: string;
  resale: string;
  description: string;
  sourcestatus: string;
  salesstatus: string;
  acknum: string;
  epc: string;
  truck: string;
  stop: string;
  manifest_status: string;
  manifest_item_status: string;
  type: string;
  pickup: string;
  sealed: string;
  deliverytype: string;
  pickid: string;
  sku: string;
}

export interface ManifestLoadResponse {
  valid: boolean;
  error: string;
  epc: string;
  box: string;
  pickid: string;
  stockid: string;
  polineitemlabel: string;
  vsn: string;
  manifest_status: string;
  manifest_item_status: string;
  manifest_sealed: string;
  from: string;
  quantity: string;
}

export interface CheckPickIdResponse {
  valid: boolean;
  pickid: string;
  vsn: string;
  box: string;
  manifestStatus: string;
  manifestSealed: boolean;
  stop: string;
}

export interface GetReturnTypeResponse {
  valid: boolean;
  error: string;
  stockId: string;
  returnType: ReturnType | null;
  realReturnType: string;
  manifestDate: string;
  delBoxScheduled: string;
  delBoxId: string;
  delBoxDate: string;
  poid: string;
  vsn: string;
  epc: string;
  truck: string;
}

export interface GetTrucksResponse {
  valid: boolean;
  error: string;
  trucks: Truck[];
}

export interface CycleCountScanResponse {
  valid: boolean;
  epc: string;
  error: string;
}

export interface Truck {
  name: string;
  manifestDate: string;
}

export interface Ticket {
  soldid: string;
  ticketnbr: string;
  market: string;
  totalQty: string;
  qtyPickedUp: string;
}
export interface TicketResponse {
  tickets: Ticket[];
  valid: boolean;
  error: string;
}

export interface UpdateItemPickedUpResponse {
  valid: boolean;
  qtyPickedUp: string;
  totalQtyScheduledForPickup: string;
  error: string;
}

export interface ProfitCenterIdsResponse {
  dealerId: string;
  dealerName: string;
  profitCenterId: string;
  naturalStoreId: string;
}

class AppService {
  sessionManager: SessionManager;

  constructor() {
    this.sessionManager = new SessionManager();
  }

  validateBOL(profitCenterNumber: string, billOfLadingNumber: string): Promise<boolean> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Inventory/XMLValidateBOL.xdo`,
        {},
        {
          params: {
            pcno: profitCenterNumber.toUpperCase(),
            bol: billOfLadingNumber.toUpperCase(),
          },
          withCredentials: true,
        }
      )

      .then((responseData) => {
        let querySelectorValidateBOL = this.parseXmlResponse(responseData, 'validateBOL');
        let isValidBOLString: string | null | undefined =
          querySelectorValidateBOL?.getAttribute('valid');

        return isValidBOLString === 'true';
      });
  }

  getVendors(pcid: string): Promise<Vendor[]> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/XMLGetVendorCodes.xdo`,
        {},
        {
          params: { pcid: pcid.toUpperCase() },
          withCredentials: true,
        }
      )
      .then((responseData) => {
        const parser = new DOMParser();
        const xml: Document = parser.parseFromString(responseData['data'], 'text/xml');
        let vendorList: Vendor[] = [];
        xml.querySelectorAll('vendor')?.forEach((vendor: Element) => {
          let upcCompany: string | null = vendor.getAttribute('upccompany');
          let vendorCode: string | null = vendor.getAttribute('vendorcode');
          if (upcCompany && vendorCode) {
            vendorList.push({
              upcCompany: upcCompany,
              vendorCode: vendorCode,
            });
          }
        });
        return vendorList;
      });
  }

  public async login(
    username: string,
    password: string,
    storeNumber: string
  ): Promise<any> {
    let responseAsPromise = axios.post(
      `${this.sessionManager.getRMSEnvironment()}/Main/loginXML.xdo`,
      {},
      {
        params: { userName: username, userPass: password, pcno: storeNumber },
        withCredentials: true,
      }
    );
    return responseAsPromise;
  }

  public async getPrinterList(pcid: string): Promise<Printer[]> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/XMLPrinterList.xdo`,
        {},
        {
          params: { pcid: pcid.toUpperCase() },
          withCredentials: true,
        }
      )
      .then((responseData) => {
        const parser = new DOMParser();
        const xml: Document = parser.parseFromString(responseData['data'], 'text/xml');
        let printerList: Printer[] = [];
        xml.querySelectorAll('printer')?.forEach((printer: Element) => {
          let printerName: string | null = printer.getAttribute('name');
          if (printerName) {
            printerList.push({ name: printerName });
          }
        });
        return printerList;
      });
  }

  public async getReportPrinterList(): Promise<Printer[]> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Main/XMLGetReportPrinters.xdo`,
        {},
        {
          withCredentials: true,
        }
      )
      .then((responseData) => {
        const parser = new DOMParser();
        const xml: Document = parser.parseFromString(responseData['data'], 'text/xml');
        const XMLGetReportPrinters: Element | null = this.parseXmlResponse(
          responseData,
          'XMLGetReportPrinters'
        );
        let printerList: Printer[] = [];
        if (this.getAttributeFromXML(XMLGetReportPrinters, 'success') === 'true') {
          xml.querySelectorAll('printer')?.forEach((printer: Element) => {
            let printerName: string | null = printer.getAttribute('printerName');
            let printerID: string | null = printer.getAttribute('printerid');
            if (printerName && printerID) {
              printerList.push({ name: printerName, id: printerID });
            }
          });
        }
        return printerList;
      });
  }

  validateLocation(profitCenterNumber: string, location: string): Promise<boolean> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Inventory/XMLValidateStockLocation.xdo`,
        {},
        {
          params: { pcno: profitCenterNumber.toUpperCase(), loc: location.toUpperCase() },
          withCredentials: true,
        }
      )
      .then((responseData) => {
        const locationValidationResult = this.parseXmlResponse(
          responseData,
          'validateLocation'
        );
        let isValidLocationString: string | null | undefined =
          locationValidationResult?.getAttribute('valid');

        if (isValidLocationString === 'true') {
          return true;
        }
        return false;
      });
  }

  public async applyScan(
    profitCenterID: string,
    qty: number,
    actionCode: string,
    bol: string,
    id: string,
    idType: IDType,
    date: string,
    locationTo: string,
    locationFrom: string
  ): Promise<ScanResponse> {
    const idParameterName = idType.toString().toLowerCase();
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/xmlApplyScan.xdo`,
        {},
        {
          params: {
            pcid: profitCenterID.toUpperCase(),
            action: actionCode.toUpperCase(),
            [idParameterName]: id.toUpperCase(),
            bol: bol.toUpperCase(),
            date: date,
            qty: qty,
            locfrom: locationFrom.toUpperCase(),
            locto: locationTo.toUpperCase(),
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        const xmlApplyScanNode = this.parseXmlResponse(response, 'xmlApplyScan');
        let isValidApplicationString: string | null | undefined =
          xmlApplyScanNode?.getAttribute('valid');

        let errorString: string | null | undefined =
          xmlApplyScanNode?.getAttribute('error');

        return {
          error: errorString ? errorString : '',
          valid: isValidApplicationString === 'true' ? true : false,
        };
      });
  }

  public async applyReceiveSKUScan(
    actionCode: string,
    bol: string,
    location: string,
    qty: number,
    poNumber: string,
    sku: string
  ): Promise<ScanResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/xmlReceiveSKUItem.xdo`,
        {},
        {
          params: {
            action: actionCode.toUpperCase(),
            bol: bol.toUpperCase(),
            location: location.toUpperCase(),
            qty: qty,
            po: poNumber.toUpperCase(),
            sku: sku,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        const xmlApplyScanNode = this.parseXmlResponse(response, 'xmlReceiveSKU');
        let isValidApplicationString: string | null | undefined =
          xmlApplyScanNode?.getAttribute('valid');

        let errorString: string | null | undefined =
          xmlApplyScanNode?.getAttribute('error');

        return {
          error: errorString ? errorString : '',
          valid: isValidApplicationString === 'true' ? true : false,
        };
      });
  }

  private async getTableData(pcno: string, tableName: string): Promise<ReturnReason[]> {
    const returnReasons: ReturnReason[] = [];
    return await axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/XMLGetTableValues.xdo`,
        {},
        { params: { pcno: pcno, table: tableName }, withCredentials: true }
      )
      .then((response) => {
        const node: Element | null = this.parseXmlResponse(response, 'getTableValues');
        node?.querySelectorAll('row')?.forEach((row: Element) => {
          const key = this.getAttributeFromXML(row, 'key');
          const value = this.getAttributeFromXML(row, 'value');
          returnReasons.push({ key: key, value: value });
        });
        return returnReasons;
      });
  }

  public async getPriorityFill(pcno: string): Promise<ReturnReason[]> {
    return this.getTableData(pcno, 'priorityfill');
  }

  public async getReturnReasons(pcno: string): Promise<ReturnReason[]> {
    return this.getTableData(pcno, 'returnreason');
  }

  private parseXmlResponse(
    response: AxiosResponse<any, any>,
    xmlNodeName: string
  ): Element | null {
    const parser = new DOMParser();
    const xml: Document = parser.parseFromString(response['data'], 'text/xml');
    return xml.querySelector(xmlNodeName);
  }

  private zeroPad(n: number): string {
    if (n < 10) {
      return '0' + n;
    } else {
      return n.toString();
    }
  }

  private formatDate(date: Date): string {
    const f =
      date.getFullYear() +
      '-' +
      this.zeroPad(date.getMonth() + 1) +
      '-' +
      this.zeroPad(date.getDate()) +
      ' ' +
      this.zeroPad(date.getHours()) +
      ':' +
      this.zeroPad(date.getMinutes()) +
      ':' +
      this.zeroPad(date.getSeconds());
    return f;
  }

  public async transfer(
    profitCenterId: string,
    date: Date,
    locationFrom: string,
    locationTo: string,
    batch: string,
    idType: IDType,
    idTypeValue: string,
    quantity: string
  ): Promise<ScanResponse> {
    const formattedDate = this.formatDate(date);
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/XMLStockLocationTransfer.xdo`,
        {},
        {
          params: {
            pcid: profitCenterId.toUpperCase(),
            date: formattedDate,
            locfrom: locationFrom.toUpperCase(),
            locto: locationTo.toUpperCase(),
            [idType.toString().toLowerCase()]: idTypeValue.toUpperCase(),
            batch: batch.toUpperCase(),
            qty: quantity,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        const xmlNode = this.parseXmlResponse(response, 'xmlApplyScan');
        let errorString: string | null | undefined = xmlNode?.getAttribute('error');
        let isValidApplicationString: string | null | undefined =
          xmlNode?.getAttribute('valid');
        return Promise.resolve({
          valid: isValidApplicationString === 'true' ? true : false,
          error: errorString ? errorString : '',
        });
      });
  }

  public async loadExternalManifest(
    id: string,
    manifestDate: string,
    truck: string,
    externalOrder: string
  ): Promise<ScanResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Orders/XMLLoadExternalOrderItem.xdo`,
        {},
        {
          params: {
            pcid: this.sessionManager.getProfitCenterID().toUpperCase(),
            id: id.toUpperCase(),
            box: truck.toUpperCase(),
            date: manifestDate,
            extordnbr: externalOrder.toUpperCase(),
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        const xmlNode = this.parseXmlResponse(response, 'loadExternalOrder');
        let errorString: string | null | undefined = xmlNode?.getAttribute('error');
        let isValidApplicationString: string | null | undefined =
          xmlNode?.getAttribute('valid');
        return Promise.resolve({
          valid: Utilities.stringToBool(isValidApplicationString),
          error: errorString ? errorString : '',
        });
      });
  }

  public async unloadExternalManifest(
    id: string,
    manifestDate: string,
    truck: string,
    externalOrder: string,
    location: string
  ): Promise<ScanResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Orders/XMLUnloadExternalOrderItem.xdo`,
        {},
        {
          params: {
            pcid: this.sessionManager.getProfitCenterID().toUpperCase(),
            id: id.toUpperCase(),
            box: truck.toUpperCase(),
            date: manifestDate,
            extordnbr: externalOrder.toUpperCase(),
            locn: location.toUpperCase(),
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        const xmlNode = this.parseXmlResponse(response, 'unloadExternalOrder');
        let errorString: string | null | undefined = xmlNode?.getAttribute('error');
        let isValidApplicationString: string | null | undefined =
          xmlNode?.getAttribute('valid');
        return Promise.resolve({
          valid: Utilities.stringToBool(isValidApplicationString),
          error: errorString ? errorString : '',
        });
      });
  }

  public async validateStock(
    idType: IDType,
    idValue: string
  ): Promise<ValidateStockResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/XMLValidateStock.xdo`,
        {},
        {
          params: {
            pcno: this.sessionManager.getProfitCenterID().toUpperCase(),
            [idType.toString().toLowerCase()]: idValue.toUpperCase(),
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        let xmlNode = this.parseXmlResponse(response, 'validateStock');
        if (xmlNode === null) {
          xmlNode = this.parseXmlResponse(response, 'validateManifestAction');
        }
        if (xmlNode === null) {
          xmlNode = this.parseXmlResponse(response, 'validateManifestItem');
        }
        let errorString: string | null | undefined = xmlNode?.getAttribute('error');
        let isValidApplicationString: string | null | undefined =
          xmlNode?.getAttribute('valid');
        let stockidString: string | null | undefined = xmlNode?.getAttribute('stockid');
        let polineitemlabelString: string | null | undefined =
          xmlNode?.getAttribute('polineitemlabel');
        let vsnString: string | null | undefined = xmlNode?.getAttribute('vsn');
        let resaleString: string | null | undefined = xmlNode?.getAttribute('resale');
        let descriptionString: string | null | undefined =
          xmlNode?.getAttribute('description');
        let sourcestatusString: string | null | undefined =
          xmlNode?.getAttribute('sourcestatus');
        let salesstatusString: string | null | undefined =
          xmlNode?.getAttribute('salesstatus');
        let acknumString: string | null | undefined = xmlNode?.getAttribute('acknum');
        let epcString: string | null | undefined = xmlNode?.getAttribute('epc');
        let truckString: string | null | undefined = xmlNode?.getAttribute('box');
        let stopString: string | null | undefined = xmlNode?.getAttribute('stop');
        let manifest_statusString: string | null | undefined =
          xmlNode?.getAttribute('manifest_status');
        let manifest_item_statusString: string | null | undefined =
          xmlNode?.getAttribute('manifest_item_status');
        let typeString: string | null | undefined = xmlNode?.getAttribute('type');
        let pickupString: string | null | undefined = xmlNode?.getAttribute('pickup');
        let sealedString: string | null | undefined = xmlNode?.getAttribute('sealed');
        let deliverytypeString: string | null | undefined =
          xmlNode?.getAttribute('deliverytype');
        let pickidString: string | null | undefined = xmlNode?.getAttribute('pickid');
        let skuString: string | null | undefined = xmlNode?.getAttribute('sku');

        return Promise.resolve({
          valid: Utilities.stringToBool(isValidApplicationString),
          error: errorString ? errorString : '',
          stockid: stockidString ? stockidString : '',
          polineitemlabel: polineitemlabelString ? polineitemlabelString : '',
          vsn: vsnString ? vsnString : '',
          resale: resaleString ? resaleString : '',
          description: descriptionString ? descriptionString : '',
          sourcestatus: sourcestatusString ? sourcestatusString : '',
          salesstatus: salesstatusString ? salesstatusString : '',
          acknum: acknumString ? acknumString : '',
          epc: epcString ? epcString : '',
          truck: truckString ? truckString : '',
          stop: stopString ? stopString : '',
          manifest_status: manifest_statusString ? manifest_statusString : '',
          manifest_item_status: manifest_item_statusString
            ? manifest_item_statusString
            : '',
          type: typeString ? typeString : '',
          pickup: pickupString ? pickupString : '',
          sealed: sealedString ? sealedString : '',
          deliverytype: deliverytypeString ? deliverytypeString : '',
          pickid: pickidString ? pickidString : '',
          sku: skuString ? skuString : '',
        });
      });
  }

  public async validateStockWithAction(
    action: string,
    date: Date,
    truck: string,
    epc: string,
    poid: string,
    pickid: string
  ): Promise<ValidateStockResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/XMLValidateStock.xdo`,
        {},
        {
          params: {
            action: action.toUpperCase(),
            pcid: this.sessionManager.getProfitCenterID().toUpperCase(),
            date: Utilities.formatDateYYYYMMDD(date),
            box: truck.toUpperCase(),
            epc: epc.toUpperCase(),
            poid: poid.toUpperCase(),
            pickid: pickid.toUpperCase(),
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        let xmlNode = this.parseXmlResponse(response, 'validateStock');
        if (xmlNode === null) {
          xmlNode = this.parseXmlResponse(response, 'validateManifestAction');
        }
        if (xmlNode === null) {
          xmlNode = this.parseXmlResponse(response, 'validateManifestItem');
        }
        if (xmlNode === null) {
          xmlNode = this.parseXmlResponse(response, 'xmlResponse');
        }
        let errorString: string | null | undefined = xmlNode?.getAttribute('error');
        let isValidApplicationString: string | null | undefined =
          xmlNode?.getAttribute('valid');
        let stockidString: string | null | undefined = xmlNode?.getAttribute('stockid');
        let polineitemlabelString: string | null | undefined =
          xmlNode?.getAttribute('polineitemlabel');
        let vsnString: string | null | undefined = xmlNode?.getAttribute('vsn');
        let resaleString: string | null | undefined = xmlNode?.getAttribute('resale');
        let descriptionString: string | null | undefined =
          xmlNode?.getAttribute('description');
        let sourcestatusString: string | null | undefined =
          xmlNode?.getAttribute('sourcestatus');
        let salesstatusString: string | null | undefined =
          xmlNode?.getAttribute('salesstatus');
        let acknumString: string | null | undefined = xmlNode?.getAttribute('acknum');
        let epcString: string | null | undefined = xmlNode?.getAttribute('epc');
        let truckString: string | null | undefined = xmlNode?.getAttribute('box');
        let stopString: string | null | undefined = xmlNode?.getAttribute('stop');
        let manifest_statusString: string | null | undefined =
          xmlNode?.getAttribute('manifest_status');
        let manifest_item_statusString: string | null | undefined =
          xmlNode?.getAttribute('manifest_item_status');
        let typeString: string | null | undefined = xmlNode?.getAttribute('type');
        let pickupString: string | null | undefined = xmlNode?.getAttribute('pickup');
        let sealedString: string | null | undefined = xmlNode?.getAttribute('sealed');
        let deliverytypeString: string | null | undefined =
          xmlNode?.getAttribute('deliverytype');
        let pickidString: string | null | undefined = xmlNode?.getAttribute('pickid');
        let skuString: string | null | undefined = xmlNode?.getAttribute('sku');

        return Promise.resolve({
          valid: Utilities.stringToBool(isValidApplicationString),
          error: errorString ? errorString : '',
          stockid: stockidString ? stockidString : '',
          polineitemlabel: polineitemlabelString ? polineitemlabelString : '',
          vsn: vsnString ? vsnString : '',
          resale: resaleString ? resaleString : '',
          description: descriptionString ? descriptionString : '',
          sourcestatus: sourcestatusString ? sourcestatusString : '',
          salesstatus: salesstatusString ? salesstatusString : '',
          acknum: acknumString ? acknumString : '',
          epc: epcString ? epcString : '',
          truck: truckString ? truckString : '',
          stop: stopString ? stopString : '',
          manifest_status: manifest_statusString ? manifest_statusString : '',
          manifest_item_status: manifest_item_statusString
            ? manifest_item_statusString
            : '',
          type: typeString ? typeString : '',
          pickup: pickupString ? pickupString : '',
          sealed: sealedString ? sealedString : '',
          deliverytype: deliverytypeString ? deliverytypeString : '',
          pickid: pickidString ? pickidString : '',
          sku: skuString ? skuString : '',
        });
      });
  }

  public async printLabels(
    idType: IDType,
    idValue: string,
    printer: string,
    quantity: string,
    location: string
  ): Promise<ScanResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/XMLPrintLabel.xdo`,
        {},
        {
          params: {
            pcid: this.sessionManager.getProfitCenterID(),
            [idType.toString().toLowerCase()]: idValue.toUpperCase(),
            printer: printer,
            qty: quantity,
            location: location.toUpperCase(),
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        let xmlNode = this.parseXmlResponse(response, 'printlabel');
        let errorString: string | null | undefined = xmlNode?.getAttribute('error');
        let isValidApplicationString: string | null | undefined =
          xmlNode?.getAttribute('success');
        return Promise.resolve({
          valid: Utilities.stringToBool(isValidApplicationString),
          error: errorString ? errorString : '',
        });
      });
  }

  public async unloadManifestItem(
    date: Date,
    box: string,
    toLocation: string,
    quantity: string,
    idType: IDType,
    idValue: string
  ): Promise<ScanResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/XMLUnLoadManifest.xdo`,
        {},
        {
          params: {
            pcid: this.sessionManager.getProfitCenterID(),
            date: Utilities.formatDateYYYYMMDD(date),
            box: box.toUpperCase(),
            to: toLocation.toUpperCase(),
            quantity: quantity,
            [idType.toString().toLowerCase()]: idValue.toUpperCase(),
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        let xmlNode = this.parseXmlResponse(response, 'unLoadManifestItem');
        let errorString: string | null | undefined = xmlNode?.getAttribute('error');
        let isValidApplicationString: string | null | undefined =
          xmlNode?.getAttribute('valid');
        return Promise.resolve({
          valid: Utilities.stringToBool(isValidApplicationString),
          error: errorString ? errorString : '',
        });
      });
  }

  public async manifestLoad(
    from: string,
    date: Date,
    truck: string,
    epc: string,
    poid: string,
    pickid: string,
    quantity: string
  ): Promise<ManifestLoadResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/XMLLoadManifest.xdo`,
        {},
        {
          params: {
            from: from.toUpperCase(),
            pcid: this.sessionManager.getProfitCenterID(),
            date: Utilities.formatDateYYYYMMDD(date),
            box: truck.toUpperCase(),
            epc: epc.toUpperCase(),
            poid: poid.toUpperCase(),
            pickid: pickid.toUpperCase(),
            quantity: quantity,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        let xmlNode = this.parseXmlResponse(response, 'loadManifestItem');

        const isValidApplicationString: string = this.getAttributeFromXML(
          xmlNode,
          'valid'
        );
        const errorString: string = this.getAttributeFromXML(xmlNode, 'error');

        let epcString: string = this.getAttributeFromXML(xmlNode, 'epc');
        let truckString: string = this.getAttributeFromXML(xmlNode, 'box');
        let stockidString: string = this.getAttributeFromXML(xmlNode, 'stockid');
        let polineitemlabelString: string = this.getAttributeFromXML(
          xmlNode,
          'polineitemlabel'
        );

        let vsnString: string = this.getAttributeFromXML(xmlNode, 'vsn');

        let manifest_statusString: string = this.getAttributeFromXML(
          xmlNode,
          'manifest_status'
        );
        let manifest_item_statusString: string = this.getAttributeFromXML(
          xmlNode,
          'manifest_item_status'
        );
        let manifest_sealedString: string = this.getAttributeFromXML(
          xmlNode,
          'manifest_sealed'
        );
        let pickidString: string = this.getAttributeFromXML(xmlNode, 'pickid');
        let fromString: string = this.getAttributeFromXML(xmlNode, 'from');
        let quantityString: string = this.getAttributeFromXML(xmlNode, 'quantity');

        return Promise.resolve({
          valid: Utilities.stringToBool(isValidApplicationString),
          error: errorString,
          epc: epcString,
          box: truckString,
          pickid: pickidString,
          stockid: stockidString,
          polineitemlabel: polineitemlabelString,
          vsn: vsnString,
          manifest_status: manifest_statusString,
          manifest_item_status: manifest_item_statusString,
          manifest_sealed: manifest_sealedString,
          from: fromString,
          quantity: quantityString,
        });
      });
  }

  private getAttributeFromXML(xmlNode: Element | null, attributeName: string) {
    let errorString: string | null | undefined = xmlNode?.getAttribute(attributeName);
    const errorString2 = errorString ? errorString : '';
    return errorString2;
  }

  public async checkPickId(date: Date, pickid: string): Promise<CheckPickIdResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/XMLValidateManifestItemID.xdo`,
        {},
        {
          params: {
            pcid: this.sessionManager.getProfitCenterID(),
            date: Utilities.formatDateYYYYMMDD(date),
            pickid: pickid.toUpperCase(),
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        let xmlNode = this.parseXmlResponse(response, 'validateManifestItemID');
        let validString: string = this.getAttributeFromXML(xmlNode, 'valid');
        let pickid: string = this.getAttributeFromXML(xmlNode, 'pickid');
        let vsn: string = this.getAttributeFromXML(xmlNode, 'vsn');
        let box: string = this.getAttributeFromXML(xmlNode, 'box');
        let stop: string = this.getAttributeFromXML(xmlNode, 'stop');
        let manifestStatus: string = this.getAttributeFromXML(xmlNode, 'manifest_status');
        let manifestSealedString: string = this.getAttributeFromXML(
          xmlNode,
          'manifest_sealed'
        );
        let errorMessage: string = this.getAttributeFromXML(xmlNode, 'error');

        return Promise.resolve({
          valid: Utilities.stringToBool(validString),
          pickid: pickid,
          vsn: vsn,
          box: box,
          manifestStatus: manifestStatus,
          manifestSealed: Utilities.stringToBool(manifestSealedString),
          stop: stop,
          error: errorMessage,
        });
      });
  }

  public sendBarcode(xml: string): Promise<ScanResponse> {
    return axios
      .post(`${this.sessionManager.getRMSEnvironment()}/Main/saveXML.xdo`, xml, {
        headers: {
          'Content-Type': 'text/application/xml',
        },
        withCredentials: true,
      })
      .then((response) => {
        let success: boolean = true;
        let errorMessage = '';
        let xmlNode = this.parseXmlResponse(response, 'dbml');
        let xmlCommit = this.getAttributeFromXML(xmlNode, 'commit');
        if (xmlCommit === 'false') {
          success = false;
          errorMessage = response['data'];
        } else if (xmlCommit === 'Not Found') {
          success = false;
          errorMessage = 'Not Found';
        }
        return Promise.resolve({ valid: success, error: errorMessage });
      });
  }

  public async updateISTReturn(
    manifestDate: string,
    epc: string,
    poid: string,
    pickid: string,
    qty: string,
    truck: string,
    from: string,
    to: string,
    batch: string,
    reason: string
  ): Promise<UpdateReturnsResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/XMLISTPickupOrRejectedReturns.xdo`,
        {},
        {
          params: {
            pcid: this.sessionManager.getProfitCenterID(),
            date: manifestDate,
            epc: epc.toUpperCase(),
            poid: poid.toUpperCase(),
            pickid: pickid.toUpperCase(),
            qty: qty,
            box: truck.toUpperCase(),
            status: to === 'TRK' ? 'L' : 'O',
            from: from.toUpperCase(),
            to: to.toUpperCase(),
            batch: batch.toUpperCase(),
            scanned: Utilities.convertToEasternTimeZone(DateWrapper.today()),
            reason: reason.toUpperCase(),
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        let xmlNode = this.parseXmlResponse(response, 'istreturn');
        let valid: string = this.getAttributeFromXML(xmlNode, 'valid');
        let errorMessage: string = this.getAttributeFromXML(xmlNode, 'error');

        return Promise.resolve({
          valid: Utilities.stringToBool(valid),
          error: errorMessage,
        });
      });
  }

  public async updateUnscheduledReturn(
    manifestDate: string,
    locationTo: string,
    truck: string,
    epc: string,
    priorityfill: string,
    realReturnType: string
  ): Promise<UpdateReturnsResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/XMLUnscheduledReturns.xdo`,
        {},
        {
          params: {
            pcno: this.sessionManager.getProfitCenterID(),
            mfdate: manifestDate,
            locto: locationTo.toUpperCase(),
            box: truck.toUpperCase(),
            returntype: realReturnType,
            epc: epc.toUpperCase(),
            priorfill: priorityfill.toUpperCase(),
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        let xmlNode = this.parseXmlResponse(response, 'unscheduledReturn');
        let valid: string = this.getAttributeFromXML(xmlNode, 'valid');
        let errorMessage: string = this.getAttributeFromXML(xmlNode, 'error');
        return Promise.resolve({
          valid: Utilities.stringToBool(valid),
          error: errorMessage,
        });
      });
  }

  public validateCycleCountLocation(
    location: string
  ): Promise<ValidateCycleCountLocationResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Inventory/XMLValidateCycleCountStkLctn.xdo`,
        {},
        {
          params: {
            pcno: this.sessionManager.getProfitCenterID(),
            loc: location.toUpperCase(),
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        let xmlNode = this.parseXmlResponse(response, 'validateLocation');
        let valid: string = this.getAttributeFromXML(xmlNode, 'valid');
        let stocklocationid: string = this.getAttributeFromXML(
          xmlNode,
          'stocklocationid'
        );
        let description: string = this.getAttributeFromXML(xmlNode, 'description');
        let nailed: string = this.getAttributeFromXML(xmlNode, 'nailed');
        let to: string = this.getAttributeFromXML(xmlNode, 'to');
        return Promise.resolve({
          valid: Utilities.stringToBool(valid),
          stocklocationid: stocklocationid,
          description: description,
          nailed: nailed,
          to: to,
        });
      });
  }

  public async updateScheduledReturn(
    date: string,
    qty: string,
    locto: string,
    priorfill: string,
    reason: string,
    bol: string,
    epc: string,
    poid: string,
    pickid: string
  ): Promise<UpdateReturnsResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/XMLDelOrUndelHomeReturn.xdo`,
        {},
        {
          params: {
            pcid: this.sessionManager.getProfitCenterID(),
            date: date,
            qty: qty,
            locto: locto.toUpperCase(),
            scanned: Utilities.convertToEasternTimeZone(DateWrapper.today()),
            priorfill: priorfill.toUpperCase(),
            reason: reason.toUpperCase(),
            bol: bol.toUpperCase(),
            epc: epc.toUpperCase(),
            poid: poid.toUpperCase(),
            pickid: pickid.toUpperCase(),
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        let xmlNode = this.parseXmlResponse(response, 'homereturn');
        let valid: string = this.getAttributeFromXML(xmlNode, 'valid');
        let errorMessage: string = this.getAttributeFromXML(xmlNode, 'error');
        return Promise.resolve({
          valid: Utilities.stringToBool(valid),
          error: errorMessage,
        });
      });
  }

  public async getReturnType(epc: string): Promise<GetReturnTypeResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/XMLGetReturnTypes.xdo`,
        {},
        {
          params: {
            pcid: this.sessionManager.getProfitCenterID(),
            epc: epc.toUpperCase(),
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        const responseString = String(response.data);
        response.data = responseString.substring(
          0,
          String(response.data).indexOf('/>') + 2
        );
        let xmlNode = this.parseXmlResponse(response, 'getReturnType');
        let valid: xmlNode = xmlNode?.getAttribute('valid');
        let stockId: xmlNode = xmlNode?.getAttribute('stockid');
        let realReturnType: xmlNode = xmlNode?.getAttribute('returntype');
        let manifestDate: xmlNode = xmlNode?.getAttribute('manifestdate');
        let delBoxScheduled: xmlNode = xmlNode?.getAttribute('delboxscheduled');
        let delBoxId: xmlNode = xmlNode?.getAttribute('delboxid');
        let delBoxDate: xmlNode = xmlNode?.getAttribute('delboxdate');
        let poid: xmlNode = xmlNode?.getAttribute('polineitemlabel');
        let vsn: xmlNode = xmlNode?.getAttribute('vsn');
        let epc: xmlNode = xmlNode?.getAttribute('epc');
        let truck: xmlNode = xmlNode?.getAttribute('box');
        let error: xmlNode = xmlNode?.getAttribute('error');
        return Promise.resolve({
          valid: Utilities.stringToBool(valid),
          error: error ? error : '',
          stockId: stockId ? stockId : '',
          realReturnType: realReturnType ? realReturnType : '',
          returnType: Utilities.stringToBool(valid)
            ? this.convertToReturnType(realReturnType)
            : ReturnType.NULL,
          manifestDate:
            manifestDate && manifestDate !== '' && manifestDate !== 'unknown'
              ? manifestDate
              : '',
          delBoxScheduled: delBoxScheduled ? delBoxScheduled : '',
          delBoxId: delBoxId ? delBoxId : '',
          delBoxDate: delBoxDate ? delBoxDate : '',
          poid: poid ? poid : '',
          vsn: vsn ? vsn : '',
          epc: epc ? epc : '',
          truck: truck ? truck : '',
        });
      });
  }
  private convertToReturnType(xml: xmlNode | undefined): ReturnType {
    switch (xml) {
      case 'FlyBy':
        return ReturnType.ScheduledReturn;
      case 'IST Pickup':
        return ReturnType.InStoreTransfer;
      case 'IST rejected':
        return ReturnType.InStoreTransfer;
      case 'Scheduled return':
        return ReturnType.ScheduledReturn;
      case 'DEL returned':
        return ReturnType.ScheduledReturn;
      default:
        return ReturnType.UnscheduledReturn;
    }
  }

  public getTrucks(pcid: string): Promise<GetTrucksResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/XMLGetTrucksbyPCID.xdo`,
        {},
        {
          params: { pcid: pcid.toUpperCase() },
          withCredentials: true,
        }
      )
      .then((responseData) => {
        const parser = new DOMParser();
        const xmlGetTrucks: Element | null = this.parseXmlResponse(
          responseData,
          'getTrucksbyPCID'
        );
        let truckList: Truck[] = [];
        let response = { valid: false, trucks: truckList, error: '' };
        if (this.getAttributeFromXML(xmlGetTrucks, 'valid') === 'true') {
          response.valid = true;
          const xml: Document = parser.parseFromString(responseData['data'], 'text/xml');

          xml.querySelectorAll('row')?.forEach((row: Element) => {
            let truckName = this.getAttributeFromXML(row, 'truck');
            let truckManifestDate = this.getAttributeFromXML(row, 'manifestdate');
            truckList.push({
              name: truckName,
              manifestDate:
                truckManifestDate == '' || truckManifestDate == 'unknown'
                  ? ''
                  : truckManifestDate,
            });
          });
          response.trucks = truckList;
        } else {
          response.valid = false;
          response.error = this.getAttributeFromXML(xmlGetTrucks, 'error');
        }

        return response;
      });
  }

  public cycleCountScan(
    scandate: Date,
    qty: string,
    location: string,
    batch: string,
    stocklocationid: string | undefined,
    idType: IDType,
    id: string,
    locemp: string
  ): Promise<CycleCountScanResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/XMLCycleCount.xdo`,
        {},
        {
          params: {
            pcid: this.sessionManager.getProfitCenterID(),
            scandate: Utilities.convertToEasternTimeZoneWithAMPM(scandate),
            qty: qty,
            location: location.toUpperCase(),
            batch: batch.toUpperCase(),
            stocklocationid: stocklocationid?.toUpperCase(),
            [idType.toString().toLowerCase()]: id.toUpperCase(),
            locemp: locemp.toUpperCase(),
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        let xmlNode = this.parseXmlResponse(response, 'cycleCount');
        let valid: string = this.getAttributeFromXML(xmlNode, 'valid');
        let epc: string = this.getAttributeFromXML(xmlNode, 'epc');
        let errorMessage: string = this.getAttributeFromXML(xmlNode, 'error');
        return Promise.resolve({
          valid: Utilities.stringToBool(valid),
          epc: epc,
          error: errorMessage,
        });
      });
  }

  public async rdcCpuTicketSearch(ticketNum: string): Promise<TicketResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Main/XMLRdcCpuTicketSearch.xdo`,
        {},
        {
          params: {
            ticketnbr: ticketNum.toUpperCase(),
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        let xmlRdcCpuTicketSearch = this.parseXmlResponse(
          response,
          'xmlRdcCpuTicketSearch'
        );
        let xmlRdcCpuTicketSearchValid = this.parseXmlResponse(
          response,
          'XMLRdcCpuTicketSearch'
        );

        let valid: boolean = this.getAttributeFromXML(xmlRdcCpuTicketSearch, 'valid')
          ? false
          : true;
        let error: string = this.getAttributeFromXML(xmlRdcCpuTicketSearch, 'error');
        let ticketList: Ticket[] = [];
        if (xmlRdcCpuTicketSearchValid !== null) {
          xmlRdcCpuTicketSearchValid
            .querySelectorAll('ticket')
            ?.forEach((ticket: Element) => {
              let soldid: string | null = ticket.getAttribute('soldid');
              let ticketnbr: string | null = ticket.getAttribute('ticketnbr');
              let market: string | null = ticket.getAttribute('market');
              let totalQty: string | null = ticket.getAttribute('totalQty');
              let qtyPickedUp: string | null = ticket.getAttribute('qtyPickedUp');
              ticketList.push({
                soldid: soldid === null ? '' : soldid,
                ticketnbr: ticketnbr === null ? '' : ticketnbr,
                market: market === null ? '' : market,
                totalQty: totalQty === null ? '' : totalQty,
                qtyPickedUp: qtyPickedUp === null ? '' : qtyPickedUp,
              });
            });
        }
        return Promise.resolve({ tickets: ticketList, valid: valid, error: error });
      });
  }

  public async updateItemPickedUp(
    soldid: string,
    epc: string,
    poid: string,
    sku: string,
    pickid: string,
    location: string
  ): Promise<UpdateItemPickedUpResponse> {
    const response = await axios.post(
      `${this.sessionManager.getRMSEnvironment()}/Main/XMLUpdateItemPickedUp.xdo`,
      {},
      {
        params: {
          soldid: soldid.toUpperCase(),
          epc: epc.toUpperCase(),
          poid: poid.toUpperCase(),
          sku: sku.toUpperCase(),
          pickid: pickid.toUpperCase(),
          loc: location.toUpperCase(),
        },
        withCredentials: true,
      }
    );
    const xmlNode = this.parseXmlResponse(response, 'XMLUpdateItemPickedUp');
    const ticketNode = xmlNode?.querySelector('ticket');
    let error: string = this.getAttributeFromXML(xmlNode, 'error');
    if (ticketNode) {
      const quantityPickedUp = this.getAttributeFromXML(ticketNode, 'qtyPickedUp');
      const totalQtyScheduledForPickup = this.getAttributeFromXML(
        ticketNode,
        'totalQtyScheduledForPickup'
      );
      return Promise.resolve({
        valid: true,
        qtyPickedUp: quantityPickedUp,
        totalQtyScheduledForPickup: totalQtyScheduledForPickup,
        error: '',
      });
    }
    return Promise.resolve({
      valid: false,
      qtyPickedUp: '',
      totalQtyScheduledForPickup: '',
      error: error,
    });
  }

  public async rdcCpuDraftReport(soldid: string): Promise<ScanResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Main/XMLRdcCpuDraftReport.xdo`,
        {},
        {
          params: {
            soldid: soldid,
            printerid:
              this.sessionManager.getPrinterID() !== ''
                ? this.sessionManager.getPrinterID()
                : undefined,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        const xmlApplyScanNode = this.parseXmlResponse(response, 'XMLRdcCpuDraftReport');
        let isSuccessApplicationString: string | null | undefined =
          xmlApplyScanNode?.getAttribute('success');

        let errorString: string | null | undefined =
          xmlApplyScanNode?.getAttribute('error');

        return {
          error: errorString ? errorString : '',
          valid: Utilities.stringToBool(isSuccessApplicationString),
        };
      });
  }

  public async rdcCpuDeliveryStatement(soldid: string): Promise<ScanResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Main/XMLRdcCpuDeliveryStatement.xdo`,
        {},
        {
          params: {
            soldid: soldid,
            printerid:
              this.sessionManager.getPrinterID() !== ''
                ? this.sessionManager.getPrinterID()
                : undefined,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        const xmlApplyScanNode = this.parseXmlResponse(
          response,
          'XMLRdcCpuDeliveryStatement'
        );

        const isSuccessApplicationString = xmlApplyScanNode?.getAttribute('success');

        const errorString = xmlApplyScanNode?.getAttribute('error');

        return {
          error: errorString ? errorString : '',
          valid: Utilities.stringToBool(isSuccessApplicationString),
        };
      });
  }
  public async DlrProcessManifestAction(
    date: string,
    truck: string,
    id: string,
    idType: IDType,
    pickqty: string,
    locationcode: string,
    action: string
  ): Promise<ScanResponse> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Barcode/xmlProcessManifest.xdo`,
        {},
        {
          params: {
            date: date.toUpperCase(),
            truck: truck.toUpperCase(),
            epc: idType === IDType.EPC ? id.toUpperCase() : '',
            poid: idType === IDType.POID ? id.toUpperCase() : '',
            pickid: idType === IDType.PICKID ? id.toUpperCase() : '',
            pickqty: pickqty.toUpperCase(),
            locationcode: locationcode.toUpperCase(),
            action: action.toUpperCase(),
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        let xmlNode = this.parseXmlResponse(response, 'XMLupdateItem4DlrProcessManifest');
        let errorString: string | null | undefined = xmlNode?.getAttribute('error');
        let isValidApplicationStringStatus: boolean = Utilities.stringToBool(
          xmlNode?.getAttribute('status')
        );
        let isValidApplicationStringSuccess: boolean = Utilities.stringToBool(
          xmlNode?.getAttribute('success')
        );
        return Promise.resolve({
          valid: isValidApplicationStringSuccess ? true : isValidApplicationStringStatus,
          error: errorString ? errorString : '',
        });
      });
  }

  public async getPcIdsForNaturalStoreId(
    naturalStoreId: string
  ): Promise<ProfitCenterIdsResponse[]> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Main/getValidProfitCentersForUser.xdo`,
        {},
        {
          params: {
            naturalStoreId: naturalStoreId,
          },
          withCredentials: true,
        }
      )
      .then((response) => {
        let xmlNode = this.parseXmlResponse(
          response,
          'retrievePcsForNaturalStoreIdResult'
        );

        let errorString: string | null | undefined = xmlNode?.getAttribute('error');

        let listOfProfitCenterIdsResponses = [] as ProfitCenterIdsResponse[];

        if (errorString) {
          return [];
        }

        if (xmlNode != null) {
          xmlNode.querySelectorAll('ScangunLoginIdentifier')?.forEach((item: Element) => {
            let dealerId: string | null = item.getAttribute('dealerId');
            let dealerName: string | null = item.getAttribute('dealerName');
            let profitCenterId: string | null = item.getAttribute('profitCenterId');
            let naturalStoreId: string | null = item.getAttribute('naturalStoreId');

            if (dealerId && profitCenterId && naturalStoreId && dealerName) {
              let profitCenterIdsResponse: ProfitCenterIdsResponse = {
                dealerId,
                dealerName,
                profitCenterId,
                naturalStoreId,
              };

              listOfProfitCenterIdsResponses.push(profitCenterIdsResponse);
            }
          });
        }
        return Promise.resolve(this.removeDuplicates(listOfProfitCenterIdsResponses));
      });
  }

  public async setSelectedPcIdOnHeritageSession(
    selectedProfitcenterId: string
  ): Promise<void> {
    return axios
      .post(
        `${this.sessionManager.getRMSEnvironment()}/Main/getValidProfitCentersForUser.xdo`,
        {},
        {
          params: {
            selectedProfitcenter: selectedProfitcenterId,
          },
          withCredentials: true,
        }
      )
      .then((result) => {
        let xmlNode = this.parseXmlResponse(result, 'retrievePcsForNaturalStoreIdResult');
        let errorString: string | null | undefined = xmlNode?.getAttribute('error');
        if (errorString) {
          throw new Error('Error Selecting PC');
        }
      });
  }

  public removeDuplicates = (
    array: ProfitCenterIdsResponse[]
  ): ProfitCenterIdsResponse[] => {
    return array.reduce((uniqueArray: ProfitCenterIdsResponse[], obj) => {
      if (
        !uniqueArray.some(
          (uniqueObj) => JSON.stringify(uniqueObj) === JSON.stringify(obj)
        )
      ) {
        uniqueArray.push(obj);
      }
      return uniqueArray;
    }, []);
  };
}

export default AppService;
