import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConfigurationTab from '../ConfigurationTab/ConfigurationTab';
import './Configuration.css';
import ConfigPanel from './ConfigPanel';
import { LoginConfiguration } from '../Login/LoginConfiguration';
import EnvironmentConfigPanel from './EnvironmentConfigPanel';

const tabTheme: Theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
  },
});

export default function Configuration() {
  const loginConfiguration: LoginConfiguration = new LoginConfiguration();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState({ data: 0 });
  const changeSelectedTab = (index: number) => {
    setSelectedTab({ data: index });
  };

  return (
    <div>
      <Grid id="header" container direction="row" justifyContent="space-between">
        <Grid item xs={8}>
          <Typography variant="h6">RMS Scan</Typography>
        </Grid>
        <Grid item xs={4}>
          <Button
            id="done-button"
            fullWidth
            size="small"
            variant="contained"
            onClick={() => {
              navigate('/');
            }}
          >
            Done
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box
            id="outerBox"
            sx={{
              backgroundColor: '#F2F2F2',
            }}
          >
            <div id="tabcontainer">
              <ThemeProvider theme={tabTheme}>
                <Tabs
                  id="tabs"
                  value={selectedTab.data}
                  textColor="primary"
                  indicatorColor="primary"
                  centered
                  sx={{
                    minHeight: '35px',
                  }}
                >
                  <Tab
                    sx={{ p: 0 }}
                    id="user-tab"
                    value={0}
                    label="User"
                    onClick={() => changeSelectedTab(0)}
                  />
                  <Tab
                    sx={{ p: 0 }}
                    id="store-tab"
                    value={1}
                    label="Store"
                    onClick={() => changeSelectedTab(1)}
                  />
                  {process.env.REACT_APP_HIDE_ENVIRONMENT_TAB?.toLowerCase() ===
                    'false' && (
                    <Tab
                      sx={{ p: 0 }}
                      id="environment-tab"
                      value={2}
                      label="Environment"
                      onClick={() => changeSelectedTab(2)}
                    />
                  )}
                </Tabs>
              </ThemeProvider>
              <ConfigurationTab selectedIndex={selectedTab.data} index={0}>
                <ConfigPanel
                  tabTitle="User"
                  addFunction={loginConfiguration.addUser}
                  deleteFunction={loginConfiguration.removeUser}
                  getAllItemsFunction={loginConfiguration.getAllUsernames}
                />
              </ConfigurationTab>
              <ConfigurationTab selectedIndex={selectedTab.data} index={1}>
                <ConfigPanel
                  tabTitle="Store"
                  addFunction={loginConfiguration.addStoreNumber}
                  deleteFunction={loginConfiguration.removeStoreNumber}
                  getAllItemsFunction={loginConfiguration.getAllStoreNumbers}
                />
              </ConfigurationTab>
              <ConfigurationTab selectedIndex={selectedTab.data} index={2}>
                <EnvironmentConfigPanel />
              </ConfigurationTab>
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
