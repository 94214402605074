import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactElement } from 'react';

interface AcceptDialogProps {
  messageLine1: string;
  messageLine2: string;
  showDialog: boolean;
  noButtonFunction: Function;
  yesButtonFunction: Function;
  icon: ReactElement<any, any>;
}

function AcceptDialog(props: AcceptDialogProps) {
  return (
    <Dialog open={props.showDialog} fullWidth>
      <Stack direction="column" justifyContent="center" alignItems="left">
        <div>
          <DialogTitle sx={{ paddingBottom: 1, paddingLeft: 2 }}>
            {'Accepted'}
          </DialogTitle>
          <IconButton
            data-testid="closeButton"
            onClick={() => {
              props.noButtonFunction();
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Divider sx={{ backgroundColor: '#797979' }} />
        </div>
        <Grid
          container
          sx={{ width: '100%' }}
          alignItems="left"
          justifyContent={'left'}
          columnSpacing={1}
          paddingTop={1}
          paddingLeft={3}
          paddingRight={3}
        >
          <Grid item xs={2} paddingTop={0.5}>
            {props.icon}
          </Grid>
          <Grid container item xs={10}>
            <Grid item xs={12} sx={{ color: 'black', paddingLeft: '8px !important' }}>
              {props.messageLine1}
            </Grid>
            <Grid item xs={12} sx={{ color: 'black', paddingLeft: '8px !important' }}>
              {props.messageLine2}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={2}
          alignItems="center"
          justifyContent={'center'}
          paddingTop={5}
        >
          <Grid item xs={5}>
            <DialogActions>
              <Button
                data-testid="yesButton"
                variant="contained"
                fullWidth
                size="small"
                onClick={() => {
                  props.yesButtonFunction();
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Grid>
          <Grid item xs={5}>
            <DialogActions>
              <Button
                data-testid="noButton"
                variant="contained"
                size="small"
                fullWidth
                onClick={() => {
                  props.noButtonFunction();
                }}
              >
                No
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Stack>
    </Dialog>
  );
}

export default AcceptDialog;
