import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import AppService from '../../Services/app.service';
import { ReturnReason } from '../../Services/app.service';
import { SessionManager } from '../SessionManager';

export interface ReasonComponentProps {
  updateReason: Function;
}

function ReasonComponent(props: ReasonComponentProps) {
  const [returnReasons, setReturnReasons] = useState<ReturnReason[]>([]);
  const [reasonEntry, setReasonEntry] = useState<string>('IST');
  const appService: AppService = new AppService();
  const sessionManager: SessionManager = new SessionManager();

  useEffect(() => {
    appService.getReturnReasons(sessionManager.getProfitCenterID()).then((reasons) => {
      setReturnReasons(reasons);
      props.updateReason(reasonEntry);
    });
  }, []);

  function OnReasonChange(reason: string) {
    setReasonEntry(reason);
    props.updateReason(reason);
  }

  return (
    <>
      <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
        <label htmlFor="reasonDropdown">Reason</label>
      </Grid>
      <Grid item xs={9} id="reasonDropdown">
        <select
          value={reasonEntry}
          data-testid="reasonDropdown"
          onChange={(eventData) => {
            OnReasonChange(eventData.target.value);
          }}
          style={{
            borderColor: 'black',
            borderRadius: '4px',
            width: '100%',
            fontSize: 'medium',
            backgroundColor: 'white',
            paddingTop: '0.25em',
            paddingBottom: '0.25em',
          }}
        >
          {returnReasons.map((reason) => {
            return (
              <option key={reason.key} value={reason.key}>
                {reason.value}
              </option>
            );
          })}
        </select>
      </Grid>
    </>
  );
}
export default ReasonComponent;
