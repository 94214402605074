import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SessionManager } from '../SessionManager';
import PrintIcon from '@mui/icons-material/Print';
import Utilities from '../Utils/Utilities';

function Main() {
  const navigate = useNavigate();
  const sessionManager = new SessionManager();
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignContent={'stretch'}
      spacing={0}
    >
      <Grid container paddingRight={1}>
        <Grid item xs={11} justifyContent={'left'}>
          <Typography paddingTop={2} paddingLeft={2} variant="h6" id="rms-scan-label">
            <label>RMS Scan</label>
          </Typography>
        </Grid>
        <Grid item xs={1} justifyContent={'right'}>
          {!Utilities.stringToBool(process.env.REACT_APP_HIDE_PRINT_PAGE) && (
            <IconButton
              data-testid="printButton"
              onClick={() => {
                sessionManager.setPrintLabelsNextPath('/PrintPage');
                navigate('/PrintLabels');
              }}
            >
              <PrintIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
      <div
        id="button-stack-div"
        style={{
          paddingTop: '.5em',
          paddingLeft: '1.5em',
          paddingRight: '1.5em',
          height: '78vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack direction="column" justifyContent="flex-start" spacing={0.75}>
          {!Utilities.stringToBool(process.env.REACT_APP_HIDE_RECEIVING) && (
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                sessionManager.setPrintLabelsNextPath('/ReceiveMenu');
                navigate('/PrintLabels');
              }}
            >
              Receiving
            </Button>
          )}
          {!Utilities.stringToBool(process.env.REACT_APP_HIDE_TRANSFER) && (
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                sessionManager.setPrintLabelsNextPath('/Transfer');
                navigate('/PrintLabels');
              }}
            >
              Transfer
            </Button>
          )}
          {!Utilities.stringToBool(process.env.REACT_APP_HIDE_CYCLE) && (
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                sessionManager.setPrintLabelsNextPath('/Cycle');
                navigate('/PrintLabels');
              }}
            >
              Cycle
            </Button>
          )}
          {!Utilities.stringToBool(process.env.REACT_APP_HIDE_MANIFEST) && (
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                navigate('/ManifestMenu');
              }}
            >
              Manifest
            </Button>
          )}
          {!Utilities.stringToBool(process.env.REACT_APP_HIDE_RETURNS) && (
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                sessionManager.setPrintLabelsNextPath('/Returns');
                navigate('/PrintLabels');
              }}
            >
              Returns
            </Button>
          )}
          {!Utilities.stringToBool(process.env.REACT_APP_HIDE_RDCCPU) && (
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                sessionManager.setPrintLabelsNextPath('/RDCCPU');
                navigate('/PrintLabels');
              }}
            >
              RDC CPU
            </Button>
          )}
          {!Utilities.stringToBool(process.env.REACT_APP_HIDE_DLRMANIFEST) && (
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                navigate('/DlrManifest');
              }}
            >
              Dealer Process Manifest
            </Button>
          )}
        </Stack>
        <div
          id="logout-div"
          style={{ alignSelf: 'center', marginTop: 'auto', width: '100%' }}
        >
          <Button
            id="logout-div"
            fullWidth
            variant="contained"
            size="small"
            onClick={() => {
              sessionManager.clearSessionManager();
              navigate('/');
            }}
          >
            Logout
          </Button>
        </div>
      </div>
    </Stack>
  );
}

export default Main;
