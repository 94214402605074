import App from '../App';
import Main from './Main/Main';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PrintLabels from './PrintLabels/PrintLabels';
import ReceiveMenu from './ReceiveMenu/ReceiveMenu';
import Configuration from './configuration/Configuration';
import { ThemeProvider } from '@mui/material';
import { customTheme } from '../theme';
import PrivateRoute from './PrivateRoute';
import ReceiveSerialized from './ReceiveSerialized';
import ReceiveSKU from './ReceiveSKU';
import Transfer from './Transfer';
import ManifestMenu from './ManifestMenu/ManifestMenu';
import ExternalOrderManifest from './ExternalOrderManifest';
import Utilities from './Utils/Utilities';
import ManifestMove from './ManifestMove';
import ManifestLoadUnload from './ManifestLoadUnload';
import Returns from './Returns';
import PrintPage from './PrintPage';
import CycleCount from './CycleCountComponents/CycleCount';
import CycleCountFields from './CycleCountComponents/CycleCountFields';
import RDCCPU from './RDCCPU';
import DealerProcessManifest from './DealerProcessManifest';
import SessionErrorDialog from '../SessionErrorDialog';

function RouteWrapper() {
  const showReceiving = !Utilities.stringToBool(process.env.REACT_APP_HIDE_RECEIVING);
  const showTransfer = !Utilities.stringToBool(process.env.REACT_APP_HIDE_TRANSFER);
  const showCycle = !Utilities.stringToBool(process.env.REACT_APP_HIDE_CYCLE);
  const showManifest = !Utilities.stringToBool(process.env.REACT_APP_HIDE_MANIFEST);
  const showReturns = !Utilities.stringToBool(process.env.REACT_APP_HIDE_RETURNS);
  const showRDCCPU = !Utilities.stringToBool(process.env.REACT_APP_HIDE_RDCCPU);
  const showPrintPage = !Utilities.stringToBool(process.env.REACT_APP_HIDE_PRINT_PAGE);
  const showDealerManifest = !Utilities.stringToBool(
    process.env.REACT_APP_HIDE_DLRMANIFEST
  );

  return (
    <ThemeProvider theme={customTheme}>
      <BrowserRouter>
        <SessionErrorDialog></SessionErrorDialog>
        <Routes>
          <Route
            path="/"
            element={<App />}
            shouldRevalidate={({}) => {
              return true;
            }}
          />
          <Route path="/Configuration" element={<Configuration />} />
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/Main" element={<Main />} />
            <Route path="/PrintLabels" element={<PrintLabels />} />
            {showReceiving && <Route path="/ReceiveMenu" element={<ReceiveMenu />} />}
            {showReceiving && (
              <Route path="/ReceiveSerialized" element={<ReceiveSerialized />} />
            )}
            {showReceiving && <Route path="/ReceiveSKU" element={<ReceiveSKU />} />}
            {showTransfer && <Route path="/Transfer" element={<Transfer />} />}
            {showManifest && <Route path="/ManifestMenu" element={<ManifestMenu />} />}
            {showManifest && <Route path="/ManifestMove" element={<ManifestMove />} />}
            {showManifest && (
              <Route path="/ExternalOrderManifest" element={<ExternalOrderManifest />} />
            )}
            {showManifest && (
              <Route path="/ManifestLoadUnload" element={<ManifestLoadUnload />} />
            )}
            {showReturns && <Route path="/Returns" element={<Returns />} />}
            {showRDCCPU && <Route path="/RDCCPU" element={<RDCCPU />} />}
            {showDealerManifest && (
              <Route path="/DlrManifest" element={<DealerProcessManifest />} />
            )}
            {showPrintPage && <Route path="/PrintPage" element={<PrintPage />} />}
            {showCycle && <Route path="/Cycle/" element={<CycleCount />} />}
            {showCycle && (
              <Route
                path="/Cycle/:locationEmptyParam/:toastTriggeredParam"
                element={<CycleCount />}
              />
            )}
            {showCycle && (
              <Route
                path="/CycleCountFields/:locationParam/:stockLocationIDParam"
                element={<CycleCountFields />}
              />
            )}
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default RouteWrapper;
