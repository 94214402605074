import { Button, Grid, TextField, Typography } from '@mui/material';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import loginHeader from '../../images/loginHeader.jpg';
import rmsLogo from '../../images/rmsLogo.gif';
import AppService, { ProfitCenterIdsResponse } from '../../Services/app.service';
import './Login.css';
import { useEffect, useState } from 'react';
import { LoginConfiguration } from './LoginConfiguration';
import { SessionManager } from '../SessionManager';
import AlertDialog from '../Dialogs/AlertDialog';
import Utilities from '../Utils/Utilities';
import ProfitCenterSelectionModal from '../Dialogs/ProfitCenterSelectionModal';

function Login() {
  const loginConfig: LoginConfiguration = new LoginConfiguration();
  const navigateField = useNavigate();
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [storeNumber, setStoreNumber] = useState('');
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [showDealerSelectDialog, setShowDealerSelectDialog] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [sessionID, setSessionID] = useState('');
  let [listOfPcIds, setListOfPcIds] = useState<ProfitCenterIdsResponse[]>([]);
  const sessionManager = new SessionManager();

  useEffect(() => {
    if (listOfPcIds.length > 1) {
      setShowDealerSelectDialog(true);
    }
  }, [listOfPcIds]);

  const getAndSetAdditionalPCs = async () => {
    const appService = new AppService();
    return await appService
      .getPcIdsForNaturalStoreId(storeNumber)
      .then(async (result: ProfitCenterIdsResponse[]) => {
        setListOfPcIds(result);
        return result;
      });
  };

  return (
    <div id="Login-Page">
      <AlertDialog
        message={alertMessage}
        showDialog={showAlertDialog}
        closeDialog={closeDialog}
      />
      <Grid
        id="image-grid"
        container
        sx={{ width: '100%', mx: 'auto' }}
        alignItems="center"
      >
        <Grid item xs={5}>
          <img id="login-header" src={loginHeader} alt={'Login Header'} />
        </Grid>
        <Grid item xs={7}>
          <img id="rms-logo" src={rmsLogo} alt={'Logo'} />
        </Grid>
      </Grid>

      <Grid
        id="form-grid"
        container
        sx={{ width: '100%' }}
        rowSpacing={2}
        alignItems="center"
        justifyContent={'left'}
        columnSpacing={2}
      >
        <Grid item xs={4}>
          <label htmlFor="login-name">Login Name</label>
        </Grid>
        <Grid item xs={8}>
          <select
            id="login-name"
            required
            value={username}
            style={{ borderColor: 'black', borderRadius: '4px' }}
            onChange={(e) => setUsername(e.target.value)}
          >
            <option value="" hidden disabled>
              Select Login Name
            </option>
            {loginConfig.getAllUsernames().map((user, index) => (
              <option key={user} value={user}>
                {user}
              </option>
            ))}
          </select>
        </Grid>
        <Grid item xs={4}>
          <label htmlFor="store-number">Store #</label>
        </Grid>
        <Grid item xs={8}>
          <select
            id="store-number"
            required
            value={storeNumber}
            style={{ borderColor: 'black', borderRadius: '4px' }}
            onChange={(e) => setStoreNumber(e.target.value)}
          >
            <option value="" hidden disabled>
              Select Store Number
            </option>
            {loginConfig.getAllStoreNumbers().map((storeNumber, index) => (
              <option key={storeNumber} value={storeNumber}>
                {storeNumber}
              </option>
            ))}
          </select>
        </Grid>
        <Grid item xs={4}>
          <label htmlFor="password-input">Password</label>
        </Grid>
        <Grid id="password-field" item xs={8}>
          <TextField
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            id="password-input"
          ></TextField>
        </Grid>
        <Grid item xs={4}>
          <Typography
            lineHeight={'0em'}
            hidden={Utilities.stringToBool(process.env.REACT_APP_HIDE_ENVIRONMENT_TAB)}
            variant="caption"
          >
            Environment
          </Typography>
        </Grid>
        <Grid lineHeight={0.8} item xs={8}>
          <Typography
            lineHeight={0}
            hidden={Utilities.stringToBool(process.env.REACT_APP_HIDE_ENVIRONMENT_TAB)}
            variant="caption"
            sx={{ wordBreak: 'break-all' }}
          >
            {sessionManager.getRMSEnvironment()}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        id="button-grid"
        container
        direction={'column'}
        rowSpacing={4}
        data-testid="login-button-grid"
      >
        <Grid item xs={12}>
          <Button
            data-testid="loginID"
            fullWidth
            variant="contained"
            size="large"
            onClick={() => {
              loginToApp(navigateField, username, password, storeNumber);
            }}
          >
            Login
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            size="small"
            onClick={() => {
              openUserConfiguration(navigateField);
            }}
          >
            Config
          </Button>
        </Grid>
      </Grid>
      <ProfitCenterSelectionModal
        data-testid="profitCenterSelectorModal"
        listOfPcs={listOfPcIds}
        sessionID={sessionID}
        showDialog={showDealerSelectDialog}
        closeDialog={() => {
          setShowDealerSelectDialog(false);
          navigateField('/Main');
        }}
        closeDialogOnError={() => {
          setShowDealerSelectDialog(false);
        }}
      ></ProfitCenterSelectionModal>
    </div>
  );

  function closeDialog() {
    setShowAlertDialog(false);
  }

  async function loginToApp(
    navigate: NavigateFunction,
    username: string,
    password: string,
    storeNumber: string
  ) {
    const appService = new AppService();
    appService.login(username, password, storeNumber).then(async (r) => {
      const parser = new DOMParser();
      const xml = parser.parseFromString(await r['data'], 'text/xml');
      const loginResult = xml.querySelector('loginResult');
      if (loginResult?.getAttribute('loggedin') === 'true') {
        const sessionIDFromLogin = xml
          .querySelector('loginResult')
          ?.getAttribute('session');
        const profitCenterID = xml.querySelector('loginResult')?.getAttribute('pcid');
        if (sessionIDFromLogin && profitCenterID) {
          setSessionID(sessionIDFromLogin);
          let listOfPcIdsTest = await getAndSetAdditionalPCs();

          if (listOfPcIdsTest.length === 1) {
            sessionManager.setSessionAndProfitCenterID(
              sessionIDFromLogin,
              profitCenterID
            );
            navigate('/Main');
          }
        }
      } else {
        if (loginResult?.getAttribute('error') === 'Invalid PC ID') {
          setAlertMessage('Invalid PC ID');
        } else {
          setAlertMessage('Login Failed');
        }
        setShowAlertDialog(true);
        setPassword('');
      }
    });
  }
}

function openUserConfiguration(navigate: NavigateFunction) {
  navigate('/Configuration');
}

export default Login;
