import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SessionManager } from '../SessionManager';

function ManifestMenu() {
  const navigate = useNavigate();
  let sessionManager: SessionManager = new SessionManager();
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignContent={'stretch'}
      spacing={1}
    >
      <Typography paddingTop={3} paddingLeft={2} variant="h6" id="manifest-menu-label">
        <label>Manifest Menu</label>
      </Typography>
      <div id="button-stack-div">
        <Stack direction="column" justifyContent="flex-start" spacing={1}>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              sessionManager.setPrintLabelsNextPath('/ManifestLoadUnload');
              navigate('/PrintLabels');
            }}
          >
            LOAD / UNLOAD
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              sessionManager.setPrintLabelsNextPath('/ManifestMove');
              navigate('/PrintLabels');
            }}
          >
            ADD / ADD & LOAD
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              navigate('/ExternalOrderManifest');
            }}
          >
            EXTERNAL LOAD / UNLOAD
          </Button>
        </Stack>
        <div id="main-menu-button">
          <Button
            id="main-menu-button"
            fullWidth
            variant="contained"
            size="small"
            onClick={() => {
              navigate('/Main');
            }}
          >
            Main Menu
          </Button>
        </div>
      </div>
    </Stack>
  );
}

export default ManifestMenu;
