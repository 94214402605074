import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import AppService, {
  GetReturnTypeResponse,
  ReturnReason,
  ReturnType,
} from '../../Services/app.service';
import { SessionManager } from '../SessionManager';

export interface PriorityFillProps {
  updatePriorityFill: (priorityFill: string) => void;
  returnTypeResponse: GetReturnTypeResponse;
}

function PriorityFillComponent(props: PriorityFillProps) {
  const [priorityFills, setPriorityFills] = useState<ReturnReason[]>([]);
  const [priorityFillEntry, setPriorityFillEntry] = useState<string>('1001');
  const appService: AppService = new AppService();
  const sessionManager: SessionManager = new SessionManager();
  const [isPriorityFillDisabled, setIsPriorityFillDisabled] = useState<boolean>(false);

  useEffect(() => {
    appService
      .getPriorityFill(sessionManager.getProfitCenterID())
      .then((priorityFills) => {
        setPriorityFills(priorityFills);
        props.updatePriorityFill(priorityFillEntry);
      });
  }, []);

  useEffect(() => {
    let isDisabled: boolean =
      props.returnTypeResponse.realReturnType !== 'HomeReturn' &&
      props.returnTypeResponse.returnType === ReturnType.UnscheduledReturn;
    setIsPriorityFillDisabled(isDisabled);
  }, [props.returnTypeResponse.realReturnType]);

  function OnPriorityChange(priority: string) {
    setPriorityFillEntry(priority);
    props.updatePriorityFill(priority);
  }
  return (
    <>
      {!isPriorityFillDisabled && (
        <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
          <label htmlFor="priorityDropdown">Priority Fill</label>
        </Grid>
      )}
      {!isPriorityFillDisabled && (
        <Grid item xs={9} id="priorityDropdown">
          <select
            value={priorityFillEntry}
            data-testid="priorityDropdown"
            style={{
              borderColor: 'black',
              borderRadius: '4px',
              width: '100%',
              fontSize: 'medium',
              paddingTop: '0.25em',
              paddingBottom: '0.25em',
            }}
            onChange={(eventData) => {
              OnPriorityChange(eventData.target.value);
            }}
          >
            {priorityFills.map((priority) => {
              return (
                <option key={priority.key} value={priority.key}>
                  {priority.value}
                </option>
              );
            })}
          </select>
        </Grid>
      )}
    </>
  );
}
export default PriorityFillComponent;
