import { SessionManager } from '../component/SessionManager';
import { Vendor } from './app.service';

export class IDType {
  static POID: IDType = new IDType('POID');
  static INVALID: IDType = new IDType('INVALID');
  static EPC: IDType = new IDType('EPC');
  static SKU: IDType = new IDType('SKU');
  static PICKID: IDType = new IDType('PICKID');
  static ACK2EPC: IDType = new IDType('EPC');

  private typeName: string;

  constructor(name: string) {
    this.typeName = name;
  }

  toString() {
    return this.typeName;
  }
}

export interface TypeDefinition {
  type: IDType;
  isValid: boolean;
  message: string;
}

export interface IDValidationType {
  isOfThisType(id: string): TypeDefinition;
  formattedID(id: string): string;
}
export class POIDTwoDashValidationType implements IDValidationType {
  isOfThisType(id: string): TypeDefinition {
    let isValid: boolean =
      (id + ' ').match(/^[A-Za-z0-9]+-[A-Za-z0-9]{3,3}-[A-Za-z0-9]{3,3} /) !== null;
    return {
      type: isValid ? IDType.POID : IDType.INVALID,
      isValid: isValid,
      message: '',
    };
  }

  formattedID(id: string): string {
    if (id.substring(0, 1).toUpperCase() === 'K') {
      return id.substring(1);
    }
    return id;
  }
}
export class POIDOneDashValidationType implements IDValidationType {
  isOfThisType(id: string): TypeDefinition {
    let isValid: boolean = (id + ' ').match(/^[A-Za-z0-9]+-[A-Za-z0-9]{6,6} /) !== null;
    return {
      type: isValid ? IDType.POID : IDType.INVALID,
      isValid: isValid,
      message: '',
    };
  }

  formattedID(id: string): string {
    if (id.substring(0, 1).toUpperCase() === 'K') {
      id = id.substring(1);
    }
    let strArr = id.split('-');
    let temp1 = strArr[1].slice(0, 3);
    let temp2 = strArr[1].slice(3, 6);
    let newStr = strArr[0] + '-' + temp1 + '-' + temp2;
    return newStr;
  }
}

export class EPCValidationType implements IDValidationType {
  isOfThisType(id: string): TypeDefinition {
    let isEPC: boolean = (id + ' ').match(/^[A-Za-z0-9]{2,6}-[0-9]{8,20} /) !== null;
    let passesDigitCheck = this.checkEPCDigit(id);
    return {
      isValid: isEPC && passesDigitCheck,
      type: isEPC ? IDType.EPC : IDType.INVALID,
      message: isEPC && !passesDigitCheck ? 'ID :Invalid EPC checkdigit' : '',
    };
  }

  private checkEPCDigit(id: string) {
    let numToCheck = createEPCDigit(id.substring(0, id.length - 1));
    return parseInt(id.substring(id.length - 1, id.length)) === numToCheck;
  }

  formattedID(id: string): string {
    return id;
  }
}

export class SKUValidationType implements IDValidationType {
  isOfThisType(id: string): TypeDefinition {
    let isValid: boolean = (id + ' ').match(/^[0-9]+-[0-9]{5,5}-[0-9]{1,1} /) !== null;
    return {
      type: isValid ? IDType.SKU : IDType.INVALID,
      isValid: isValid,
      message: '',
    };
  }
  formattedID(id: string): string {
    return id;
  }
}

export class PICKIDValidationType implements IDValidationType {
  isOfThisType(id: string): TypeDefinition {
    let isValid: boolean = (id + ' ').match(/^[Pp][0-9]+ /) !== null;
    return {
      type: isValid ? IDType.PICKID : IDType.INVALID,
      isValid: isValid,
      message: '',
    };
  }
  formattedID(id: string): string {
    return id;
  }
}

export class ACK2EPCValidationType implements IDValidationType {
  isOfThisType(id: string): TypeDefinition {
    let isValid: boolean = id.match(/^(1K)?[0-9]{15}/) !== null;

    return {
      type: isValid ? IDType.ACK2EPC : IDType.INVALID,
      isValid: isValid,
      message: '',
    };
  }
  formattedID(id: string): string {
    let epcPrefix: string = 'LAZB-';
    let ackDigits: string = '';

    if (id.substring(0, 2) === '1K') {
      ackDigits = id.slice(2);
      if (ackDigits.length === 16) {
        ackDigits = ackDigits.slice(0, -1);
      }
      let ackDigitsInt = parseInt(ackDigits);
      if (ackDigitsInt > 501010000000000 && ackDigitsInt <= 802030000000000) {
        ackDigits = '0' + ackDigits;
      } else if (ackDigitsInt > 802030000000000) {
        ackDigits = '1' + ackDigits;
      } else if (ackDigitsInt <= 501010000000000) {
        ackDigits = '2' + ackDigits;
      }
    } else {
      ackDigits = id;
    }
    let epcCheckDigit = createEPCDigit(epcPrefix + ackDigits);
    return epcPrefix + ackDigits + epcCheckDigit;
  }
}

function createEPCDigit(id: string) {
  let sum = 0;
  let numToCheck = 0;
  for (let i = 0; i < id.length; i++) {
    const charAsInt = parseInt(id.charAt(i));
    if (!isNaN(charAsInt)) {
      if (i % 2 === 0) {
        sum += charAsInt;
      } else {
        sum += charAsInt * 3;
      }
    }
  }
  numToCheck = sum % 10;
  return numToCheck;
}

export class EPCWithVendor implements IDValidationType {
  isOfThisType(id: string): TypeDefinition {
    let has20numbers: boolean = (id + ' ').match(/^[0-9]{20} /) !== null;
    if (has20numbers) {
      let uccCompanyForVendor: string = id.substring(0, 10);
      let sessionManager: SessionManager = new SessionManager();
      let foundVender: Vendor | undefined = sessionManager
        .getVendors()
        .find((vendor) => vendor.upcCompany === uccCompanyForVendor);
      return {
        type: IDType.EPC,
        isValid: foundVender ? true : false,
        message: foundVender ? '' : 'ID :Vendor not Found',
      };
    }

    return {
      type: IDType.INVALID,
      isValid: false,
      message: '',
    };
  }

  formattedID(id: string): string {
    let sessionManager: SessionManager = new SessionManager();
    let uccCompanyNumber: string = id.substring(0, 10);
    let uccvendor = sessionManager
      .getVendors()
      .find((vendor) => vendor.upcCompany === uccCompanyNumber);
    let prefix = uccvendor?.vendorCode;
    let suffix: string = id.substring(10, 20);
    let idWithDash = prefix + '-' + suffix;
    let epcDigit = createEPCDigit(idWithDash);
    return idWithDash + epcDigit;
  }
}
