import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Validation, {
  ValidationResult,
  IDValidationResult,
} from '../Services/validation.service';
import { IDType } from '../Services/IDValidationTypes';
import { useRef } from 'react';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import AppService, { ScanResponse } from '../Services/app.service';
import AlertDialog from './Dialogs/AlertDialog';
import { SessionManager } from './SessionManager';
import { DisplayValue } from './Utils/DisplayValue';
import AcceptDialog from './Dialogs/AcceptDialog';
import { DialogMetaData } from './Utils/DialogMetaData';

function ReceiveSerialized() {
  const appService = new AppService();
  const actionCode = 'RCV';
  const qty = 1;
  const [formattedId, setFormattedId] = useState<IDValidationResult>({
    isValid: false,
    message: '',
    type: IDType.INVALID,
    id: '',
  });
  const idRef = useRef<HTMLInputElement>();
  const bolRef = useRef<HTMLInputElement>();
  const locationRef = useRef<HTMLInputElement>();
  let enterRef: HTMLButtonElement | null;
  const navigate = useNavigate();
  const [bolEntry, setBolEntry] = useState(new DisplayValue(''));
  const [idEntry, setIdEntry] = useState(new DisplayValue(''));
  const [locationEntry, setLocationEntry] = useState(new DisplayValue(''));
  const [dialogData, setDialogData] = useState<DialogMetaData>({
    showDialog: false,
    textFieldRefToBeFocused: undefined,
  });
  const [showAcceptDialogData, setShowAcceptDialog] = useState<DialogMetaData>({
    showDialog: false,
    textFieldRefToBeFocused: undefined,
  });
  const [alertMessage, setAlertMessage] = useState('');
  let fieldValidation: Validation = new Validation();
  const sessionManager = new SessionManager();

  function closeAlertDialog() {
    setDialogData({
      showDialog: false,
      textFieldRefToBeFocused: dialogData.textFieldRefToBeFocused,
    });
  }

  function noButtonFunction() {
    setShowAcceptDialog({
      showDialog: false,
      textFieldRefToBeFocused: bolRef,
    });
    clearAllInputs();
  }

  function yesButtonFunction() {
    setShowAcceptDialog({
      showDialog: false,
      textFieldRefToBeFocused: idRef,
    });
    setIdEntry(new DisplayValue(''));
  }

  useEffect(() => {
    if (!dialogData.showDialog) {
      dialogData.textFieldRefToBeFocused?.current?.focus();
    }
  }, [dialogData]);

  useEffect(() => {
    if (!showAcceptDialogData.showDialog) {
      showAcceptDialogData.textFieldRefToBeFocused?.current?.focus();
    }
  }, [showAcceptDialogData]);

  function focusLostBOL() {
    if (bolEntry.getDisplayValue() !== '') {
      fieldValidation
        .validateBOL(bolEntry.getDisplayValue())
        .then((result: ValidationResult) => {
          if (!result.isValid) {
            setAlertMessage(result.message);
            setDialogData({ showDialog: true, textFieldRefToBeFocused: bolRef });
          }
        });
    }
  }

  function handleBOLDataChange(eventData: ChangeEvent<HTMLInputElement>) {
    setBolEntry(new DisplayValue(eventData.target.value));
  }

  useEffect(() => {
    if (bolEntry.getOriginalValue().search(/\n/) > 0) {
      idRef?.current?.focus();
    }
  }, [bolEntry]);

  function focusLostID() {
    if (idEntry.getDisplayValue() !== '') {
      let result: IDValidationResult = fieldValidation.validateId(
        idEntry.getDisplayValue()
      );
      if (!result.isValid) {
        setAlertMessage(result.message);
        setDialogData({ showDialog: true, textFieldRefToBeFocused: idRef });
      } else {
        setFormattedId(result);
      }
    }
  }

  function handleIdDataChange(eventData: ChangeEvent<HTMLInputElement>) {
    setIdEntry(new DisplayValue(eventData.target.value));
  }

  useEffect(() => {
    if (idEntry.getOriginalValue().search(/\n/) > 0) {
      locationRef?.current?.focus();
    }
  }, [idEntry]);

  useEffect(() => {
    if (locationEntry.getOriginalValue().search(/\n/) > 0) {
      enterRef?.focus();
    }
  }, [locationEntry]);

  function focusLostLocation() {
    if (locationEntry.getDisplayValue() !== '') {
      locationValidation();
    }
  }

  function locationValidation() {
    fieldValidation
      .validateLocation(locationEntry.getDisplayValue())
      .then((result: ValidationResult) => {
        if (!result.isValid) {
          setAlertMessage(result.message);
          setDialogData({ showDialog: true, textFieldRefToBeFocused: locationRef });
        }
      });
  }

  function handleLocationDataChange(eventData: ChangeEvent<HTMLInputElement>) {
    setLocationEntry(new DisplayValue(eventData.target.value));
  }

  useEffect(() => {
    if (locationEntry.getOriginalValue().search(/\n/) > 0) {
      locationValidation();
    }
  }, [locationEntry]);

  async function enterButtonClick() {
    const success = await applyScan(
      appService,
      sessionManager,
      qty,
      actionCode,
      bolEntry,
      formattedId,
      locationEntry
    );
    if (success) {
      if (sessionManager.getPrinter() !== '') {
        appService
          .printLabels(
            formattedId.type,
            formattedId.id,
            sessionManager.getPrinter(),
            '1',
            locationEntry.getDisplayValue()
          )
          .then((response: ScanResponse) => {
            if (!response.valid) {
              setAlertMessage(response.error);
              setDialogData({
                showDialog: true,
                textFieldRefToBeFocused: dialogData.textFieldRefToBeFocused,
              });
            } else {
              setShowAcceptDialog({
                showDialog: true,
                textFieldRefToBeFocused: showAcceptDialogData.textFieldRefToBeFocused,
              });
            }
          });
      }
    }
  }
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignContent={'stretch'}
      spacing={1}
      marginRight="1em"
      display={'flex'}
      height={'86vh'}
    >
      <AlertDialog
        message={alertMessage}
        showDialog={dialogData.showDialog}
        closeDialog={closeAlertDialog}
      />

      <AcceptDialog
        messageLine1="Item accepted:"
        messageLine2="Continue same BOL?"
        showDialog={showAcceptDialogData.showDialog}
        noButtonFunction={noButtonFunction}
        yesButtonFunction={yesButtonFunction}
        icon={<TaskAltIcon fontSize="large" htmlColor="#2E7D32" />}
      />
      <Stack height={'442px'} flexShrink={0}>
        <Stack direction="row" justifyContent="space-between" alignItems="baseline">
          <Typography paddingTop={3} paddingLeft={2} marginBottom={3} variant="h6">
            Receive
          </Typography>
          <Button
            variant="contained"
            size="medium"
            onClick={() => {
              navigate('/Main');
            }}
          >
            Done
          </Button>
        </Stack>
        <Typography variant="subtitle1" lineHeight={2}>
          <Grid
            container
            rowSpacing={2}
            justifyItems={'center'}
            justifyContent={'space-between'}
            columnSpacing={1}
            paddingLeft={2}
          >
            <Grid item xs={3} justifyContent={'left'}>
              BOL #
            </Grid>
            <Grid item xs={9}>
              <TextField
                autoFocus
                inputProps={{ 'aria-label': 'bol-number' }}
                inputRef={bolRef}
                value={bolEntry.getDisplayValue()}
                multiline
                minRows={1}
                maxRows={1}
                size="small"
                id="bol-number"
                required
                fullWidth
                onChange={handleBOLDataChange}
                onBlur={focusLostBOL}
              />
            </Grid>
            <Grid item xs={3} justifyContent={'left'}>
              ID
            </Grid>
            <Grid item xs={9}>
              <TextField
                inputProps={{ 'aria-label': 'id' }}
                inputRef={idRef}
                value={idEntry.getDisplayValue()}
                size="small"
                id="id"
                required
                fullWidth
                multiline
                minRows={1}
                maxRows={1}
                onChange={handleIdDataChange}
                onBlur={focusLostID}
              />
            </Grid>
            <Grid item xs={3} justifyContent={'left'}>
              Location
            </Grid>
            <Grid item xs={9}>
              <TextField
                inputProps={{ 'aria-label': 'location' }}
                inputRef={locationRef}
                value={locationEntry.getDisplayValue()}
                size="small"
                id="location"
                required
                fullWidth
                multiline
                minRows={1}
                maxRows={1}
                onChange={handleLocationDataChange}
                onBlur={focusLostLocation}
              />
            </Grid>
          </Grid>
        </Typography>
      </Stack>
      <div
        style={{
          alignSelf: 'center',
          marginTop: '1em',
          width: '100%',
          paddingLeft: '1em',
        }}
      >
        <Button
          id="enter-button"
          fullWidth
          variant="contained"
          size="large"
          ref={(node) => {
            enterRef = node;
          }}
          onClick={() => {
            enterButtonClick();
          }}
        >
          Enter
        </Button>
      </div>
    </Stack>
  );

  function clearAllInputs() {
    setBolEntry(new DisplayValue(''));
    setIdEntry(new DisplayValue(''));
    setLocationEntry(new DisplayValue(''));
  }

  async function applyScan(
    appService: AppService,
    sessionManager: SessionManager,
    qty: number,
    actionCode: string,
    bolEntry: DisplayValue,
    formattedId: IDValidationResult,
    locationEntry: DisplayValue
  ): Promise<boolean> {
    let scanDate = new Date(Date.now()).toISOString().split('T')[0];
    let flag: boolean = false;
    const success = await appService
      .applyScan(
        sessionManager.getProfitCenterID(),
        qty,
        actionCode,
        bolEntry.getDisplayValue(),
        formattedId.id,
        formattedId.type,
        scanDate,
        locationEntry.getDisplayValue(),
        ''
      )
      .then((response: ScanResponse) => {
        if (!response.valid) {
          setAlertMessage(response.error);
          setDialogData({
            showDialog: true,
            textFieldRefToBeFocused: dialogData.textFieldRefToBeFocused,
          });
          flag = false;
        } else {
          flag = true;
          setShowAcceptDialog({
            showDialog: true,
            textFieldRefToBeFocused: showAcceptDialogData.textFieldRefToBeFocused,
          });
        }
        return Promise.resolve(flag);
      });
    return Promise.resolve(success);
  }
}

export default ReceiveSerialized;
