import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function TitleAndDoneButton() {
  const navigate = useNavigate();

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="baseline">
        <Typography paddingTop={3} paddingLeft={2} marginBottom={3} variant="h6">
          All Returns
        </Typography>
        <Button
          sx={{ width: '100px' }}
          variant="contained"
          size="small"
          onClick={() => {
            navigate('/Main');
          }}
        >
          Done
        </Button>
      </Stack>
    </>
  );
}
export default TitleAndDoneButton;
