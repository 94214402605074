import { Alert, Button, Snackbar, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useRef, useState } from 'react';
import AppService, { CycleCountScanResponse } from '../../Services/app.service';
import { useNavigate, useParams } from 'react-router-dom';
import { IDType } from '../../Services/IDValidationTypes';
import Validation from '../../Services/validation.service';
import { SessionManager } from '../SessionManager';
import { DateWrapper } from '../Utils/DateWrapper';
import { DialogMetaData } from '../Utils/DialogMetaData';
import { DisplayValue } from '../Utils/DisplayValue';
import Utilities from '../Utils/Utilities';

let epc = '';
let buttonRef: HTMLButtonElement | null = null;

function CycleCountFields() {
  const { locationParam, stockLocationIDParam } = useParams();
  const validation: Validation = new Validation();
  const navigate = useNavigate();
  const sessionManager = new SessionManager();
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [showFailureToast, setShowFailureToast] = useState<boolean>(false);

  const [toastMessage, setToastMessage] = useState<string>('');

  const [quantityEntry, setQuantityEntry] = useState(new DisplayValue('1'));
  const [idSkuEntry, setIdSkuEntry] = useState(new DisplayValue(''));
  const [locationEntry, setLocationEntry] = useState(new DisplayValue(''));
  const [locEmptyDisabled, setLocEmptyDisabled] = useState<boolean>(false);
  const [disabledFieldColor, setDisabledFieldColor] =
    useState<string>('rgb(143, 208, 236)');

  const idRef = useRef<HTMLInputElement>();
  const quantityRef = useRef<HTMLInputElement>();

  useEffect(Utilities.checkForScannedDataAndClick(idSkuEntry, buttonRef), [idSkuEntry]);
  useEffect(() => {
    idRef.current?.focus();
  }, []);
  useEffect(Utilities.checkForScannedData(quantityEntry, idRef), [quantityEntry]);

  function setDisabledBackgroundColor(setDisabled: boolean) {
    if (setDisabled) {
      setDisabledFieldColor('rgb(50, 50, 50, 0.15)');
    } else {
      setDisabledFieldColor('rgb(143, 208, 236)');
    }
  }

  useEffect(() => {
    if (locEmptyDisabled) {
      setDisabledBackgroundColor(true);
    } else {
      setDisabledBackgroundColor(false);
    }
  }, [locEmptyDisabled]);

  useEffect(() => {
    if (locationParam !== undefined) {
      setLocationEntry(new DisplayValue(locationParam));
    }
  }, []);

  function focusLostQuantity() {
    if (
      Number(quantityEntry.getDisplayValue()) < 1 ||
      Number(quantityEntry.getDisplayValue()) > 999
    ) {
      focusTextfield(quantityRef);
      setToastMessage('Qty must greater than 0 and less than 1000.');
      setShowFailureToast(true);
    } else if (isNaN(Number(quantityEntry.getDisplayValue()))) {
      setToastMessage('Qty must be numeric.');
      setShowFailureToast(true);
      focusTextfield(quantityRef);
    } else {
      focusTextfield(idRef);
    }
  }

  function focusTextfield(
    textFieldRefToBeFocused:
      | React.MutableRefObject<HTMLInputElement | undefined>
      | undefined
  ) {
    textFieldRefToBeFocused?.current?.focus();
  }

  async function enterButtonClick() {
    let quantity = quantityEntry.getDisplayValue();

    const appService: AppService = new AppService();
    if (idSkuEntry.getDisplayValue() !== '') {
      const validationResult = validation.validateId(idSkuEntry.getDisplayValue());
      if (validationResult.isValid === false) {
        setToastMessage(validationResult.message);
        setShowFailureToast(true);
      } else {
        if (
          validationResult.type !== IDType.SKU &&
          validationResult.type !== IDType.PICKID
        ) {
          setQuantityEntry(new DisplayValue('1'));
          quantity = '1';
        }
        let result: CycleCountScanResponse = await appService.cycleCountScan(
          DateWrapper.today(),
          quantity,
          locationEntry.getDisplayValue(),
          sessionManager.getBatchNumber(),
          stockLocationIDParam,
          validationResult.type,
          validationResult.id,
          'N'
        );
        if (!result.valid) {
          setToastMessage(result.error);
          setShowFailureToast(true);
        } else {
          epc = result.epc;
          setLocEmptyDisabled(true);
          setToastMessage(`ID ${validationResult.id}, Qty ${quantity}`);
          setShowSuccessToast(true);

          setQuantityEntry(new DisplayValue('1'));
          setIdSkuEntry(new DisplayValue(''));
        }
      }
    }
    focusTextfield(idRef);
  }

  async function locEmptyButtonClicked() {
    const appService: AppService = new AppService();
    await appService.cycleCountScan(
      DateWrapper.today(),
      '0',
      locationEntry.getDisplayValue(),
      sessionManager.getBatchNumber(),
      stockLocationIDParam,
      IDType.INVALID,
      '',
      'Y'
    );
    navigate(`/Cycle/${locationEntry.getDisplayValue()}/Empty`);
  }
  return (
    <div>
      <Snackbar
        open={showSuccessToast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => {
          setShowSuccessToast(false);
        }}
      >
        <Alert severity="success" sx={{ width: '100%' }} variant="filled">
          {toastMessage}
          <br /> Processed Successfully.
        </Alert>
      </Snackbar>
      <Snackbar
        open={showFailureToast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => {
          setShowFailureToast(false);
        }}
      >
        <Alert severity="error" sx={{ width: '100%' }} variant="filled">
          {toastMessage}
        </Alert>
      </Snackbar>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignContent={'stretch'}
        spacing={1}
        marginRight="1em"
        marginLeft="1em"
        display={'flex'}
        height={'76vh'}
        flexDirection={'column'}
      >
        <Stack height={'400px'} flexShrink={0}>
          <Stack direction="row" justifyContent="space-between" alignItems="baseline">
            <Typography paddingTop={3} marginBottom={0.5} variant="h6">
              <label>Cycle Count</label>
              <Typography sx={{ fontSize: 16 }}>
                <label>Batch: {sessionManager.getBatchNumber()}</label>
              </Typography>
            </Typography>

            <Button
              sx={{ width: '100px' }}
              variant="contained"
              size="small"
              onClick={() => {
                navigate('/Main');
              }}
            >
              DONE
            </Button>
          </Stack>
          <Grid
            container
            direction={'row'}
            rowSpacing={2}
            alignItems={'flex-start'}
            justifyContent={'flex-end'}
            columnSpacing={1}
          >
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              Location
            </Grid>
            <Grid item xs={9}>
              <TextField
                inputProps={{ 'aria-label': 'locationInput' }}
                data-testid="locationInput"
                multiline
                minRows={1}
                maxRows={1}
                size="small"
                disabled={true}
                style={{
                  backgroundColor: 'rgb(50, 50, 50, 0.15)',
                }}
                fullWidth
                value={locationEntry.getDisplayValue()}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography>Qty</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                inputProps={{ 'aria-label': 'QuantityField' }}
                multiline
                minRows={1}
                maxRows={1}
                fullWidth
                size="small"
                onBlur={focusLostQuantity}
                onChange={(eventData) => {
                  setQuantityEntry(new DisplayValue(eventData.target.value));
                }}
                value={quantityEntry.getDisplayValue()}
                inputRef={quantityRef}
              />
            </Grid>
            <Grid item xs={3}>
              <Typography>ID/SKU</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                inputProps={{ 'aria-label': 'IDField' }}
                multiline
                minRows={1}
                maxRows={1}
                fullWidth
                size="small"
                onChange={(eventData) => {
                  setIdSkuEntry(new DisplayValue(eventData.target.value));
                }}
                value={idSkuEntry.getDisplayValue()}
                inputRef={idRef}
              />
            </Grid>
            <Grid item container justifyContent="flex-end">
              <Button
                size="small"
                variant="contained"
                disabled={locEmptyDisabled}
                style={{ backgroundColor: disabledFieldColor }}
                onClick={() => {
                  locEmptyButtonClicked();
                }}
              >
                Loc. Empty
              </Button>
            </Grid>
          </Grid>
        </Stack>
        <Stack
          direction="column"
          justifyContent="space-between"
          spacing={2}
          marginRight="1em"
          marginLeft="1em"
        >
          <Button
            fullWidth
            variant="contained"
            size="medium"
            ref={(node) => {
              buttonRef = node;
            }}
            onClick={() => {
              enterButtonClick();
            }}
          >
            Enter
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{ alignSelf: '', marginTop: 'auto' }}
            size="small"
            onClick={() => {
              navigate(`/Cycle`);
            }}
          >
            Back
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}

export default CycleCountFields;
