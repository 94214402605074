import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import AppService, { Truck } from '../../Services/app.service';
import { SessionManager } from '../SessionManager';
import Utilities from '../../component/Utils/Utilities';
interface Properties {
  label: string;
  updateTruckEntry: (truck: Truck) => void;
}

function TruckISTField(props: Properties) {
  const [truckList, setTruckList] = useState<Truck[]>([]);
  const appService: AppService = new AppService();
  const sessionManager: SessionManager = new SessionManager();

  useEffect(() => {
    appService.getTrucks(sessionManager.getProfitCenterID()).then((response) => {
      setTruckList(response.trucks);
    });
  }, []);

  return (
    <>
      <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
        {props.label}
      </Grid>
      <Grid item xs={9}>
        <select
          data-testid="truckDropdown"
          style={{
            borderColor: 'black',
            borderRadius: '4px',
            width: '100%',
            fontSize: 'medium',
            paddingTop: '0.25em',
            paddingBottom: '0.25em',
          }}
          onChange={(eventData) => {
            let truck = truckList.filter(
              (truck) => truck.manifestDate === eventData.target.value
            );
            props.updateTruckEntry(truck[0]);
          }}
        >
          <option value="">- SELECT -</option>
          {truckList.map((truck) => {
            return (
              <option key={truck.name} value={truck.manifestDate}>
                {truck.name}{' '}
                {truck.manifestDate != ''
                  ? Utilities.formatDateWithSlashes(truck.manifestDate)
                  : ''}
              </option>
            );
          })}
        </select>
      </Grid>
    </>
  );
}

export default TruckISTField;
