import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import './ConfigPanel.css';
import { useState } from 'react';

interface ConfigPanelProps {
  tabTitle: string;
  addFunction: (itemName: string) => void;
  deleteFunction: (itemName: string) => void;
  getAllItemsFunction: () => string[];
}

interface SelectedItem {
  index: number;
  value: string;
}

function ConfigPanel(props: ConfigPanelProps) {
  const { tabTitle } = props;
  const [entry, setEntry] = useState('');
  const [rerenderTrigger, triggerRerender] = useState(false);
  const [selectedItem, setSelectedItem] = useState({ index: 0, value: '' });

  function addItem(itemName: string) {
    if (itemName.length > 0) {
      if (!props.getAllItemsFunction().includes(itemName)) {
        props.addFunction(itemName);
        triggerRerender(!rerenderTrigger);
      }
    }
  }

  function deleteSelectedItem(selectedItem: SelectedItem) {
    props.deleteFunction(selectedItem.value);
    triggerRerender(!rerenderTrigger);
  }

  function getAllItems() {
    const items = props.getAllItemsFunction();
    return items;
  }

  return (
    <div id="user-container">
      <Typography variant="subtitle1">
        <Grid
          container
          id="main-grid"
          direction="row"
          justifyContent="flex-start"
          item
          columnSpacing={1}
        >
          <Grid item xs={12}>
            <label htmlFor="user-name" id="user-label">
              {tabTitle}
            </label>
          </Grid>
          <Grid item xs={9}>
            <TextField
              variant="outlined"
              fullWidth
              id="user-name"
              onChange={(e) => setEntry(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              size="small"
              variant="contained"
              id="add-button"
              onClick={() => {
                addItem(entry);
              }}
            >
              Add
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ border: 1, borderColor: '#797979', marginTop: '2em' }}>
              <List
                sx={{
                  overflow: 'auto',
                  maxHeight: '175px',
                  minHeight: '175px',
                  alignContent: 'left',
                  paddingTop: '0em',
                }}
              >
                {getAllItems().map((user, index) => (
                  <ListItem
                    id="list-item"
                    key={user}
                    disableGutters
                    sx={{ height: '1.3em' }}
                    selected={selectedItem.index === index}
                    onClick={() => {
                      setSelectedItem({ index: index, value: user });
                    }}
                  >
                    <ListItemText>{user}</ListItemText>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            sx={{ paddingTop: '1em', paddingBottom: '1em' }}
          >
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                size="small"
                onClick={() => {
                  deleteSelectedItem(selectedItem);
                }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Typography>
    </div>
  );
}

export default ConfigPanel;
