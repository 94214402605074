interface TabPanelProps {
  children?: React.ReactNode;
  selectedIndex: number;
  index: number;
}

function ConfigurationTab(props: TabPanelProps) {
  const { children, selectedIndex, index } = props;
  return (
    <div hidden={hidden(selectedIndex, index)} role="configTab">
      {children}
    </div>
  );
}

export default ConfigurationTab;

function hidden(selectedIndex: number, index: number): boolean | undefined {
  return selectedIndex !== index;
}
