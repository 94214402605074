export class DisplayValue {
  value: string;
  constructor(startingValue: string) {
    this.value = startingValue;
  }

  getOriginalValue() {
    return this.value;
  }

  getDisplayValue() {
    return this.value.trim().toUpperCase();
  }
}
