import AppService, { Vendor } from '../Services/app.service';

export enum SessionManagerKey {
  SESSION_KEY = 'sessionID',
  PROFIT_CENTER_KEY = 'profitCenterID',
  VENDOR_KEY = 'vendors',
  PRINT_LABELS_NEXT_PATH_KEY = 'printLabelsNextPath',
  BATCH_NUMBER_KEY = 'batchNumber',
  RMS_ENVIRONMENT_KEY = 'RMSEnvironment',
  PRINTER_KEY = 'printer',
  PRINTER_ID_KEY = 'printerID',
}

export class SessionManager {
  clearKey(key: SessionManagerKey) {
    sessionStorage.removeItem(key);
  }

  getVendors(): Vendor[] {
    let vendors = sessionStorage.getItem(SessionManagerKey.VENDOR_KEY);
    if (vendors) {
      return JSON.parse(vendors);
    } else {
      return [];
    }
  }

  private setVendors(vendors: Vendor[]) {
    sessionStorage.setItem(SessionManagerKey.VENDOR_KEY, JSON.stringify(vendors));
  }

  setSessionAndProfitCenterID(sessionID: string, profitCenterid: string) {
    sessionStorage.setItem(SessionManagerKey.SESSION_KEY, sessionID);
    sessionStorage.setItem(SessionManagerKey.PROFIT_CENTER_KEY, profitCenterid);
    let service: AppService = new AppService();
    service.getVendors(this.getProfitCenterID()).then((vendors: Vendor[]) => {
      this.setVendors(vendors);
    });
  }

  async setSessionAndSelectedProfitCenterIDForHeritageSession(
    sessionID: string,
    profitCenterid: string
  ) {
    sessionStorage.setItem(SessionManagerKey.SESSION_KEY, sessionID);
    sessionStorage.setItem(SessionManagerKey.PROFIT_CENTER_KEY, profitCenterid);
    let service: AppService = new AppService();
    try {
      await service.setSelectedPcIdOnHeritageSession(profitCenterid);
      service.getVendors(this.getProfitCenterID()).then((vendors: Vendor[]) => {
        this.setVendors(vendors);
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  getSessionID(): string {
    const sessionID = sessionStorage.getItem(SessionManagerKey.SESSION_KEY);
    if (sessionID) {
      return sessionID;
    } else {
      return '';
    }
  }

  getProfitCenterID(): string {
    const profitCenterID = sessionStorage.getItem(SessionManagerKey.PROFIT_CENTER_KEY);
    if (profitCenterID) {
      return profitCenterID;
    } else {
      return '';
    }
  }

  clearSessionManager() {
    sessionStorage.removeItem(SessionManagerKey.SESSION_KEY);
    sessionStorage.removeItem(SessionManagerKey.PROFIT_CENTER_KEY);
    sessionStorage.removeItem(SessionManagerKey.VENDOR_KEY);
    sessionStorage.removeItem(SessionManagerKey.BATCH_NUMBER_KEY);
    sessionStorage.removeItem(SessionManagerKey.PRINTER_KEY);
    sessionStorage.removeItem(SessionManagerKey.PRINTER_ID_KEY);
    sessionStorage.removeItem(SessionManagerKey.PRINT_LABELS_NEXT_PATH_KEY);
  }

  removeProfitCenterID() {
    sessionStorage.removeItem(SessionManagerKey.PROFIT_CENTER_KEY);
  }

  setPrintLabelsNextPath(printLabelsNextPath: string) {
    sessionStorage.setItem(
      SessionManagerKey.PRINT_LABELS_NEXT_PATH_KEY,
      printLabelsNextPath
    );
  }

  getPrintLabelsNextPath(): string {
    const printLabelsNextPath = sessionStorage.getItem(
      SessionManagerKey.PRINT_LABELS_NEXT_PATH_KEY
    );
    if (printLabelsNextPath) {
      return printLabelsNextPath;
    } else {
      return '';
    }
  }

  getBatchNumber(): string {
    const batchNumber = sessionStorage.getItem(SessionManagerKey.BATCH_NUMBER_KEY);
    if (batchNumber) {
      return batchNumber;
    } else {
      return '';
    }
  }

  setBatchNumber(batchNumber: string) {
    sessionStorage.setItem(SessionManagerKey.BATCH_NUMBER_KEY, batchNumber);
  }

  getRMSEnvironment(): string {
    let environment = sessionStorage.getItem(SessionManagerKey.RMS_ENVIRONMENT_KEY);
    if (environment) {
      return environment;
    }
    const defaultEnvironment: string | undefined = process.env.REACT_APP_BASE_RMS_URL;
    if (defaultEnvironment !== undefined) {
      return defaultEnvironment;
    }
    return '';
  }

  setRMSEnvironment(environment: string) {
    sessionStorage.setItem(SessionManagerKey.RMS_ENVIRONMENT_KEY, environment);
  }

  getPrinter() {
    const printer = sessionStorage.getItem(SessionManagerKey.PRINTER_KEY);
    if (printer) return printer;
    else {
      return '';
    }
  }

  setPrinter(printer: string) {
    sessionStorage.setItem(SessionManagerKey.PRINTER_KEY, printer);
  }

  getPrinterID() {
    const printerID = sessionStorage.getItem(SessionManagerKey.PRINTER_ID_KEY);
    if (printerID) return printerID;
    else {
      return '';
    }
  }

  setPrinterID(printerID: string | undefined) {
    if (printerID != undefined) {
      sessionStorage.setItem(SessionManagerKey.PRINTER_ID_KEY, printerID);
    }
  }
}
