import { Grid, Typography } from '@mui/material';
import Utilities from '../Utils/Utilities';

export interface manifestDateProps {
  manifestDateValue: string;
}

function ManifestDate(props: manifestDateProps) {
  function showManifestDate(manifestDate: string): boolean {
    if (manifestDate !== '' && manifestDate !== 'unknown') {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      {showManifestDate(props.manifestDateValue) && (
        <Grid item xs={8} justifyContent={'left'} alignSelf={'center'}>
          Manifest Date: {Utilities.formatDateWithSlashes(props.manifestDateValue)}
        </Grid>
      )}
      {showManifestDate(props.manifestDateValue) && (
        <Grid item xs={4}>
          <Typography></Typography>
        </Grid>
      )}
    </>
  );
}
export default ManifestDate;
