import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { SessionManager, SessionManagerKey } from '../SessionManager';
import './ConfigPanel.css';

function EnvironmentConfigPanel() {
  let sessionManager: SessionManager = new SessionManager();
  const [selectedEnvironment, setSelectedEnvironment] = useState(
    sessionManager.getRMSEnvironment()
  );
  const urls = [
    'https://rms-sso.la-z-boy.com/lazboy',
    'https://rms-dev.la-z-boy.com/lazboy',
    'https://rms-uat.la-z-boy.com/lazboy',
    'https://rms-oat.la-z-boy.com/lazboy',
    'https://rms-pilot.la-z-boy.com/lazboy',
    'https://dev-int.la-z-boy.com/lazboy',
  ];

  useEffect(() => {
    sessionManager.setRMSEnvironment(selectedEnvironment);
  }, [selectedEnvironment]);

  return (
    <div id="user-container">
      <Typography variant="subtitle1">
        <Grid
          container
          id="main-grid"
          direction="row"
          justifyContent="flex-start"
          columnSpacing={1}
          paddingBottom="1em"
        >
          <Grid container justifyContent="flex-end" item xs={12}>
            <Button
              variant="contained"
              onClick={() => {
                sessionManager.clearKey(SessionManagerKey.RMS_ENVIRONMENT_KEY);
                if (process.env.REACT_APP_BASE_RMS_URL) {
                  setSelectedEnvironment(process.env.REACT_APP_BASE_RMS_URL);
                }
              }}
            >
              RESET TO DEFAULT
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                border: 1,
                borderColor: '#797979',
                marginTop: '2em',
                height: '18.2em',
              }}
            >
              <List
                sx={{
                  overflow: 'auto',
                  maxHeight: '275px',
                  minHeight: '175px',
                  alignContent: 'left',
                  paddingTop: '0em',
                }}
              >
                {urls.map((listItem) => {
                  return (
                    <ListItem
                      onClick={() => {
                        setSelectedEnvironment(listItem);
                      }}
                      selected={selectedEnvironment === listItem}
                      id="list-item"
                      key={listItem}
                      disableGutters
                      sx={{ height: '3em' }}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          variant: 'body2',
                          style: {
                            whiteSpace: 'normal',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          },
                        }}
                      >
                        {listItem}
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Grid>
        </Grid>
      </Typography>
    </div>
  );
}

export default EnvironmentConfigPanel;
