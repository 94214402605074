import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppService, {
  CheckPickIdResponse,
  ManifestLoadResponse,
  ScanResponse,
  ValidateStockResponse,
} from '../Services/app.service';
import { IDType } from '../Services/IDValidationTypes';
import Validation, { IDValidationResult } from '../Services/validation.service';
import AlertDialog from './Dialogs/AlertDialog';
import { SessionManager } from './SessionManager';
import { DateWrapper } from './Utils/DateWrapper';
import { DialogMetaData } from './Utils/DialogMetaData';
import { DisplayValue } from './Utils/DisplayValue';
import Utilities from './Utils/Utilities';
let truck = '';
const sessionManager: SessionManager = new SessionManager();
let localdata = {
  epc: '',
  poid: '',
  pickid: '',
  idType: IDType.INVALID,
  getCorrespondingId: () => {
    if (localdata.idType === IDType.EPC || localdata.idType === IDType.ACK2EPC)
      return localdata.epc;
    if (localdata.idType === IDType.POID) return localdata.poid;
    if (localdata.idType === IDType.PICKID) return localdata.pickid;
    return '';
  },
};

function ManifestLoadUnload() {
  const navigate = useNavigate();
  const appService = new AppService();

  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertDialogData, setAlertDialogData] = useState<DialogMetaData>({
    showDialog: false,
    textFieldRefToBeFocused: undefined,
  });
  const [disabledFieldColor, setDisabledFieldColor] = useState<string>(
    'rgb(50, 50, 50, 0.15)'
  );
  const [loadButtonDisabled, setLoadButtonDisabled] = useState<boolean>(true);
  const [unloadButtonDisabled, setUnloadButtonDisabled] = useState<boolean>(true);
  const [locationDisabled, setLocationDisabled] = useState<boolean>(true);
  const [quantityDisabled, setQuantityDisabled] = useState<boolean>(true);
  const [dateEntry, setDateEntry] = useState<Date | null>(DateWrapper.today());
  const [idEntry, setIdEntry] = useState(new DisplayValue(''));
  const [truckEntry, setTruckEntry] = useState(new DisplayValue(''));
  const [locationEntry, setLocationEntry] = useState(new DisplayValue(''));
  const [stopNum, setStopNum] = useState(new DisplayValue('# #'));
  const [quantityEntry, setQuantityEntry] = useState(new DisplayValue('1'));
  const truckRef = useRef<HTMLInputElement>();
  const idRef = useRef<HTMLInputElement>();
  const locationRef = useRef<HTMLInputElement>();
  const quantityRef = useRef<HTMLInputElement>();

  useEffect(() => {
    idRef.current?.focus();
  }, [dateEntry]);

  useEffect(() => {
    if (locationDisabled) {
      setDisabledBackgroundColor(true);
    } else {
      setDisabledBackgroundColor(false);
    }
  }, [locationDisabled]);

  useEffect(Utilities.onDialogClosedFocusField(alertDialogData), [alertDialogData]);
  useEffect(Utilities.checkForScannedData(idEntry, truckRef), [idEntry]);
  useEffect(handleTruckEntryChange(), [truckEntry]);
  useEffect(handleLocationEntryChange(), [locationEntry]);
  useEffect(Utilities.checkForScannedDataAndFocusNone(quantityEntry, quantityRef), [
    quantityEntry,
  ]);

  function handleTruckEntryChange() {
    if (locationRef?.current?.disabled) {
      return Utilities.checkForScannedDataAndFocusNone(truckEntry, truckRef);
    } else {
      return Utilities.checkForScannedData(truckEntry, locationRef);
    }
  }

  function handleLocationEntryChange() {
    if (quantityRef?.current?.disabled) {
      return Utilities.checkForScannedDataAndFocusNone(locationEntry, locationRef);
    } else {
      return Utilities.checkForScannedData(locationEntry, quantityRef);
    }
  }


  async function idLostFocus() {
    setQuantityDisabled(true);
    setLocationDisabled(true);
    localdata.epc = '';
    localdata.poid = '';
    localdata.pickid = '';
    if (idEntry.getDisplayValue() === '') {
      return;
    }
    const validation: Validation = new Validation();
    let idValidationResponse: IDValidationResult = validation.validateId(
      idEntry.getDisplayValue()
    );
    const appService: AppService = new AppService();
    setTruckEntry(new DisplayValue(''));
    truck = '';

    const idType = idValidationResponse.type;
    localdata.idType = idType;
    if (!idValidationResponse.isValid) {
      showAlertAndSetFocus(idValidationResponse.message, idRef);
      setIdEntry(new DisplayValue(''));
      setStopNum(new DisplayValue('# #'));
    } else if (
      idType !== IDType.EPC &&
      idType !== IDType.POID &&
      idType !== IDType.PICKID &&
      idType !== IDType.ACK2EPC
    ) {
      showAlertAndSetFocus('ID :' + idType, idRef);
    } else {
      if (idType === IDType.PICKID) {
        await handlePickID(appService, idValidationResponse);
        localdata.pickid = idValidationResponse.id;
        setLoadButtonDisabled(false);
        setUnloadButtonDisabled(false);
        setLocationDisabled(false);
        setQuantityDisabled(false);
        locationRef.current?.focus();
      } else {
        setQuantityEntry(new DisplayValue('1'));
        const validateStockResult: ValidateStockResponse = await vaildateNonPickID(
          idValidationResponse
        );
        if (
          idValidationResponse.type === IDType.EPC ||
          idValidationResponse.type === IDType.ACK2EPC
        ) {
          localdata.epc = idValidationResponse.id;
        } else if (idValidationResponse.type === IDType.POID) {
          localdata.poid = idValidationResponse.id;
        }

        updateStopAndTruck(validateStockResult.truck, validateStockResult.stop);

        const isAPickUp =
          Utilities.stringToBool(validateStockResult.pickup) &&
          validateStockResult.type === 'IST';

        const isAHomereturn =
          validateStockResult.type === 'DEL' && validateStockResult.deliverytype === 'r';

        const isSealedAndStatusIsO =
          !Utilities.stringToBool(validateStockResult.sealed) &&
          validateStockResult.manifest_status === 'O';

        if (isAPickUp) {
          clearIdAndShowError('Item is a pickup');
        } else if (isAHomereturn) {
          clearIdAndShowError('Item is a home return, use return screen');
        } else if (isSealedAndStatusIsO) {
          setStateOfButtons(validateStockResult);
        } else if (!validateStockResult.valid && validateStockResult.error) {
          clearIdAndShowError(validateStockResult.error);
        } else {
          clearIdAndShowError('Invalid Function - check screen usage.');
        }
      }
    }
  }

  function clearIdAndShowError(errorString: string) {
    setIdEntry(new DisplayValue(''));
    showAlertAndSetFocus(errorString, idRef);
  }

  function focusLostTruck() {
    let validation: Validation = new Validation();
    let response = validation.validateTruck(truckEntry.getDisplayValue());
    if (!response.isValid) {
      showAlertAndSetFocus(response.message, truckRef);
      setTruckEntry(new DisplayValue(''));
    }
  }

  function focusLostQuantity() {
    if (isNaN(parseInt(quantityEntry.getDisplayValue()))) {
      showAlertAndSetFocus('Qty must be numeric', quantityRef);
    } else if (parseInt(quantityEntry.getDisplayValue()) < 1) {
      showAlertAndSetFocus('Qty must be greater than zero.', quantityRef);
    }
  }

  async function handlePickID(
    appService: AppService,
    idValidationResponse: IDValidationResult
  ) {
    const pickIdResult: CheckPickIdResponse = await appService.checkPickId(
      getSelectedDate(),
      idValidationResponse.id
    );
    if (pickIdResult.valid === false) {
      setIdEntry(new DisplayValue(''));
    }
    updateStopAndTruck(pickIdResult.box, pickIdResult.stop);
    setLocationDisabled(false);
    setQuantityDisabled(false);
    locationRef.current?.focus();
  }

  function updateStopAndTruck(truckString: string, stopString: string) {
    setTruckEntry(new DisplayValue(truckString));
    truck = truckString;
    if (stopString === '') {
      setStopNum(new DisplayValue('# #'));
    } else {
      setStopNum(new DisplayValue(stopString));
    }
  }

  function closeDialog() {
    setAlertDialogData({
      showDialog: false,
      textFieldRefToBeFocused: alertDialogData.textFieldRefToBeFocused,
    });
  }

  function showAlertAndSetFocus(
    message: string,
    focus: React.MutableRefObject<HTMLInputElement | undefined> | undefined
  ) {
    setAlertMessage(message);
    setAlertDialogData({
      showDialog: true,
      textFieldRefToBeFocused: focus,
    });
  }

  async function vaildateNonPickID(idValidationResponse: IDValidationResult) {
    const appService: AppService = new AppService();
    const isAnEPCorACK2EPC =
      idValidationResponse.type === IDType.EPC ||
      idValidationResponse.type === IDType.ACK2EPC;
    const isAPOID = idValidationResponse.type === IDType.POID;

    const validateStockResult: ValidateStockResponse =
      await appService.validateStockWithAction(
        '',
        getSelectedDate(),
        truckEntry.getDisplayValue(),
        isAnEPCorACK2EPC ? idValidationResponse.id : '',
        isAPOID ? idValidationResponse.id : '',
        ''
      );
    return validateStockResult;
  }

  function setStateOfButtons(validateStockResult: ValidateStockResponse) {
    const isAnO: boolean = validateStockResult.manifest_item_status === 'O';
    const isAnL: boolean = validateStockResult.manifest_item_status === 'L';
    if (isAnO) {
      setLoadButtonDisabled(false);
      setUnloadButtonDisabled(true);
      setLocationEntry(new DisplayValue(''));
    }
    if (isAnL) {
      setLoadButtonDisabled(true);
      setUnloadButtonDisabled(false);
      setLocationDisabled(false);
    }
  }

  async function loadButtonClicked() {
    let date = getSelectedDate();
    let result: ManifestLoadResponse = await appService.manifestLoad(
      locationEntry.getDisplayValue(),
      date,
      truck,
      localdata.epc,
      localdata.poid,
      localdata.pickid,
      quantityEntry.getDisplayValue()
    );
    if (!result.valid) {
      showAlertAndSetFocus(result.error, alertDialogData.textFieldRefToBeFocused);
    } else {
      printLabel();
      setLocationDisabled(true);
      setQuantityDisabled(true);
      setStopNum(new DisplayValue('# #'));
      setIdEntry(new DisplayValue(''));
      setTruckEntry(new DisplayValue(''));
      setLocationEntry(new DisplayValue(''));
      setQuantityEntry(new DisplayValue('1'));
      setLoadButtonDisabled(true);
      setUnloadButtonDisabled(true);
      idRef.current?.focus();
    }
  }

  function getSelectedDate() {
    let date = new Date();
    if (dateEntry != null) {
      date = dateEntry;
    }
    return date;
  }

  async function unloadButtonClicked() {
    let result = await appService.unloadManifestItem(
      getSelectedDate(),
      truck,
      locationEntry.getDisplayValue(),
      quantityEntry.getDisplayValue(),
      localdata.idType,
      localdata.getCorrespondingId()
    );
    if (!result.valid) {
      showAlertAndSetFocus(result.error, alertDialogData.textFieldRefToBeFocused);
    } else {
      printLabel();
      setLocationDisabled(true);
      setQuantityDisabled(true);
      setStopNum(new DisplayValue('# #'));
      setIdEntry(new DisplayValue(''));
      setLocationEntry(new DisplayValue(''));
      setTruckEntry(new DisplayValue(''));
      setQuantityEntry(new DisplayValue('1'));
      setUnloadButtonDisabled(true);
      setLoadButtonDisabled(true);
      idRef.current?.focus();
    }
  }

  async function printLabel() {
    if (sessionManager.getPrinter() !== '') {
      appService
        .printLabels(
          localdata.idType,
          localdata.getCorrespondingId(),
          sessionManager.getPrinter(),
          quantityEntry.getDisplayValue(),
          locationEntry.getDisplayValue()
        )
        .then((response: ScanResponse) => {
          if (!response.valid) {
            showAlertAndSetFocus(response.error, alertDialogData.textFieldRefToBeFocused);
          }
        });
    }
  }

  function setDisabledBackgroundColor(setDisabled: boolean) {
    if (setDisabled) {
      setDisabledFieldColor('rgb(50, 50, 50, 0.15)');
    } else {
      setDisabledFieldColor('rgb(255, 255, 255)');
    }
  }

  return (
    <div>
      <AlertDialog
        message={alertMessage}
        showDialog={alertDialogData.showDialog}
        closeDialog={() => {
          closeDialog();
        }}
      />
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignContent={'stretch'}
        spacing={1}
        marginRight="1em"
        display={'flex'}
        height={'86vh'}
      >
        <Stack height={'442px'} flexShrink={0}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
            <Typography paddingTop={3} paddingLeft={2} variant="h6">
              Manifest Load / Unload
            </Typography>
            <Button
              sx={{ width: '100px' }}
              variant="contained"
              size="small"
              onClick={() => {
                navigate('/Main');
              }}
            >
              DONE
            </Button>
          </Stack>
          <Typography paddingLeft={2}>Stop {stopNum.getDisplayValue()}</Typography>
          <Grid
            container
            direction={'row'}
            rowSpacing={2}
            alignItems={'flex-start'}
            justifyContent={'flex-end'}
            columnSpacing={1}
            paddingLeft={2}
          >
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              Date
            </Grid>
            <Grid item xs={9} data-testid="mobileDatePicker">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  inputFormat="MM-dd-yyyy"
                  onChange={() => undefined}
                  onAccept={(newValue) => {
                    setDateEntry(newValue);
                  }}
                  value={dateEntry}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: '50%' }} />
                  )}
                ></MobileDatePicker>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              ID
            </Grid>
            <Grid item xs={9}>
              <TextField
                value={idEntry.getDisplayValue()}
                onChange={(eventData) => {
                  setIdEntry(new DisplayValue(eventData.target.value));
                }}
                autoFocus
                inputProps={{ 'aria-label': 'idInput' }}
                multiline
                minRows={1}
                maxRows={1}
                size="small"
                fullWidth
                inputRef={idRef}
                onBlur={idLostFocus}
              />
            </Grid>
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              Truck
            </Grid>
            <Grid item xs={9}>
              <TextField
                onBlur={focusLostTruck}
                inputProps={{ 'aria-label': 'truckInput' }}
                multiline
                minRows={1}
                maxRows={1}
                size="small"
                fullWidth
                inputRef={truckRef}
                value={truckEntry.getDisplayValue()}
                onChange={(eventData) => {
                  setTruckEntry(new DisplayValue(eventData.target.value));
                  truck = eventData.target.value;
                }}
              />
            </Grid>
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              Location
            </Grid>
            <Grid item xs={9}>
              <TextField
                style={{
                  backgroundColor: disabledFieldColor,
                }}
                value={locationEntry.getDisplayValue()}
                inputProps={{ 'aria-label': 'locationInput' }}
                multiline
                minRows={1}
                maxRows={1}
                size="small"
                fullWidth
                inputRef={locationRef}
                disabled={locationDisabled}
                onChange={(eventData) => {
                  setLocationEntry(new DisplayValue(eventData.target.value));
                }}
              />
            </Grid>
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              Quantity
            </Grid>
            <Grid item xs={9}>
              <TextField
                onBlur={focusLostQuantity}
                inputRef={quantityRef}
                inputProps={{ 'aria-label': 'quantityInput' }}
                sx={{ width: '50%' }}
                multiline
                minRows={1}
                maxRows={1}
                size="small"
                fullWidth
                disabled={quantityDisabled}
                value={quantityEntry.getDisplayValue()}
                onChange={(eventData) => {
                  setQuantityEntry(new DisplayValue(eventData.target.value));
                }}
              />
            </Grid>
            <Grid item xs={12} justifyContent={'left'} alignSelf={'center'} marginTop={3}>
              <Button
                disabled={loadButtonDisabled}
                fullWidth
                variant="contained"
                size="large"
                onClick={loadButtonClicked}
              >
                LOAD
              </Button>
            </Grid>
            <Grid item xs={12} justifyContent={'left'} alignSelf={'center'}>
              <Button
                disabled={unloadButtonDisabled}
                fullWidth
                variant="contained"
                size="large"
                onClick={unloadButtonClicked}
              >
                UNLOAD
              </Button>
            </Grid>
            <Grid item xs={12} justifyContent={'left'} alignSelf={'center'} marginTop={2}>
              <Button
                id="cancel-button"
                fullWidth
                variant="contained"
                size="small"
                onClick={() => {
                  navigate('/PrintLabels');
                }}
              >
                CANCEL
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </div>
  );
}
export default ManifestLoadUnload;
