import { GetReturnTypeResponse, Truck } from '../../Services/app.service';
import LocationComponent from '../Returns Components/LocationComponent';
import ManifestDate from '../Returns Components/ManifestDate';
import ReasonComponent from '../Returns Components/ReasonComponent';
import TruckField from '../Returns Components/TruckField';
import Utilities from '../Utils/Utilities';

interface ISTReturnsProps {
  updateTruckEntry: (truck: Truck) => void;
  updateLocationEntry: (location: string) => void;
  updateReasonEntry: (reason: string) => void;
  returnTypeResponse: GetReturnTypeResponse;
}

function ISTReturns(props: ISTReturnsProps) {
  return (
    <>
      <TruckField label={'Truck IST'} truckName={props.returnTypeResponse.truck} />
      <LocationComponent updateLocation={props.updateLocationEntry} />
      <ReasonComponent updateReason={props.updateReasonEntry} />
      <ManifestDate manifestDateValue={props.returnTypeResponse.manifestDate} />
    </>
  );
}
export default ISTReturns;
