import { WarningAmber } from '@mui/icons-material';
import {
  Alert,
  Button,
  Checkbox,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppService, { ScanResponse } from '../Services/app.service';
import { IDType } from '../Services/IDValidationTypes';
import Validation, { ValidationResult } from '../Services/validation.service';
import AcceptDialog from './Dialogs/AcceptDialog';
import AlertDialog from './Dialogs/AlertDialog';
import { SessionManager } from './SessionManager';
import { DateWrapper } from './Utils/DateWrapper';
import { DialogMetaData } from './Utils/DialogMetaData';
import { DisplayValue } from './Utils/DisplayValue';
import Utilities from './Utils/Utilities';

function Transfer() {
  const navigate = useNavigate();
  const validation: Validation = new Validation();

  const [idSkuEntry, setIdSkuEntry] = useState(new DisplayValue(''));
  const [formattedId, setFormattedId] = useState('');
  const [fromLocationEntry, setfromLocationEntry] = useState(new DisplayValue(''));
  const [toLocationEntry, setToLocationEntry] = useState(new DisplayValue(''));
  const [hideQuantity, setHideQuantity] = useState(true);
  const [quantityEntry, setQuantityEntry] = useState(new DisplayValue('1'));
  const [alertDialogData, setAlertDialogData] = useState<DialogMetaData>({
    showDialog: false,
    textFieldRefToBeFocused: undefined,
  });
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [currentTypeID, setCurrentTypeID] = useState<IDType>(IDType.INVALID);
  const [fromValidation, setFromValidation] = useState<ValidationResult>({
    isValid: false,
    message: '',
  });
  const [toValidation, setToValidation] = useState<ValidationResult>({
    isValid: false,
    message: '',
  });
  const [isEnterButtonDisable, setIsEnterButtonDisable] = useState<boolean>(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [showQuantityGreaterThanOneDialogData, setShowQuantityGreaterThanOneDialogData] =
    useState<DialogMetaData>({ showDialog: false, textFieldRefToBeFocused: undefined });

  const quantityRef = useRef<HTMLInputElement>();
  const idSkuRef = useRef<HTMLInputElement>();
  const fromLocationRef = useRef<HTMLInputElement>();
  const fromLocationCheckboxRef = useRef<HTMLInputElement>(null);
  const toLocationRef = useRef<HTMLInputElement>();
  const toLocationCheckboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let allFieldsHaveText: boolean =
      idSkuEntry.getDisplayValue() !== '' &&
      toLocationEntry.getDisplayValue() !== '' &&
      quantityEntry.getDisplayValue() !== '';
    let enableEnterButton: boolean = allFieldsHaveText && toValidation.isValid;
    if (currentTypeID === IDType.SKU || currentTypeID === IDType.PICKID) {
      enableEnterButton =
        enableEnterButton &&
        fromLocationEntry.getDisplayValue() !== '' &&
        fromValidation.isValid;
    }
    setIsEnterButtonDisable(!enableEnterButton);
  }, [
    idSkuEntry,
    fromLocationEntry,
    toLocationEntry,
    quantityEntry,
    currentTypeID,
    fromValidation,
    toValidation,
  ]);

  useEffect(Utilities.checkForScannedData(idSkuEntry, toLocationRef), [idSkuEntry]);
  useEffect(Utilities.checkForScannedData(toLocationEntry, toLocationCheckboxRef), [
    toLocationEntry,
  ]);
  useEffect(Utilities.checkForScannedData(fromLocationEntry, fromLocationCheckboxRef), [
    fromLocationEntry,
  ]);

  useEffect(Utilities.onDialogClosedFocusField(alertDialogData), [alertDialogData]);
  useEffect(Utilities.onDialogClosedFocusField(showQuantityGreaterThanOneDialogData), [
    showQuantityGreaterThanOneDialogData,
  ]);

 
  function focusLostSku() {
    if (idSkuEntry.getDisplayValue() !== '') {
      const validationResult = validation.validateId(idSkuEntry.getDisplayValue());
      setCurrentTypeID(validationResult.type);
      if (validationResult.isValid === false) {
        showAlertDialogAndFocusTextfield(validationResult.message, idSkuRef);
      } else {
        setFormattedId(validationResult.id);
        if (
          validationResult.type === IDType.SKU ||
          validationResult.type === IDType.PICKID
        ) {
          setHideQuantity(false);
        } else {
          setHideQuantity(true);
          setQuantityEntry(new DisplayValue('1'));
        }
      }
    }
  }

  function validateLocation(
    locationEntry: DisplayValue,
    toFocusField: React.MutableRefObject<HTMLInputElement | undefined>,
    validationSetter: React.Dispatch<React.SetStateAction<ValidationResult>>
  ) {
    if (locationEntry.getDisplayValue() !== '') {
      validation
        .validateLocation(locationEntry.getDisplayValue())
        .then((result: ValidationResult) => {
          validationSetter(result);
          if (!result.isValid) {
            setAlertMessage(result.message);
            setAlertDialogData({
              showDialog: true,
              textFieldRefToBeFocused: toFocusField,
            });
          }
        });
    }
  }

  function focusLostQuantity() {
    if (quantityEntry.getDisplayValue() === '') {
      showAlertDialogAndFocusTextfield('Quantity cannot be blank.', quantityRef);
    } else if (isNaN(parseInt(quantityEntry.getDisplayValue()))) {
      showAlertDialogAndFocusTextfield('Qty must be numeric', quantityRef);
    } else if (parseInt(quantityEntry.getDisplayValue()) < 1) {
      showAlertDialogAndFocusTextfield('Qty must be greater than zero.', quantityRef);
    }
  }

  function enterPressed() {
    if (fromLocationEntry.getDisplayValue() === toLocationEntry.getDisplayValue()) {
      showAlertDialogAndFocusTextfield(
        'From and To locations can not be the same.',
        toLocationRef
      );
    } else if (parseInt(quantityEntry.getDisplayValue()) > 1) {
      setShowQuantityGreaterThanOneDialogData({
        showDialog: true,
        textFieldRefToBeFocused: undefined,
      });
    } else {
      applyTransfer();
    }
  }

  function yesQuantityButtonFunction() {
    applyTransfer();
    setShowQuantityGreaterThanOneDialogData({
      showDialog: false,
      textFieldRefToBeFocused:
        showQuantityGreaterThanOneDialogData.textFieldRefToBeFocused,
    });
  }

  function noQuantityButtonFunction() {
    setShowQuantityGreaterThanOneDialogData({
      showDialog: false,
      textFieldRefToBeFocused: quantityRef,
    });
  }

  function isEPCOrAPK(type: IDType) {
    return type === IDType.EPC || type === IDType.ACK2EPC;
  }

  function applyTransfer() {
    const sessionManager: SessionManager = new SessionManager();
    const appService: AppService = new AppService();

    appService
      .transfer(
        sessionManager.getProfitCenterID(),
        DateWrapper.today(),
        fromLocationEntry.getDisplayValue(),
        toLocationEntry.getDisplayValue(),
        sessionManager.getBatchNumber(),
        currentTypeID,
        formattedId,
        quantityEntry.getDisplayValue()
      )
      .then((response: ScanResponse) => {
        if (!response.valid) {
          showAlertDialogAndFocusTextfield(response.error, undefined);
        } else {
          if (sessionManager.getPrinter() !== '') {
            let validationResult = validation.validateId(idSkuEntry.getDisplayValue());

            appService
              .printLabels(
                validationResult.type,
                isEPCOrAPK(validation.validateId(idSkuEntry.getDisplayValue()).type)
                  ? formattedId
                  : '',
                sessionManager.getPrinter(),
                '',
                ''
              )
              .then((response: ScanResponse) => {
                if (!response.valid) {
                  setAlertMessage(response.error);
                  setAlertDialogData({
                    showDialog: true,
                    textFieldRefToBeFocused: alertDialogData.textFieldRefToBeFocused,
                  });
                }
              });
          }
          setShowToast(true);
          setIdSkuEntry(new DisplayValue(''));
          if (!toLocationCheckboxRef.current?.checked) {
            setToLocationEntry(new DisplayValue(''));
          }
          if (!fromLocationCheckboxRef.current?.checked) {
            setfromLocationEntry(new DisplayValue(''));
          }
          setQuantityEntry(new DisplayValue('1'));
          setHideQuantity(true);
        }
      });
  }

  function closeDialog() {
    setAlertDialogData({
      showDialog: false,
      textFieldRefToBeFocused: alertDialogData.textFieldRefToBeFocused,
    });
  }

  return (
    <div>
      <AlertDialog
        message={alertMessage}
        showDialog={alertDialogData.showDialog}
        closeDialog={() => {
          closeDialog();
        }}
      />
      <Snackbar
        open={showToast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => {
          setShowToast(false);
        }}
      >
        <Alert severity="success" sx={{ width: '100%' }} variant="filled">
          Item(s) Transferred
        </Alert>
      </Snackbar>
      <AcceptDialog
        messageLine1="Qty is more than 1."
        messageLine2="Do you want to continue?"
        showDialog={showQuantityGreaterThanOneDialogData.showDialog}
        noButtonFunction={noQuantityButtonFunction}
        yesButtonFunction={yesQuantityButtonFunction}
        icon={<WarningAmber fontSize="large" htmlColor="#ED6C02" />}
      />
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignContent={'stretch'}
        spacing={1}
        marginRight="1em"
        display={'flex'}
        height={'95vh'}
      >
        <Stack height={'442px'} flexShrink={0}>
          <Stack direction="row" justifyContent="space-between" alignItems="baseline">
            <Typography paddingTop={3} paddingLeft={2} marginBottom={3} variant="h6">
              Transfer
            </Typography>
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                navigate('/Main');
              }}
            >
              Done
            </Button>
          </Stack>

          <Typography variant="subtitle1" lineHeight={1}>
            <Grid
              container
              direction={'row'}
              rowSpacing={2}
              alignItems={'flex-start'}
              justifyContent={'flex-end'}
              columnSpacing={1}
              paddingLeft={2}
            >
              <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
                ID / SKU
              </Grid>
              <Grid item xs={9}>
                <TextField
                  autoFocus
                  inputProps={{ 'aria-label': 'id/sku' }}
                  onBlur={focusLostSku}
                  onChange={(eventData) => {
                    setIdSkuEntry(new DisplayValue(eventData.target.value));
                  }}
                  value={idSkuEntry.getDisplayValue()}
                  multiline
                  minRows={1}
                  maxRows={1}
                  size="small"
                  id="id/sku"
                  required
                  fullWidth
                  inputRef={idSkuRef}
                />
              </Grid>
              <Grid item xs={8}>
                Location
              </Grid>
              <Grid
                item
                xs={4}
                textAlign={'right'}
                justifyContent={'flex-end'}
                alignItems={'flex-end'}
              >
                <Typography sx={{ fontSize: 13 }}>Save Location</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction={'row'}
                alignItems={'center'}
                justifyContent={'flex-end'}
                columnSpacing={1}
                paddingRight={3.5}
                sx={{ paddingTop: '10px !important' }}
              >
                <Grid item textAlign={'left'} xs={2}>
                  From
                </Grid>
                <Grid item xs={5.4}>
                  <TextField
                    inputProps={{ 'aria-label': 'fromLocation' }}
                    onChange={(eventData) => {
                      setfromLocationEntry(new DisplayValue(eventData.target.value));
                      setFromValidation({ isValid: false, message: '' });
                    }}
                    onBlur={() => {
                      validateLocation(
                        fromLocationEntry,
                        fromLocationRef,
                        setFromValidation
                      );
                    }}
                    multiline
                    minRows={1}
                    maxRows={1}
                    size="small"
                    id="fromLocation"
                    required
                    inputRef={fromLocationRef}
                    value={fromLocationEntry.getDisplayValue()}
                  />
                </Grid>
                <Grid item xs={1.5} alignSelf={'right'}>
                  <Checkbox
                    inputRef={fromLocationCheckboxRef}
                    inputProps={{ 'aria-label': 'fromLocationCheckbox' }}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 40 } }}
                    id="fromCheckbox"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction={'row'}
                rowSpacing={2}
                alignItems={'center'}
                justifyContent={'flex-end'}
                columnSpacing={1}
                paddingRight={3.5}
              >
                <Grid item justifyItems={'left'} xs={2}>
                  To
                </Grid>
                <Grid item xs={5.4}>
                  <TextField
                    inputProps={{ 'aria-label': 'toLocation' }}
                    onChange={(eventData) => {
                      setToLocationEntry(new DisplayValue(eventData.target.value));
                      setToValidation({ isValid: false, message: '' });
                    }}
                    onBlur={() => {
                      validateLocation(toLocationEntry, toLocationRef, setToValidation);
                    }}
                    multiline
                    minRows={1}
                    maxRows={1}
                    size="small"
                    id="toLocation"
                    required
                    inputRef={toLocationRef}
                    value={toLocationEntry.getDisplayValue()}
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <Checkbox
                    inputRef={toLocationCheckboxRef}
                    inputProps={{ 'aria-label': 'toLocationCheckbox' }}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 42 } }}
                    id="toCheckbox"
                  />
                </Grid>
              </Grid>
              <Grid
                hidden={hideQuantity}
                item
                xs={3}
                justifyContent={'left'}
                alignSelf={'center'}
              >
                Qty
              </Grid>
              <Grid item xs={9} hidden={hideQuantity}>
                <TextField
                  inputProps={{ 'aria-label': 'quantity' }}
                  inputRef={quantityRef}
                  value={quantityEntry.getDisplayValue()}
                  onChange={(eventData) => {
                    setQuantityEntry(new DisplayValue(eventData.target.value));
                  }}
                  onBlur={focusLostQuantity}
                  sx={{ width: '50%' }}
                  multiline
                  minRows={1}
                  maxRows={1}
                  size="small"
                  id="quantity"
                  required
                />
              </Grid>
            </Grid>
          </Typography>
        </Stack>
        <div
          style={{
            alignSelf: 'center',
            marginTop: '1em',
            width: '100%',
            paddingLeft: '1em',
            paddingBottom: '1.75em',
          }}
        >
          <Button
            id="enter-button"
            fullWidth
            variant="contained"
            size="large"
            disabled={isEnterButtonDisable}
            onClick={enterPressed}
          >
            ENTER
          </Button>
        </div>
      </Stack>
    </div>
  );

  function showAlertDialogAndFocusTextfield(
    message: string,
    textFieldToFocus: React.MutableRefObject<HTMLInputElement | undefined> | undefined
  ) {
    setAlertMessage(message);
    setAlertDialogData({ showDialog: true, textFieldRefToBeFocused: textFieldToFocus });
  }
}

export default Transfer;
