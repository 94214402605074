import { Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './ReceiveMenu.css';

function ReceiveMenu() {
  const navigate = useNavigate();
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignContent={'stretch'}
      spacing={1}
    >
      <Typography paddingTop={3} paddingLeft={2} variant="h6" id="receive-menu-label">
        <label>Receive Menu</label>
      </Typography>
      <div id="button-stack-div">
        <Stack direction="column" justifyContent="flex-start" spacing={1}>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              navigate('/ReceiveSerialized');
            }}
          >
            Receive Serialized
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              navigate('/ReceiveSKU');
            }}
          >
            Receive SKU
          </Button>
        </Stack>
        <div id="main-menu-button">
          <Button
            id="main-menu-button"
            fullWidth
            variant="contained"
            size="small"
            onClick={() => {
              navigate('/Main');
            }}
          >
            Main Menu
          </Button>
        </div>
      </div>
    </Stack>
  );
}

export default ReceiveMenu;
