import { formatInTimeZone } from 'date-fns-tz';
import { RefObject } from 'react';
import { DialogMetaDataWithMessage } from '../DialogMetaDataWithMessage';
import { DateWrapper } from './DateWrapper';
import { DialogMetaData } from './DialogMetaData';
import { DisplayValue } from './DisplayValue';

export default class Utilities {
  static isScannedInput(input: string) {
    return input.includes('\n');
  }

  static checkForScannedData(
    valueToCheck: DisplayValue,
    refToFocus: RefObject<HTMLInputElement | undefined>
  ): () => void {
    return () => {
      if (valueToCheck.getOriginalValue().includes('\n')) {
        refToFocus?.current?.focus();
      }
    };
  }

  static checkForScannedDataAndClick(
    valueToCheck: DisplayValue,
    refToClick: HTMLButtonElement | null
  ): () => void {
    return () => {
      if (valueToCheck.getOriginalValue().includes('\n')) {
        refToClick?.click();
      }
    };
  }
  static checkForScannedDataAndFocusButton(
    valueToCheck: DisplayValue,
    refToFocus: HTMLButtonElement | null
  ): () => void {
    return () => {
      if (valueToCheck.getOriginalValue().includes('\n')) {
        refToFocus?.focus();
      }
    };
  }

  static checkForScannedDataAndFocusNone(
    valueToCheck: DisplayValue,
    refToBlur: RefObject<HTMLInputElement | undefined>
  ): () => void {
    return () => {
      if (valueToCheck.getOriginalValue().includes('\n')) {
        refToBlur?.current?.blur();
      }
    };
  }

  static onDialogWithMessageClosedFocusField(
    dialogMetaData: DialogMetaDataWithMessage
  ): () => void {
    return () => {
      if (!dialogMetaData.metaData.showDialog) {
        dialogMetaData.metaData.textFieldRefToBeFocused?.current?.focus();
      }
    };
  }

  static onDialogClosedFocusField(dialogMetaData: DialogMetaData): () => void {
    return () => {
      if (!dialogMetaData.showDialog) {
        dialogMetaData.textFieldRefToBeFocused?.current?.focus();
      }
    };
  }

  static stringToBool(booleanString: string | undefined | null): boolean {
    if (booleanString === null) return false;
    if (booleanString === undefined) return false;
    if (booleanString.toLowerCase() === 't') return true;
    return booleanString.toLowerCase() === 'true';
  }

  static formatDateWithSlashes(manifestDate: string): string {
    let substring_manifestDate: string[] = manifestDate.split('-');
    let month: string = substring_manifestDate[1];
    let day: string = substring_manifestDate[2];
    let year: string = substring_manifestDate[0];

    return month + '/' + day + '/' + year;
  }

  static formatDateMMDDYYYY(date: Date, delimeter: string = '-'): string {
    const f =
      Utilities.zeroPad(date.getMonth() + 1) +
      delimeter +
      Utilities.zeroPad(date.getDate()) +
      delimeter +
      date.getFullYear();
    return f;
  }

  static formatDateYYYYMMDD(date: Date): string {
    const f =
      date.getFullYear() +
      '-' +
      Utilities.zeroPad(date.getMonth() + 1) +
      '-' +
      Utilities.zeroPad(date.getDate());
    return f;
  }

  static formatDateYYYYMMDDHHMMSSTT(date: Date): string {
    let tt = 'AM';
    let hours: number = date.getHours();
    if (hours > 12) {
      hours = hours - 12;
      tt = 'PM';
    }
    const f =
      date.getFullYear() +
      '-' +
      Utilities.zeroPad(date.getMonth() + 1) +
      '-' +
      Utilities.zeroPad(date.getDate()) +
      ' ' +
      Utilities.zeroPad(hours) +
      ':' +
      Utilities.zeroPad(date.getMinutes()) +
      ':' +
      Utilities.zeroPad(date.getSeconds()) +
      ' ' +
      tt;
    return f;
  }

  static buildXML(
    profitCenterId: string,
    batch: string,
    operation: string,
    poid: string,
    ack: string,
    epc: string,
    locationFrom: string,
    locationTo: string,
    reason: string
  ): string {
    let xml = "<request type='std'>";
    xml += "<record table='barcode' action='insert' tableid='barcodeid'>";
    xml += '<keys>';
    xml += "<key name='barcodeid' groupid='1' value='_NEW_' />";
    xml += '</keys>';
    xml += '<data>';
    xml += this.buildCDATA('profitcenterid', profitCenterId);
    xml += this.buildCDATA('batch', batch);
    xml += this.buildCDATA('operation', operation);

    if (poid !== '') {
      xml += this.buildCDATA('polineitemlabel', poid);
    }
    if (ack !== '') {
      xml += this.buildCDATA('ack', ack);
    }
    if (epc !== '') {
      xml += this.buildCDATA('epc', epc);
    }
    if (locationFrom === '') {
      locationFrom = 'TRK0';
    }
    xml += this.buildCDATA('locfrom', locationFrom);
    xml += this.buildCDATA('locto', locationTo);
    if (reason !== '') {
      xml += this.buildCDATA('returnreason', reason);
    }
    xml += this.buildCDATA('scanned', this.convertToEasternTimeZone(DateWrapper.today()));
    xml += '</data>';
    xml += '</record>';
    xml += '</request>';
    return xml;
  }

  static convertToEasternTimeZone(date: Date): string {
    return formatInTimeZone(date, 'America/New_York', 'yyyy-MM-dd HH:mm:ss');
  }

  static convertToEasternTimeZoneWithAMPM(date: Date): string {
    let newDate: string = formatInTimeZone(
      date,
      'America/New_York',
      'yyyy-MM-dd HH:mm:ss'
    );
    let startOfDate = newDate.substring(0, 11);
    let hours: string = newDate.substring(11, 13);
    let endOfDate = newDate.substring(13);
    let intHours = parseInt(hours);
    let AMPM = ' AM';
    if (intHours > 12) {
      intHours = intHours - 12;
      if (intHours < 10) {
        hours = '0' + intHours;
      } else {
        hours = '' + intHours;
      }
      AMPM = ' PM';
    } else if (intHours === 12) {
      AMPM = ' PM';
    }
    return startOfDate + hours + endOfDate + AMPM;
  }

  private static buildCDATA(name: string, value: string): string {
    let xml = `<field name='${name}'><![CDATA[`;
    xml += value;
    xml += ']]></field>';
    return xml;
  }

  private static zeroPad(n: number): string {
    if (n < 10) {
      return '0' + n;
    } else {
      return n.toString();
    }
  }
}
