import {
  Typography,
  Button,
  Stack,
  Grid,
  TextField,
  Snackbar,
  Alert,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppService, {
  ValidateCycleCountLocationResponse,
} from '../../Services/app.service';
import { SessionManager } from '../SessionManager';
import { DialogMetaData } from '../Utils/DialogMetaData';
import { DisplayValue } from '../Utils/DisplayValue';

function CycleCount() {
  let enterButtonRef: HTMLButtonElement | null;
  const navigate = useNavigate();
  const { locationEmptyParam, toastTriggeredParam } = useParams();
  const sessionManager = new SessionManager();
  const appService: AppService = new AppService();
  const locationRef = useRef<HTMLInputElement>();
  const [locationEntry, setLocationEntry] = useState(new DisplayValue(''));
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [showFailureToast, setShowFailureToast] = useState<boolean>(false);

  const [toastMessage, setToastMessage] = useState<string>('');

  useEffect(() => {
    if (toastTriggeredParam === 'Empty') {
      setToastMessage(`Empty Location ${locationEmptyParam}`);
      setShowSuccessToast(true);
    }
  }, []);

  useEffect(() => {
    if (locationEntry.getOriginalValue().search(/\n/) > 0) {
      enterButtonRef?.focus();
    }
  }, [locationEntry]);

  async function validateLocation() {
    if (locationEntry.getDisplayValue() === '') {
      setToastMessage('Location is required.');
      setShowFailureToast(true);
    } else if (locationEntry.getDisplayValue().length > 10) {
      setToastMessage('Location is longer than 10 characters.');
      setShowFailureToast(true);
    } else {
      const locationResponse: ValidateCycleCountLocationResponse =
        await appService.validateCycleCountLocation(locationEntry.getDisplayValue());
      if (locationResponse.valid) {
        navigate(
          `/CycleCountFields/${locationEntry.getDisplayValue()}/${
            locationResponse.stocklocationid
          }`
        );
      } else {
        setToastMessage('Error: Invalid Location.');
        setShowFailureToast(true);
      }
    }
  }


  return (
    <div>
      <Snackbar
        open={showSuccessToast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => {
          setShowSuccessToast(false);
        }}
      >
        <Alert severity="success" sx={{ width: '100%' }} variant="filled">
          {toastMessage}
          <br /> Processed Successfully.
        </Alert>
      </Snackbar>
      <Snackbar
        open={showFailureToast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => {
          setShowFailureToast(false);
        }}
      >
        <Alert severity="error" sx={{ width: '100%' }} variant="filled">
          {toastMessage}
        </Alert>
      </Snackbar>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignContent={'stretch'}
        spacing={1}
        marginRight="1em"
        marginLeft="1em"
        display={'flex'}
        height={'76vh'}
        flexDirection={'column'}
      >
        <Stack height={'400px'} flexShrink={0}>
          <Stack direction="row" justifyContent="space-between" alignItems="baseline">
            <Typography paddingTop={3} marginBottom={0.5} variant="h6">
              <label>Cycle Count</label>
              <Typography sx={{ fontSize: 16 }}>
                <label>Batch: {sessionManager.getBatchNumber()} </label>
              </Typography>
            </Typography>

            <Button
              sx={{ width: '100px' }}
              variant="contained"
              size="small"
              onClick={() => {
                navigate('/Main');
              }}
            >
              DONE
            </Button>
          </Stack>
          <Grid
            container
            direction={'row'}
            rowSpacing={2}
            alignItems={'flex-start'}
            justifyContent={'flex-end'}
            columnSpacing={1}
          >
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              Location
            </Grid>
            <Grid item xs={9}>
              <TextField
                autoFocus
                inputRef={locationRef}
                inputProps={{ 'aria-label': 'locationInput' }}
                data-testid="locationInput"
                multiline
                minRows={1}
                maxRows={1}
                size="small"
                fullWidth
                value={locationEntry.getDisplayValue()}
                onChange={(eventData) => {
                  setLocationEntry(new DisplayValue(eventData.target.value));
                }}
              />
            </Grid>
          </Grid>
        </Stack>
        <Stack
          direction="column"
          justifyContent="space-between"
          spacing={2}
          marginRight="1em"
          marginLeft="1em"
        >
          <Button
            fullWidth
            variant="contained"
            size="medium"
            onClick={validateLocation}
            ref={(node) => {
              enterButtonRef = node;
            }}
          >
            Enter
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={{ alignSelf: '', marginTop: 'auto' }}
            size="small"
            onClick={() => {
              navigate('/PrintLabels');
            }}
          >
            Back
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}

export default CycleCount;
