import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';

interface AlertDialogProps {
  message: string;
  showDialog: boolean;
  closeDialog: Function;
}

function AlertDialog(props: AlertDialogProps) {
  return (
    <Dialog open={props.showDialog} fullWidth>
      <Stack direction="column" justifyContent="center" alignItems="left" spacing={1}>
        <div>
          <DialogTitle sx={{ paddingBottom: 1, paddingLeft: 2 }}>
            {'RMS Scan Gun'}
          </DialogTitle>
          <IconButton
            data-testid="closeButton"
            onClick={() => {
              props.closeDialog();
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Divider sx={{ backgroundColor: '#797979' }} />
        </div>
        <Grid
          wrap="nowrap"
          container
          sx={{ width: '100%' }}
          alignItems="center"
          justifyContent={'left'}
          columnSpacing={2}
          paddingTop={1}
        >
          <Grid item sx={{ lineHeight: '0px' }}>
            <ErrorOutlineIcon htmlColor="#D32F2F" />
          </Grid>
          <Grid
            item
            sx={{
              color: 'black',
              paddingLeft: '8px !important',
              paddingRight: '12px !important',
              wordBreak: 'break-word',
            }}
          >
            {props.message}
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent={'center'} paddingTop={5}>
          <Grid item xs={4}>
            <DialogActions>
              <Button
                data-testid="okButton"
                variant="contained"
                size="small"
                fullWidth
                onClick={() => {
                  props.closeDialog();
                }}
              >
                OK
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Stack>
    </Dialog>
  );
}

export default AlertDialog;
