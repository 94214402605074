import { Grid, Stack, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import AppService, {
  GetReturnTypeResponse,
  ReturnType,
  Truck,
  UpdateReturnsResponse,
} from '../Services/app.service';
import { IDType } from '../Services/IDValidationTypes';
import Validation, { IDValidationResult } from '../Services/validation.service';
import AlertDialog from './Dialogs/AlertDialog';
import TitleAndDoneButton from './Returns Components/TitleAndDoneButton';
import UpdateAndCancel from './Returns Components/UpdateAndCancel';
import ISTReturns from './Returns Pages/ISTReturns';
import ScheduledReturns from './Returns Pages/ScheduledReturns';
import UnscheduledReturns from './Returns Pages/UnscheduledReturns';
import { DisplayValue } from './Utils/DisplayValue';
import Utilities from './Utils/Utilities';
import './Returns.css';
import { SessionManager } from './SessionManager';
import { DialogMetaDataWithMessage } from './DialogMetaDataWithMessage';

function Returns() {
  const validation = new Validation();
  const appService = new AppService();
  const sessionManager = new SessionManager();

  const [updateButtonDisabled, setUpdateButtonDisabled] = useState<boolean>(true);

  const emptyReturnTypeResponse: GetReturnTypeResponse = {
    valid: false,
    error: '',
    stockId: '',
    returnType: ReturnType.NULL,
    realReturnType: '',
    manifestDate: '',
    delBoxScheduled: '',
    delBoxId: '',
    delBoxDate: '',
    poid: '',
    vsn: '',
    epc: '',
    truck: '',
  };
  const [idEntry, setIdEntry] = useState(new DisplayValue(''));
  const [locationEntry, setLocationEntry] = useState(new DisplayValue(''));
  const [truckEntry, setTruckEntry] = useState<Truck>();
  const [reasonEntry, setReasonEntry] = useState('');
  const [priorityFillEntry, setPriorityFillEntry] = useState('');
  const [returnTypeResponse, setReturnTypeResponse] = useState<GetReturnTypeResponse>(
    emptyReturnTypeResponse
  );
  const [idValidationResult, setIDValidationResult] = useState<IDValidationResult>();

  const [alertDialogData, setAlertDialogData] = useState<DialogMetaDataWithMessage>({
    message: '',
    metaData: {
      showDialog: false,
      textFieldRefToBeFocused: undefined,
    },
  });
  useEffect(Utilities.onDialogWithMessageClosedFocusField(alertDialogData), [
    alertDialogData,
  ]);
  const idRef = useRef<HTMLInputElement>();

  useEffect(Utilities.checkForScannedDataAndFocusNone(idEntry, idRef), [idEntry]);

  function ifIsHomeReturnThenCheckPriorityFill(): boolean {
    const isHomeReturn = returnTypeResponse.realReturnType === 'HomeReturn';
    if (isHomeReturn) {
      return priorityFillEntry !== '';
    }
    return true;
  }
  useEffect(() => {
    let allFieldsHaveText: boolean = false;

    if (returnTypeResponse?.returnType === ReturnType.ScheduledReturn) {
      allFieldsHaveText =
        idEntry.getDisplayValue() !== '' &&
        locationEntry.getDisplayValue() !== '' &&
        reasonEntry !== '' &&
        priorityFillEntry !== '';
    } else if (returnTypeResponse?.returnType === ReturnType.UnscheduledReturn) {
      if (truckEntry !== undefined) {
        allFieldsHaveText =
          idEntry.getDisplayValue() !== '' &&
          truckEntry.name !== '' &&
          locationEntry.getDisplayValue() !== '' &&
          ifIsHomeReturnThenCheckPriorityFill() &&
          reasonEntry !== '';
      }
    } else if (returnTypeResponse?.returnType === ReturnType.InStoreTransfer) {
      if (truckEntry !== undefined) {
        allFieldsHaveText =
          idEntry.getDisplayValue() !== '' &&
          truckEntry.name !== '' &&
          locationEntry.getDisplayValue() !== '' &&
          reasonEntry !== '';
      }
    }
    setUpdateButtonDisabled(!allFieldsHaveText);
  }, [idEntry, locationEntry, reasonEntry, priorityFillEntry]);

  async function focusLostId() {
    const idValue = idEntry.getDisplayValue();
    if (idValue.length > 0) {
      const result: IDValidationResult = validation.validateId(idValue);
      setIDValidationResult(result);
      if (result.isValid) {
        if (result.type === IDType.EPC || result.type === IDType.ACK2EPC) {
          await handleGoodIDResult(result);
        } else {
          setAlertDialogData({
            message: 'Enter an EPC.',
            metaData: { showDialog: true, textFieldRefToBeFocused: idRef },
          });
        }
      } else {
        setAlertDialogData({
          message: result.message,
          metaData: { showDialog: true, textFieldRefToBeFocused: idRef },
        });
      }
    }
  }

  async function handleGoodIDResult(result: IDValidationResult) {
    const response: GetReturnTypeResponse = await appService
      .getReturnType(result.id)
      .then((response) => {
        return response;
      });

    if (!response.valid) {
      setAlertDialogData({
        message: response.error,
        metaData: { showDialog: true, textFieldRefToBeFocused: idRef },
      });
    } else {
      setReturnTypeResponse(response);
      setTruckEntry({ name: response.truck, manifestDate: response.manifestDate });
    }
  }

  async function onUpdateButtonClick() {
    handleReturn().then((repsonse: UpdateReturnsResponse) => {
      if (repsonse.valid) {
        if (sessionManager.getPrinter() !== '') {
          appService.printLabels(
            IDType.EPC,
            idValidationResult ? idValidationResult.id : '',
            sessionManager.getPrinter(),
            '1',
            locationEntry.getDisplayValue()
          );
        }

        if (returnTypeResponse.returnType === ReturnType.UnscheduledReturn) {
          sendBarcode();
        }

        setReturnTypeResponse(emptyReturnTypeResponse);
        setIdEntry(new DisplayValue(''));
        setLocationEntry(new DisplayValue(''));
        setPriorityFillEntry('');
        setReasonEntry('');
        setTruckEntry(undefined);
        idRef.current?.focus();
      } else {
        setAlertDialogData({
          message: repsonse.error,
          metaData: { showDialog: true, textFieldRefToBeFocused: idRef },
        });
      }
    });
  }

  async function handleReturn(): Promise<UpdateReturnsResponse> {
    let truckName: string = truckEntry ? truckEntry.name : '';
    let truckDate = truckEntry ? truckEntry.manifestDate : '';
    let validatedID = idValidationResult ? idValidationResult.id : '';
    if (returnTypeResponse.returnType === ReturnType.ScheduledReturn) {
      return appService.updateScheduledReturn(
        returnTypeResponse.manifestDate,
        '1',
        locationEntry.getDisplayValue(),
        priorityFillEntry,
        reasonEntry,
        truckName,
        validatedID,
        returnTypeResponse.poid,
        ''
      );
    }
    if (returnTypeResponse.returnType === ReturnType.UnscheduledReturn) {
      let priority = priorityFillEntry;
      if (returnTypeResponse.realReturnType !== 'HomeReturn') {
        priority = '';
      }
      return appService.updateUnscheduledReturn(
        truckDate,
        locationEntry.getDisplayValue(),
        truckName,
        validatedID,
        priority,
        returnTypeResponse.realReturnType
      );
    }
    if (returnTypeResponse.returnType === ReturnType.InStoreTransfer) {
      return appService.updateISTReturn(
        returnTypeResponse.manifestDate,
        validatedID,
        returnTypeResponse.poid,
        '',
        '1',
        truckName,
        'TRK',
        locationEntry.getDisplayValue(),
        truckName,
        reasonEntry
      );
    }

    return Promise.resolve({ valid: false, error: '' });
  }

  function updateLocationEntry(newLocation: string) {
    setLocationEntry(new DisplayValue(newLocation));
  }

  function updateReason(newReason: string) {
    setReasonEntry(newReason);
  }

  function updatePriorityFill(newPriority: string) {
    setPriorityFillEntry(newPriority);
  }

  function updateTruckEntry(newTruck: Truck) {
    setTruckEntry(newTruck);
  }

  function sendBarcode() {
    const profitCenterId = sessionManager.getProfitCenterID();
    const xml = Utilities.buildXML(
      profitCenterId,
      truckEntry?.name ? truckEntry?.name : '',
      'MAN',
      returnTypeResponse.poid,
      '',
      returnTypeResponse.epc,
      'TRK',
      locationEntry.getDisplayValue(),
      reasonEntry
    );

    appService.sendBarcode(xml).then((result) => {
      if (!result.valid) {
        setAlertDialogData({
          message: result.error,
          metaData: { showDialog: true, textFieldRefToBeFocused: idRef },
        });
      }
    });
  }
  return (
    <>
      <AlertDialog
        message={alertDialogData?.message}
        showDialog={alertDialogData?.metaData.showDialog}
        closeDialog={() => {
          setAlertDialogData({
            message: '',
            metaData: {
              showDialog: false,
              textFieldRefToBeFocused: alertDialogData.metaData.textFieldRefToBeFocused,
            },
          });
        }}
      />
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignContent={'stretch'}
        spacing={0.1}
        marginRight="1em"
        display={'flex'}
        height={'88vh'}
        paddingLeft={1}
      >
        <TitleAndDoneButton />
        <Grid
          container
          direction={'row'}
          rowSpacing={2}
          alignItems={'flex-start'}
          justifyContent={'flex-end'}
          columnSpacing={1}
          paddingLeft={1}
          paddingRight={1}
        >
          <Grid item xs={3} justifyContent={'left'} alignSelf={'center'} paddingRight={1}>
            ID
          </Grid>
          <Grid item xs={9}>
            <TextField
              autoFocus
              inputProps={{ 'aria-label': 'idInput' }}
              multiline
              minRows={1}
              maxRows={1}
              size="small"
              inputRef={idRef}
              fullWidth
              value={idEntry.getDisplayValue()}
              onChange={(eventData) => {
                setIdEntry(new DisplayValue(eventData.target.value));
              }}
              onBlur={() => {
                focusLostId();
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction={'row'}
          rowSpacing={2}
          alignItems={'flex-start'}
          justifyContent={'flex-end'}
          columnSpacing={1}
          paddingLeft={1}
          paddingRight={1}
        >
          {returnTypeResponse?.returnType === ReturnType.ScheduledReturn && (
            <>
              <ScheduledReturns
                returnTypeResponse={returnTypeResponse}
                updateLocation={updateLocationEntry}
                updateReason={updateReason}
                updatePriorityFill={updatePriorityFill}
              />
            </>
          )}
          {returnTypeResponse?.returnType === ReturnType.UnscheduledReturn && (
            <>
              <UnscheduledReturns
                returnTypeResponse={returnTypeResponse}
                updateTruckEntry={updateTruckEntry}
                updateLocationEntry={updateLocationEntry}
                updateReasonEntry={updateReason}
                updatePriorityFillEntry={updatePriorityFill}
              />
            </>
          )}
          {returnTypeResponse?.returnType === ReturnType.InStoreTransfer && (
            <>
              <ISTReturns
                returnTypeResponse={returnTypeResponse}
                updateTruckEntry={updateTruckEntry}
                updateLocationEntry={updateLocationEntry}
                updateReasonEntry={updateReason}
              />
            </>
          )}
        </Grid>
        <div id="update-cancel-div">
          <UpdateAndCancel
            updateButtonClick={onUpdateButtonClick}
            updateButtonDisabled={updateButtonDisabled}
          />
        </div>
      </Stack>
    </>
  );
}

export default Returns;
