import { Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export interface UpdateProps {
  updateButtonClick: Function;
  updateButtonDisabled: boolean;
}

function UpdateAndCancel(props: UpdateProps) {
  const navigate = useNavigate();

  return (
    <>
      <Grid item xs={12} justifyContent={'left'} alignSelf={'center'} paddingLeft={2}>
        <Button
          disabled={props.updateButtonDisabled}
          id="update-button"
          fullWidth
          variant="contained"
          size="large"
          onClick={() => {
            props.updateButtonClick();
          }}
        >
          Update
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        justifyContent={'left'}
        alignSelf={'center'}
        marginTop={3}
        paddingLeft={2}
      >
        <Button
          id="cancel-button"
          fullWidth
          variant="contained"
          size="small"
          onClick={() => {
            navigate('/PrintLabels');
          }}
        >
          Cancel
        </Button>
      </Grid>
    </>
  );
}
export default UpdateAndCancel;
