import { Grid, TextField } from '@mui/material';

interface Properties {
  label: string;
  truckName: string;
}

function TruckField({ label, truckName }: Properties) {
  return (
    <>
      <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
        {label}
      </Grid>
      <Grid item xs={9}>
        <TextField
          disabled={true}
          style={{ backgroundColor: 'rgb(50, 50, 50, 0.15)' }}
          inputProps={{ 'aria-label': 'truckInput' }}
          size="small"
          fullWidth
          value={truckName}
        ></TextField>
      </Grid>
    </>
  );
}

export default TruckField;
