import { Alert, Button, Grid, Snackbar, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import AppService, { Ticket, UpdateItemPickedUpResponse } from '../Services/app.service';
import { IDType } from '../Services/IDValidationTypes';
import Validation, {
  IDValidationResult,
  ValidationResult,
} from '../Services/validation.service';
import AlertDialog from './Dialogs/AlertDialog';
import { DialogMetaDataWithMessage } from './DialogMetaDataWithMessage';
import { SessionManager } from './SessionManager';
import { DialogMetaData } from './Utils/DialogMetaData';
import { DisplayValue } from './Utils/DisplayValue';
import Utilities from './Utils/Utilities';

export interface RdcCpuScannerProps {
  ticket: Ticket;
  deliveryStatementButtonClicked: Function;
}

function RdcCpuScanner(props: RdcCpuScannerProps) {
  let sessionManager = new SessionManager();
  let validation = new Validation();
  let appService = new AppService();
  const [skuId, setSkuId] = useState(new DisplayValue(''));
  const [locationField, setLocationField] = useState(new DisplayValue(''));
  const [status, setStatus] = useState('Ready to scan.');
  const skuIdRef = useRef<HTMLInputElement>();
  const locationRef = useRef<HTMLInputElement>();
  const [locationDisabled, setLocationDisabled] = useState<boolean>(true);
  const [processedItemsLabel, setProcessedItemsLabel] = useState(
    `Processed ${props.ticket.qtyPickedUp} of ${props.ticket.totalQty} items.`
  );
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [alertDialogData, setAlertDialogData] = useState<DialogMetaDataWithMessage>({
    message: '',
    metaData: {
      showDialog: false,
      textFieldRefToBeFocused: undefined,
    },
  });

  useEffect(Utilities.checkForScannedDataAndFocusNone(skuId, skuIdRef), [skuId]);
  useEffect(Utilities.checkForScannedDataAndFocusNone(locationField, locationRef), [
    locationField,
  ]);
  useEffect(() => {
    if (!locationDisabled) {
      locationRef.current?.focus();
    }
  }, [locationDisabled]);

  useEffect(Utilities.onDialogWithMessageClosedFocusField(alertDialogData), [
    alertDialogData,
  ]);

  useEffect(() => {
    if (props.ticket) {
      skuIdRef.current?.focus();
    }
  }, [props.ticket]);

  async function updateItemData(
    soldId: string,
    validationResult: IDValidationResult,
    location: string
  ): Promise<UpdateItemPickedUpResponse> {
    let itemPickupParameters = {
      soldid: soldId,
      epc: '',
      poid: '',
      sku: '',
      pickid: '',
      location: location,
    };
    if (validationResult.type === IDType.EPC) {
      itemPickupParameters.epc = validationResult.id;
    } else if (validationResult.type === IDType.POID) {
      itemPickupParameters.poid = validationResult.id;
    } else if (validationResult.type === IDType.SKU) {
      itemPickupParameters.sku = validationResult.id;
    } else if (validationResult.type === IDType.PICKID) {
      itemPickupParameters.pickid = validationResult.id;
    }
    let response = await appService.updateItemPickedUp(
      itemPickupParameters.soldid,
      itemPickupParameters.epc,
      itemPickupParameters.poid,
      itemPickupParameters.sku,
      itemPickupParameters.pickid,
      location
    );
    if (response.valid) {
      setProcessedItemsLabel(
        `Processed ${response.qtyPickedUp} of ${response.totalQtyScheduledForPickup} items.`
      );
    }
    return response;
  }

  async function focusLostSkuId() {
    if (skuId.getDisplayValue() === '') {
      setLocationDisabled(true);
      return;
    }
    let validationResult: IDValidationResult = validation.validateId(
      skuId.getDisplayValue()
    );
    if (!validationResult.isValid) {
      setAlertDialogData({
        message: validationResult.message,
        metaData: { showDialog: true, textFieldRefToBeFocused: skuIdRef },
      });
      setSkuId(new DisplayValue(''));
    } else if (
      validationResult.type === IDType.SKU ||
      validationResult.type === IDType.PICKID
    ) {
      setLocationDisabled(false);
    } else {
      sendItemToBackend(validationResult);
    }
  }

  async function sendItemToBackend(
    validationResult: IDValidationResult,
    location: string = ''
  ) {
    let response: UpdateItemPickedUpResponse = await updateItemData(
      props.ticket.soldid,
      validationResult,
      location
    );
    if (response.valid) {
      skuIdRef.current?.focus();

      setToastMessage(`Processed Item ${skuId.getDisplayValue()}`);
      setShowSuccessToast(true);
    } else {
      setAlertDialogData({
        message: response.error,
        metaData: { showDialog: true, textFieldRefToBeFocused: skuIdRef },
      });
    }
    setSkuId(new DisplayValue(''));
    setLocationField(new DisplayValue(''));
    setLocationDisabled(true);
  }

  async function focusLostLocation() {
    if (locationField.getDisplayValue() === '') {
      return;
    }
    let locationValidationResult: ValidationResult = await validation.validateLocation(
      locationField.getDisplayValue()
    );
    if (!locationValidationResult.isValid) {
      setAlertDialogData({
        message: locationValidationResult.message,
        metaData: { showDialog: true, textFieldRefToBeFocused: locationRef },
      });
      setLocationField(new DisplayValue(''));
    } else {
      let idSKUValidationResult: IDValidationResult = validation.validateId(
        skuId.getDisplayValue()
      );
      sendItemToBackend(idSKUValidationResult, locationField.getDisplayValue());
    }
  }

  async function onDraftReceiptClick() {
    setStatus('Please wait...');
    appService.rdcCpuDraftReport(props.ticket.soldid).then((response) => {
      if (response.valid) {
        skuIdRef.current?.focus();
        setToastMessage('Draft statement printed.');
        setShowSuccessToast(true);
      } else {
        setAlertDialogData({
          message: response.error,
          metaData: { showDialog: true, textFieldRefToBeFocused: undefined },
        });
      }
      setStatus('Ready to scan.');
    });
  }

  function closeDialog() {
    setAlertDialogData({
      message: '',
      metaData: {
        showDialog: false,
        textFieldRefToBeFocused: alertDialogData.metaData.textFieldRefToBeFocused,
      },
    });
  }

  function onDeliveryStatementClick() {
    setStatus('Please wait...');

    appService.rdcCpuDeliveryStatement(props.ticket.soldid).then((response) => {
      if (!response.valid) {
        setAlertDialogData({
          message: response.error,
          metaData: { showDialog: true, textFieldRefToBeFocused: undefined },
        });
        setStatus('Ready to scan.');
      }
      props.deliveryStatementButtonClicked();
    });
  }

  return (
    <>
      <AlertDialog
        message={alertDialogData.message}
        showDialog={alertDialogData.metaData.showDialog}
        closeDialog={closeDialog}
      />
      <Snackbar
        open={showSuccessToast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => {
          setShowSuccessToast(false);
        }}
      >
        <Alert severity="success" sx={{ width: '100%' }} variant="filled">
          {toastMessage}
        </Alert>
      </Snackbar>
      <Grid item xs={3} justifyContent={'left'} alignSelf={'center'} paddingRight={1}>
        SKU / ID
      </Grid>
      <Grid item xs={9}>
        <TextField
          inputProps={{ 'aria-label': 'idInput' }}
          multiline
          minRows={1}
          maxRows={1}
          size="small"
          fullWidth
          value={skuId.getDisplayValue()}
          onChange={(eventData) => {
            setSkuId(new DisplayValue(eventData.target.value));
          }}
          onBlur={focusLostSkuId}
          inputRef={skuIdRef}
        />
      </Grid>
      {!locationDisabled && (
        <Grid item xs={3} justifyContent={'left'} alignSelf={'center'} paddingRight={1}>
          <label htmlFor="locationField">Location</label>
        </Grid>
      )}
      {!locationDisabled && (
        <Grid item xs={9}>
          <TextField
            inputProps={{ 'aria-label': 'locationInput' }}
            multiline
            minRows={1}
            maxRows={1}
            size="small"
            fullWidth
            inputRef={locationRef}
            disabled={locationDisabled}
            id="locationField"
            value={locationField.getDisplayValue()}
            onChange={(eventData) => {
              setLocationField(new DisplayValue(eventData.target.value));
            }}
            onBlur={focusLostLocation}
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
        justifyContent={'left'}
        alignSelf={'center'}
        paddingRight={1}
        marginTop={5}
      >
        <p>{`${processedItemsLabel}`}</p>
      </Grid>
      <Grid item xs={12} justifyContent={'left'} alignSelf={'center'} paddingRight={1}>
        Status: {status}
      </Grid>
      <Grid
        item
        xs={12}
        justifyContent={'left'}
        alignSelf={'center'}
        marginTop={locationDisabled ? 6.25 : 1}
      >
        <Button
          fullWidth
          variant="contained"
          size="large"
          aria-label="draftReceiptButton"
          onClick={onDraftReceiptClick}
        >
          Draft Receipt
        </Button>
      </Grid>
      <Grid item xs={12} justifyContent={'left'} alignSelf={'center'} marginTop={1}>
        <Button
          fullWidth
          variant="contained"
          size="large"
          aria-label="deliveryStatementButton"
          onClick={onDeliveryStatementClick}
        >
          Delivery Statement
        </Button>
      </Grid>
    </>
  );
}

export default RdcCpuScanner;
