import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  List,
  ListItemButton,
  Stack,
} from '@mui/material';
import { ProfitCenterIdsResponse } from '../../Services/app.service';
import { SessionManager } from '../SessionManager';
import { useState } from 'react';
import './ProfitCenterSelectionModal.css';

interface ProfitCenterSelectionModalProps {
  listOfPcs: ProfitCenterIdsResponse[];
  sessionID: string;
  showDialog: boolean;
  closeDialog: Function;
  closeDialogOnError: Function;
}

function ProfitCenterSelectionModal(props: ProfitCenterSelectionModalProps) {
  const sessionManager = new SessionManager();
  const [selectedPCId, setSelectedPCId] = useState('');
  const [loginError, setLoginError] = useState(false);

  const closeModalForError = () => {
    setTimeout(() => {
      props.closeDialogOnError();
      setLoginError(false);
    }, 5000);
    setLoginError(true);
    setSelectedPCId('');
  };

  return (
    <Dialog open={props.showDialog} fullWidth>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="left"
        spacing={1}
        id="modalStack"
      >
        <div id="title-and-list-container">
          <div id="dialog-title" data-testid="profitCenterSelectorModal">
            <DialogTitle
              style={{ padding: '5px', fontWeight: '400', textAlign: 'center' }}
            >
              {'Please confirm which store:'}
            </DialogTitle>
          </div>

          <Grid
            wrap="nowrap"
            container
            item
            justifyContent={'center'}
            paddingTop={1}
            overflow="auto"
            maxHeight="350px"
          >
            {loginError ? (
              <div id="errorText">Error Logging In. Please Refresh and Try Again.</div>
            ) : (
              <div id="list-container">
                <List id="store-list">
                  {props.listOfPcs.map((item) => {
                    return (
                      <ListItemButton
                        id="natural"
                        key={item.profitCenterId + item.dealerId + item.dealerName}
                        selected={item.profitCenterId == selectedPCId}
                        sx={{
                          color: 'black',
                          wordBreak: 'break-word',
                        }}
                        onClick={() => {
                          setSelectedPCId(item.profitCenterId);
                        }}
                      >
                        {item.naturalStoreId} - {item.dealerName}
                      </ListItemButton>
                    );
                  })}
                </List>
              </div>
            )}
          </Grid>
        </div>
        <Grid id="confirm-button-container" container>
          <DialogActions style={{ padding: 0 }}>
            <Button
              id="confirm-button"
              disabled={!selectedPCId}
              data-testid="confirmButton"
              variant="contained"
              fullWidth={true}
              style={{ width: '75vw' }}
              size="small"
              onClick={async () => {
                let wasSessionSet =
                  await sessionManager.setSessionAndSelectedProfitCenterIDForHeritageSession(
                    props.sessionID,
                    selectedPCId
                  );
                if (wasSessionSet) {
                  props.closeDialog();
                } else {
                  closeModalForError();
                }
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Grid>
      </Stack>
    </Dialog>
  );
}

export default ProfitCenterSelectionModal;
