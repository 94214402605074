import { Navigate, Outlet } from 'react-router-dom';
import { SessionManager } from './SessionManager';

const sessionManager = new SessionManager();

function PrivateRoute() {
  const auth = sessionManager.getSessionID();

  return auth ? <Outlet /> : <Navigate to="/" />;
}

export default PrivateRoute;
