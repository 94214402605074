import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Alert,
  Button,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Validation, { IDValidationResult } from '../Services/validation.service';
import AlertDialog from './Dialogs/AlertDialog';
import { DialogMetaDataWithMessage } from './DialogMetaDataWithMessage';
import { DateWrapper } from './Utils/DateWrapper';
import { DisplayValue } from './Utils/DisplayValue';
import Utilities from './Utils/Utilities';
import AppService from '../Services/app.service';
import { IDType } from '../Services/IDValidationTypes';

let loadButtonRef: HTMLButtonElement | null = null;
enum Action {
  LOAD = 'L',
  UNLOAD = 'NL',
  RECEIVE = 'R',
  CANCEL_RECEIVE = 'NR',
}

function DealerProcessManifest() {
  const navigate = useNavigate();
  const validation = new Validation();
  const idRef = useRef<HTMLInputElement>();
  const qtyRef = useRef<HTMLInputElement>();
  const locationRef = useRef<HTMLInputElement>();
  const truckRef = useRef<HTMLInputElement>();

  const [datePicker, setDatePicker] = useState<Date>(DateWrapper.today());
  const [areButtonsDisabled, setAreButtonsDisabled] = useState<boolean>(true);
  const [idEntry, setIdEntry] = useState(new DisplayValue(''));
  const [truckEntry, setTruckEntry] = useState(new DisplayValue(''));
  const [locationEntry, setLocationEntry] = useState(new DisplayValue(''));
  const [quantityEntry, setQuantityEntry] = useState(new DisplayValue(''));
  const [idValidationResult, setIdValidationResult] = useState<IDValidationResult>();
  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [lastFocusedField, setLastFocusedField] =
    useState<React.MutableRefObject<HTMLInputElement | undefined>>();

  const [alertDialogData, setAlertDialogData] = useState<DialogMetaDataWithMessage>({
    message: '',
    metaData: {
      showDialog: false,
      textFieldRefToBeFocused: undefined,
    },
  });

  useEffect(Utilities.checkForScannedData(idEntry, locationRef), [idEntry]);
  useEffect(Utilities.onDialogWithMessageClosedFocusField(alertDialogData), [
    alertDialogData,
  ]);
  useEffect(Utilities.checkForScannedData(truckEntry, idRef), [truckEntry]);
  useEffect(Utilities.checkForScannedDataAndFocusButton(locationEntry, loadButtonRef), [
    locationEntry,
  ]);
  useEffect(Utilities.checkForScannedDataAndFocusButton(quantityEntry, loadButtonRef), [
    quantityEntry,
  ]);

  useEffect(() => {
    var pickupFieldsHaveAValue: boolean =
      Boolean(idEntry.getDisplayValue()) &&
      Boolean(truckEntry.getDisplayValue()) &&
      Boolean(quantityEntry.getDisplayValue());
    setAreButtonsDisabled(!pickupFieldsHaveAValue);
  }, [idEntry, truckEntry, quantityEntry]);

  async function focusLostId() {
    const idValue = idEntry.getDisplayValue();
    if (idValue.length > 0) {
      const result: IDValidationResult = validation.validateId(idValue);
      setIdValidationResult(result);
      const isASku = result.type === IDType.SKU;
      const isInvaild = !result.isValid;
      if (isASku || isInvaild) {
        setAlertDialogData({
          message: isASku ? 'Invalid ID: SKU' : result.message,
          metaData: {
            showDialog: true,
            textFieldRefToBeFocused: idRef,
          },
        });
        setIdEntry(new DisplayValue(''));
      }
    }
  }

  function focusLostQty() {
    const qtyValue = quantityEntry.getDisplayValue();
    const quantityNumber: number = parseInt(qtyValue);
    const dialogMetaData = {
      showDialog: true,
      textFieldRefToBeFocused: qtyRef,
    };
    if (quantityEntry.getDisplayValue() !== '') {
      if (isNaN(quantityNumber)) {
        setAlertDialogData({
          message: 'Qty must be numeric',
          metaData: dialogMetaData,
        });
        setQuantityEntry(new DisplayValue(''));
      } else if (quantityNumber <= 0) {
        setAlertDialogData({
          message: 'Qty must be greater than zero.',
          metaData: dialogMetaData,
        });
        setQuantityEntry(new DisplayValue(''));
      }
    }
  }

  async function actionButtonClicked(action: Action) {
    var appService = new AppService();
    var dateString: string = Utilities.formatDateMMDDYYYY(datePicker);
    const dialogMetaData = {
      showDialog: true,
      textFieldRefToBeFocused: locationRef,
    };
    if (
      (action === Action.UNLOAD || action === Action.RECEIVE) &&
      locationEntry.getDisplayValue() === ''
    ) {
      setAlertDialogData({
        message: 'Please enter a location code',
        metaData: dialogMetaData,
      });
    } else {
      const currentType = idValidationResult?.type ?? IDType.INVALID;
      const quantity =
        currentType === IDType.PICKID ? quantityEntry.getDisplayValue() : '1';
      setQuantityEntry(new DisplayValue(quantity));
      let result = await appService.DlrProcessManifestAction(
        dateString,
        truckEntry.getDisplayValue(),
        idValidationResult?.id ?? '',
        currentType,
        quantity,
        locationEntry.getDisplayValue(),
        action
      );
      if (!result.valid) {
        setAlertDialogData({
          message: result.error,
          metaData: {
            showDialog: true,
            textFieldRefToBeFocused: lastFocusedField,
          },
        });
      } else {
        switch (action) {
          case Action.LOAD: {
            setToastMessage('Item Loaded');
            break;
          }
          case Action.UNLOAD: {
            setToastMessage('Item Unloaded');
            break;
          }
          case Action.RECEIVE: {
            setToastMessage('Item Received');
            break;
          }
          case Action.CANCEL_RECEIVE: {
            setToastMessage('Receive Cancelled');
            break;
          }
        }
        setShowSuccessToast(true);
        setIdEntry(new DisplayValue(''));
        setQuantityEntry(new DisplayValue('1'));
        idRef?.current?.focus();
      }
    }
  }
  function clearButtonClicked() {
    setTruckEntry(new DisplayValue(''));
    setIdEntry(new DisplayValue(''));
    setLocationEntry(new DisplayValue(''));
    setQuantityEntry(new DisplayValue(''));
    truckRef?.current?.focus();
  }

  function hasTouch() {
    return 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0;
  }

  if (hasTouch()) {
    try {
      for (var sheetIndex in document.styleSheets) {
        var styleSheet = document.styleSheets[sheetIndex];
        if (!styleSheet.cssRules) continue;

        for (
          var ruleIndex = styleSheet.cssRules.length - 1;
          ruleIndex >= 0;
          ruleIndex--
        ) {
          if (!styleSheet.cssRules[ruleIndex].cssText) continue;

          if (styleSheet.cssRules[ruleIndex].cssText.match(':hover')) {
            styleSheet.deleteRule(ruleIndex);
          }
        }
      }
    } catch (exception) {}
  }
  return (
    <div>
      <Snackbar
        open={showSuccessToast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => {
          setShowSuccessToast(false);
        }}
      >
        <Alert severity="success" sx={{ width: '100%' }} variant="filled">
          {toastMessage}
        </Alert>
      </Snackbar>
      <AlertDialog
        message={alertDialogData?.message}
        showDialog={alertDialogData?.metaData.showDialog}
        closeDialog={() => {
          setAlertDialogData({
            message: '',
            metaData: {
              showDialog: false,
              textFieldRefToBeFocused: alertDialogData.metaData.textFieldRefToBeFocused,
            },
          });
        }}
      />

      <Stack height={'442px'} flexShrink={0} paddingRight={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="baseline">
          <Typography paddingTop={3} paddingLeft={2} marginBottom={3} variant="h6">
            Dlr Receiving
          </Typography>
          <Button
            sx={{ width: '100px' }}
            variant="contained"
            size="small"
            onClick={() => {
              navigate('/Main');
            }}
          >
            DONE
          </Button>
        </Stack>
        <Typography variant="subtitle1" lineHeight={1}>
          <Grid
            container
            direction={'row'}
            rowSpacing={2}
            alignItems={'flex-start'}
            justifyContent={'flex-end'}
            columnSpacing={1}
            paddingLeft={2}
          >
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              Date
            </Grid>
            <Grid item xs={9} data-testid="mobileDatePicker">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  inputFormat="MM-dd-yyyy"
                  onChange={(newValue) => {
                    if (newValue) {
                      setDatePicker(newValue);
                    }
                  }}
                  value={datePicker}
                  renderInput={(params) => (
                    <TextField {...params} sx={{ width: '50%' }} />
                  )}
                ></MobileDatePicker>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              Truck
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                inputProps={{ 'aria-label': 'truckInput' }}
                multiline
                minRows={1}
                maxRows={1}
                inputRef={truckRef}
                value={truckEntry.getDisplayValue()}
                onChange={(eventData) => {
                  setTruckEntry(new DisplayValue(eventData.target.value));
                }}
                onFocus={() => {
                  setLastFocusedField(truckRef);
                }}
              ></TextField>
            </Grid>
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              ID
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                multiline
                minRows={1}
                maxRows={1}
                inputProps={{ 'aria-label': 'idInput' }}
                inputRef={idRef}
                value={idEntry.getDisplayValue()}
                onChange={(eventData) => {
                  setIdEntry(new DisplayValue(eventData.target.value));
                }}
                onBlur={() => {
                  focusLostId();
                }}
                onFocus={() => {
                  setLastFocusedField(idRef);
                }}
              ></TextField>
            </Grid>
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              Location
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                inputProps={{ 'aria-label': 'locationInput' }}
                inputRef={locationRef}
                multiline
                minRows={1}
                maxRows={1}
                value={locationEntry.getDisplayValue()}
                onChange={(eventData) => {
                  setLocationEntry(new DisplayValue(eventData.target.value));
                }}
                onFocus={() => {
                  setLastFocusedField(locationRef);
                }}
              ></TextField>
            </Grid>
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              Quantity
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                inputProps={{ 'aria-label': 'quantityInput' }}
                inputRef={qtyRef}
                multiline
                minRows={1}
                maxRows={1}
                value={quantityEntry.getDisplayValue()}
                onChange={(eventData) => {
                  setQuantityEntry(new DisplayValue(eventData.target.value));
                }}
                onBlur={() => {
                  focusLostQty();
                }}
                onFocus={() => {
                  setLastFocusedField(qtyRef);
                }}
              ></TextField>
            </Grid>
            <Grid item xs={6}>
              <Button
                sx={{ width: '100%', padding: '0.67em' }}
                variant="contained"
                size="medium"
                ref={(node) => {
                  loadButtonRef = node;
                }}
                disabled={areButtonsDisabled}
                onClick={() => {
                  actionButtonClicked(Action.LOAD);
                }}
              >
                Load
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                sx={{ width: '100%', padding: '0.67em' }}
                variant="contained"
                size="medium"
                disabled={areButtonsDisabled}
                onClick={() => {
                  actionButtonClicked(Action.UNLOAD);
                }}
              >
                Unload
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                sx={{ width: '100%', padding: '0.67em' }}
                variant="contained"
                size="medium"
                disabled={areButtonsDisabled}
                onClick={() => {
                  actionButtonClicked(Action.RECEIVE);
                }}
              >
                Receive
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                sx={{ width: '100%', lineHeight: '1.1em' }}
                variant="contained"
                size="medium"
                disabled={areButtonsDisabled}
                onClick={() => {
                  actionButtonClicked(Action.CANCEL_RECEIVE);
                }}
              >
                Cancel <br /> Receive
              </Button>
            </Grid>
            <Grid item xs={12} justifyContent={'left'} alignSelf={'center'}>
              <Stack direction="column" spacing={1}>
                <Button
                  id="clear-button"
                  fullWidth
                  variant="contained"
                  size="small"
                  onClick={() => {
                    clearButtonClicked();
                  }}
                >
                  CLEAR
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Typography>
      </Stack>
    </div>
  );
}

export default DealerProcessManifest;
