import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppService, { ScanResponse } from '../Services/app.service';
import Validation, { ValidationResult } from '../Services/validation.service';
import AcceptDialog from './Dialogs/AcceptDialog';
import AlertDialog from './Dialogs/AlertDialog';
import { DialogMetaData } from './Utils/DialogMetaData';
import { DisplayValue } from './Utils/DisplayValue';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import WarningAmber from '@mui/icons-material/WarningAmber';
import { SessionManager } from './SessionManager';
import Utilities from './Utils/Utilities';
import { IDType } from '../Services/IDValidationTypes';

function ReceiveSKU() {
  const navigate = useNavigate();
  const [bolEntry, setBolEntry] = useState(new DisplayValue(''));
  const [locationEntry, setLocationEntry] = useState(new DisplayValue(''));
  const [quantityEntry, setQuantityEntry] = useState(new DisplayValue('1'));
  const [poNumberEntry, setPONumberEntry] = useState(new DisplayValue(''));
  const [skuEntry, setSKUEntry] = useState(new DisplayValue(''));
  const [showAcceptDialogData, setShowAcceptDialogData] = useState<DialogMetaData>({
    showDialog: false,
    textFieldRefToBeFocused: undefined,
  });
  const [showQuantityGreaterThanOneDialogData, setShowQuantityGreaterThanOneDialogData] =
    useState<DialogMetaData>({ showDialog: false, textFieldRefToBeFocused: undefined });
  const bolRef = useRef<HTMLInputElement>();
  const locationRef = useRef<HTMLInputElement>();
  const quantityRef = useRef<HTMLInputElement>();
  const poNumberRef = useRef<HTMLInputElement>();
  const skuRef = useRef<HTMLInputElement>();

  const [alertDialogData, setAlertDialogData] = useState<DialogMetaData>({
    showDialog: false,
    textFieldRefToBeFocused: undefined,
  });
  const [alertMessage, setAlertMessage] = useState('');

  const appService: AppService = new AppService();
  const sessionManager = new SessionManager();

  let fieldValidation: Validation = new Validation();
  const action = 'RCVSKU';

  function closeDialog() {
    setAlertDialogData({
      showDialog: false,
      textFieldRefToBeFocused: alertDialogData.textFieldRefToBeFocused,
    });
  }

  useEffect(Utilities.onDialogClosedFocusField(alertDialogData), [alertDialogData]);
  useEffect(Utilities.onDialogClosedFocusField(showAcceptDialogData), [
    showAcceptDialogData,
  ]);
  useEffect(Utilities.onDialogClosedFocusField(showQuantityGreaterThanOneDialogData), [
    showQuantityGreaterThanOneDialogData,
  ]);

  useEffect(Utilities.checkForScannedData(bolEntry, locationRef), [bolEntry]);
  useEffect(Utilities.checkForScannedData(quantityEntry, poNumberRef), [quantityEntry]);
  useEffect(Utilities.checkForScannedData(poNumberEntry, skuRef), [poNumberEntry]);
  useEffect(Utilities.checkForScannedData(locationEntry, quantityRef), [locationEntry]);

  useEffect(() => {
    if (skuEntry.getOriginalValue().search(/\n/) > 0) {
      attemptApplyScan(quantityEntry, poNumberEntry, skuEntry);
    }
  }, [skuEntry]);

  function focusLostBOL() {
    if (bolEntry.getDisplayValue() !== '') {
      fieldValidation
        .validateBOL(bolEntry.getDisplayValue())
        .then((result: ValidationResult) => {
          if (!result.isValid) {
            setAlertMessage(result.message);
            setAlertDialogData({ showDialog: true, textFieldRefToBeFocused: bolRef });
          }
        });
    }
  }

  function focusLostLocation() {
    if (locationEntry.getDisplayValue() !== '') {
      fieldValidation
        .validateLocation(locationEntry.getDisplayValue())
        .then((result: ValidationResult) => {
          if (!result.isValid) {
            setAlertMessage(result.message);
            setAlertDialogData({
              showDialog: true,
              textFieldRefToBeFocused: locationRef,
            });
          }
        });
    }
  }

  function attemptApplyScan(
    quantity: DisplayValue,
    poNumber: DisplayValue,
    sku: DisplayValue
  ) {
    const fieldValidationResult = fieldValidation.validateReceiveSKUFields(
      quantity.getOriginalValue(),
      poNumber.getOriginalValue(),
      sku.getOriginalValue()
    );
    if (fieldValidationResult.isValid) {
      if (parseInt(quantityEntry.getDisplayValue()) > 1) {
        setShowQuantityGreaterThanOneDialogData({
          showDialog: true,
          textFieldRefToBeFocused:
            showQuantityGreaterThanOneDialogData.textFieldRefToBeFocused,
        });
      } else {
        applyReceiveSKUWithoutValidation();
      }
    } else {
      setAlertMessage(fieldValidationResult.message);

      if (fieldValidationResult.message === 'Enter the PO') {
        setAlertDialogData({ showDialog: true, textFieldRefToBeFocused: poNumberRef });
      } else if (fieldValidationResult.message === 'Not a valid SKU') {
        setAlertDialogData({ showDialog: true, textFieldRefToBeFocused: skuRef });
      }
    }
  }

  function applyReceiveSKUWithoutValidation() {
    appService
      .applyReceiveSKUScan(
        action,
        bolEntry.getDisplayValue(),
        locationEntry.getDisplayValue(),
        parseInt(quantityEntry.getDisplayValue()),
        poNumberEntry.getDisplayValue(),
        skuEntry.getDisplayValue()
      )
      .then((response: ScanResponse) => {
        if (response.valid) {
          if (sessionManager.getPrinter() !== '') {
            appService
              .printLabels(
                IDType.SKU,
                skuEntry.getDisplayValue(),
                sessionManager.getPrinter(),
                '',
                ''
              )
              .then((response: ScanResponse) => {
                if (!response.valid) {
                  setAlertMessage(response.error);
                  setAlertDialogData({
                    showDialog: true,
                    textFieldRefToBeFocused: alertDialogData.textFieldRefToBeFocused,
                  });
                }
              });
          }
          setShowAcceptDialogData({
            showDialog: true,
            textFieldRefToBeFocused: showAcceptDialogData.textFieldRefToBeFocused,
          });
        } else {
          setAlertMessage(response.error);
          setAlertDialogData({ showDialog: true, textFieldRefToBeFocused: skuRef });
        }
      });
  }

  function noButtonFunction() {
    setShowAcceptDialogData({ showDialog: false, textFieldRefToBeFocused: bolRef });
    setQuantityToOneAndClearOtherInputs();
  }

  function setQuantityToOneAndClearOtherInputs() {
    setBolEntry(new DisplayValue(''));
    setLocationEntry(new DisplayValue(''));
    setQuantityEntry(new DisplayValue('1'));
    setPONumberEntry(new DisplayValue(''));
    setSKUEntry(new DisplayValue(''));
  }

  function yesButtonFunction() {
    setShowAcceptDialogData({ showDialog: false, textFieldRefToBeFocused: skuRef });
    setSKUEntry(new DisplayValue(''));
  }

  function yesQuantityButtonFunction() {
    applyReceiveSKUWithoutValidation();
    setShowQuantityGreaterThanOneDialogData({
      showDialog: false,
      textFieldRefToBeFocused:
        showQuantityGreaterThanOneDialogData.textFieldRefToBeFocused,
    });
  }

  function noQuantityButtonFunction() {
    setShowQuantityGreaterThanOneDialogData({
      showDialog: false,
      textFieldRefToBeFocused: quantityRef,
    });
  }

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignContent={'stretch'}
      spacing={1}
      marginRight="1em"
      display={'flex'}
      height={'95vh'}
    >
      <AlertDialog
        message={alertMessage}
        showDialog={alertDialogData.showDialog}
        closeDialog={closeDialog}
      />

      <AcceptDialog
        messageLine1="Item accepted:"
        messageLine2="Continue same BOL?"
        showDialog={showAcceptDialogData.showDialog}
        noButtonFunction={noButtonFunction}
        yesButtonFunction={yesButtonFunction}
        icon={<TaskAltIcon fontSize="large" htmlColor="#2E7D32" />}
      />

      <AcceptDialog
        messageLine1="Qty is more than 1."
        messageLine2="Do you want to continue?"
        showDialog={showQuantityGreaterThanOneDialogData.showDialog}
        noButtonFunction={noQuantityButtonFunction}
        yesButtonFunction={yesQuantityButtonFunction}
        icon={<WarningAmber fontSize="large" htmlColor="#ED6C02" />}
      />

      <Stack height={'442px'} flexShrink={0}>
        <Stack direction="row" justifyContent="space-between" alignItems="baseline">
          <Typography paddingTop={3} paddingLeft={2} marginBottom={3} variant="h6">
            Receive
          </Typography>
          <Button
            variant="contained"
            size="medium"
            onClick={() => {
              navigate('/Main');
            }}
          >
            Done
          </Button>
        </Stack>
        <Typography variant="subtitle1" lineHeight={1}>
          <Grid
            container
            rowSpacing={2}
            justifyItems={'center'}
            justifyContent={'space-between'}
            columnSpacing={1}
            paddingLeft={2}
          >
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              BOL #
            </Grid>
            <Grid item xs={9}>
              <TextField
                autoFocus
                inputRef={bolRef}
                inputProps={{ 'aria-label': 'bol-number' }}
                value={bolEntry.getDisplayValue()}
                multiline
                minRows={1}
                maxRows={1}
                size="small"
                id="bol-number"
                required
                fullWidth
                onChange={(eventData) => {
                  setBolEntry(new DisplayValue(eventData.target.value));
                }}
                onBlur={focusLostBOL}
              />
            </Grid>
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              Location
            </Grid>
            <Grid item xs={9}>
              <TextField
                inputRef={locationRef}
                inputProps={{ 'aria-label': 'location' }}
                value={locationEntry.getDisplayValue()}
                multiline
                minRows={1}
                maxRows={1}
                size="small"
                id="location"
                required
                fullWidth
                onChange={(eventData) => {
                  setLocationEntry(new DisplayValue(eventData.target.value));
                }}
                onBlur={focusLostLocation}
              />
            </Grid>
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              Quantity
            </Grid>
            <Grid item xs={9}>
              <TextField
                sx={{ width: '50%' }}
                value={quantityEntry.getDisplayValue()}
                inputRef={quantityRef}
                inputProps={{ 'aria-label': 'quantity' }}
                multiline
                minRows={1}
                maxRows={1}
                size="small"
                id="quantity"
                required
                fullWidth
                onChange={(eventData) => {
                  setQuantityEntry(new DisplayValue(eventData.target.value));
                }}
              />
            </Grid>
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              PO #
            </Grid>
            <Grid item xs={9}>
              <TextField
                inputRef={poNumberRef}
                value={poNumberEntry.getDisplayValue()}
                inputProps={{ 'aria-label': 'po-number' }}
                multiline
                minRows={1}
                maxRows={1}
                size="small"
                id="po-number"
                required
                fullWidth
                onChange={(eventData) => {
                  setPONumberEntry(new DisplayValue(eventData.target.value));
                }}
              />
            </Grid>
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              SKU
            </Grid>
            <Grid item xs={9}>
              <TextField
                inputRef={skuRef}
                value={skuEntry.getDisplayValue()}
                inputProps={{ 'aria-label': 'sku' }}
                multiline
                minRows={1}
                maxRows={1}
                size="small"
                id="sku"
                required
                fullWidth
                onChange={(eventData) => {
                  setSKUEntry(new DisplayValue(eventData.target.value));
                }}
              />
            </Grid>
          </Grid>
        </Typography>
      </Stack>
      <div
        style={{
          alignSelf: 'center',
          marginTop: '1em',
          width: '100%',
          paddingLeft: '1em',
        }}
      >
        <Button
          id="enter-button"
          fullWidth
          variant="contained"
          size="large"
          onClick={() => {
            attemptApplyScan(quantityEntry, poNumberEntry, skuEntry);
          }}
        >
          Enter
        </Button>
      </div>
    </Stack>
  );
}
export default ReceiveSKU;
