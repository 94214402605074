import {
  Alert,
  Button,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { useEffect, useRef, useState } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Validation from '../Services/validation.service';
import AlertDialog from './Dialogs/AlertDialog';
import { DialogMetaData } from './Utils/DialogMetaData';
import { IDType } from '../Services/IDValidationTypes';
import AppService from '../Services/app.service';
import Utilities from './Utils/Utilities';
import { DisplayValue } from './Utils/DisplayValue';

function ExternalOrderManifest() {
  const appservice = new AppService();

  const navigate = useNavigate();
  const [alertDialogData, setAlertDialogData] = useState<DialogMetaData>({
    showDialog: false,
    textFieldRefToBeFocused: undefined,
  });
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [dateEntry, setDateEntry] = useState<Date | null>(new Date());
  const [truckEntry, setTruckEntry] = useState<DisplayValue>(new DisplayValue(''));
  const [externalOrderNumberEntry, setExternalOrderNumberEntry] = useState<DisplayValue>(
    new DisplayValue('')
  );
  const [idEntry, setIdEntry] = useState<DisplayValue>(new DisplayValue(''));
  const [locationEntry, setLocationEntry] = useState<DisplayValue>(new DisplayValue(''));
  const [loadButtonDisabled, setLoadButtonDisabled] = useState<boolean>(false);
  const [unloadButtonDisabled, setUnloadButtonDisabled] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const validation = new Validation();

  let unloadRef: HTMLButtonElement | null;
  const truckRef = useRef<HTMLInputElement>();
  const idRef = useRef<HTMLInputElement>();
  const locationRef = useRef<HTMLInputElement>();
  const externalOrderNumberRef = useRef<HTMLInputElement>();

  useEffect(Utilities.onDialogClosedFocusField(alertDialogData), [alertDialogData]);

  useEffect(() => {
    if (idEntry.getOriginalValue().search(/\n/) > 0) {
      loadButtonClick();
    }
  }, [idEntry]);

  useEffect(() => {
    if (locationEntry.getOriginalValue().search(/\n/) > 0) {
      unloadRef?.focus();
    }
  }, [locationEntry]);

  useEffect(Utilities.checkForScannedData(truckEntry, externalOrderNumberRef), [
    truckEntry,
  ]);

  useEffect(Utilities.checkForScannedData(externalOrderNumberEntry, idRef), [
    externalOrderNumberEntry,
  ]);

  useEffect(() => {
    if (truckEntry.getDisplayValue() !== '' && idEntry.getDisplayValue() !== '') {
      setLoadButtonDisabled(false);
    } else {
      setLoadButtonDisabled(true);
    }
  }, [truckEntry, idEntry]);

  useEffect(() => {
    if (
      truckEntry.getDisplayValue() !== '' &&
      idEntry.getDisplayValue() !== '' &&
      locationEntry.getDisplayValue() !== ''
    ) {
      setUnloadButtonDisabled(false);
    } else {
      setUnloadButtonDisabled(true);
    }
  }, [truckEntry, idEntry, locationEntry]);

  useEffect(() => {
    truckRef.current?.focus();
  }, [dateEntry]);


  function focusLostTruck() {
    if (truckEntry.getDisplayValue() !== '') {
      const validationResults = validation.validateTruck(truckEntry.getDisplayValue());
      if (!validationResults.isValid) {
        setAlertMessage(validationResults.message);
        setAlertDialogData({
          showDialog: true,
          textFieldRefToBeFocused: truckRef,
        });
      }
    }
  }

  function focusLostExternalOrderNumber() {
    if (externalOrderNumberEntry.getDisplayValue() !== '') {
      const validationResults = validation.validateExternalOrderNumber(
        externalOrderNumberEntry.getDisplayValue()
      );
      if (!validationResults.isValid) {
        setAlertMessage(validationResults.message);
        setAlertDialogData({
          showDialog: true,
          textFieldRefToBeFocused: externalOrderNumberRef,
        });
      }
    }
  }

  function closeDialog() {
    setAlertDialogData({
      showDialog: false,
      textFieldRefToBeFocused: alertDialogData.textFieldRefToBeFocused,
    });
  }

  async function loadButtonClick() {
    const validationResult = validateId();
    if (validationResult.isValid) {
      const response = await appservice.loadExternalManifest(
        validationResult.formattedId,
        formatDate(dateEntry),
        truckEntry.getDisplayValue(),
        externalOrderNumberEntry.getDisplayValue()
      );
      if (!response.valid) {
        setAlertMessage(response.error);
        setAlertDialogData({
          showDialog: true,
          textFieldRefToBeFocused: idRef,
        });
      } else {
        setToastMessage('Item Loaded');
        setShowToast(true);
        clearIdAndLocationAndExternalOrder();
        idRef.current?.focus();
      }
    }
  }

  function clearIdAndLocationAndExternalOrder() {
    setIdEntry(new DisplayValue(''));
    setLocationEntry(new DisplayValue(''));
    setExternalOrderNumberEntry(new DisplayValue(''));
  }

  function validateLocation(): boolean {
    const validationResult = validation.validateExternalOrderLocation(
      locationEntry.getDisplayValue()
    );
    if (!validationResult.isValid) {
      setAlertMessage(validationResult.message);
      setAlertDialogData({
        showDialog: true,
        textFieldRefToBeFocused: locationRef,
      });
      return false;
    }
    return true;
  }

  async function unloadButtonClick() {
    if (validateLocation()) {
      const validationResult = validateId();
      if (validationResult.isValid) {
        const response = await appservice.unloadExternalManifest(
          validationResult.formattedId,
          formatDate(dateEntry),
          truckEntry.getDisplayValue(),
          externalOrderNumberEntry.getDisplayValue(),
          locationEntry.getDisplayValue()
        );
        if (!response.valid) {
          setAlertMessage(response.error);
          setAlertDialogData({
            showDialog: true,
            textFieldRefToBeFocused: idRef,
          });
        } else {
          setToastMessage('Item Unloaded');
          setShowToast(true);
          clearIdAndLocationAndExternalOrder();
          idRef.current?.focus();
        }
      }
    }
  }

  function validateId(): { isValid: boolean; formattedId: string } {
    const idValidationResult = validation.validateId(idEntry.getDisplayValue());
    if (!idValidationResult.isValid) {
      setAlertMessage(idValidationResult.message);
      setAlertDialogData({
        showDialog: true,
        textFieldRefToBeFocused: idRef,
      });
      return { isValid: false, formattedId: '' };
    } else if (
      idValidationResult.type === IDType.SKU ||
      idValidationResult.type === IDType.PICKID
    ) {
      setAlertMessage(
        `ID type: ${idValidationResult.type}. Please enter an ALI, EPC, or POID.`
      );
      setAlertDialogData({
        showDialog: true,
        textFieldRefToBeFocused: idRef,
      });
      return { isValid: false, formattedId: '' };
    }
    return { isValid: true, formattedId: idValidationResult.id };
  }
  function formatDate(dateEntry: Date | null): string {
    let date = '';
    if (dateEntry !== null) {
      date = Utilities.formatDateYYYYMMDD(dateEntry);
    }
    return date;
  }

  return (
    <div>
      <AlertDialog
        message={alertMessage}
        showDialog={alertDialogData.showDialog}
        closeDialog={() => {
          closeDialog();
        }}
      />
      <Snackbar
        open={showToast}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={() => {
          setShowToast(false);
        }}
      >
        <Alert severity="success" sx={{ width: '100%' }} variant="filled">
          {toastMessage}
        </Alert>
      </Snackbar>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignContent={'stretch'}
        spacing={1}
        marginRight="1em"
        display={'flex'}
        height={'86vh'}
        paddingRight={1}
        paddingLeft={1}
      >
        <Stack height={'442px'} flexShrink={0}>
          <Stack direction="row" justifyContent="space-between" alignItems="baseline">
            <Typography paddingTop={3} paddingLeft={2} marginBottom={3} variant="h6">
              External Order Manifest
            </Typography>
            <Button
              sx={{ width: '100px' }}
              variant="contained"
              size="small"
              onClick={() => {
                navigate('/Main');
              }}
            >
              Done
            </Button>
          </Stack>

          <Typography variant="subtitle1" lineHeight={1}>
            <Grid
              container
              direction={'row'}
              rowSpacing={2}
              alignItems={'flex-start'}
              justifyContent={'flex-end'}
              columnSpacing={1}
              paddingLeft={2}
            >
              <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
                Date
              </Grid>
              <Grid item xs={9} data-testid="mobileDatePicker">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    inputFormat="MM-dd-yyyy"
                    onChange={(newValue) => {
                      setDateEntry(newValue);
                    }}
                    value={dateEntry}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ width: '50%' }} />
                    )}
                  ></MobileDatePicker>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
                Truck
              </Grid>
              <Grid item xs={9}>
                <TextField
                  autoFocus
                  inputProps={{ 'aria-label': 'truckInput' }}
                  multiline
                  minRows={1}
                  maxRows={1}
                  size="small"
                  fullWidth
                  onChange={(eventData) => {
                    setTruckEntry(new DisplayValue(eventData.target.value));
                  }}
                  onBlur={focusLostTruck}
                  value={truckEntry.getDisplayValue()}
                  inputRef={truckRef}
                />
              </Grid>
              <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
                External Order
              </Grid>
              <Grid item xs={9}>
                <TextField
                  inputProps={{ 'aria-label': 'externalOrderInput' }}
                  multiline
                  minRows={1}
                  maxRows={1}
                  size="small"
                  fullWidth
                  onBlur={focusLostExternalOrderNumber}
                  value={externalOrderNumberEntry.getDisplayValue()}
                  inputRef={externalOrderNumberRef}
                  onChange={(eventData) => {
                    setExternalOrderNumberEntry(new DisplayValue(eventData.target.value));
                  }}
                />
              </Grid>
              <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
                ID
              </Grid>
              <Grid item xs={9}>
                <TextField
                  inputProps={{ 'aria-label': 'idInput' }}
                  multiline
                  minRows={1}
                  maxRows={1}
                  size="small"
                  fullWidth
                  value={idEntry.getDisplayValue()}
                  inputRef={idRef}
                  onChange={(eventData) => {
                    setIdEntry(new DisplayValue(eventData.target.value));
                  }}
                />
              </Grid>
              <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
                Location
              </Grid>
              <Grid item xs={9}>
                <TextField
                  inputProps={{ 'aria-label': 'locationInput' }}
                  multiline
                  minRows={1}
                  maxRows={1}
                  size="small"
                  fullWidth
                  value={locationEntry.getDisplayValue()}
                  inputRef={locationRef}
                  onChange={(eventData) => {
                    setLocationEntry(new DisplayValue(eventData.target.value));
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent={'left'}
                alignSelf={'center'}
                marginTop={3}
              >
                <Button
                  disabled={loadButtonDisabled}
                  fullWidth
                  variant="contained"
                  size="large"
                  onClick={loadButtonClick}
                >
                  LOAD
                </Button>
              </Grid>
              <Grid item xs={12} justifyContent={'left'} alignSelf={'center'}>
                <Button
                  disabled={unloadButtonDisabled}
                  fullWidth
                  variant="contained"
                  size="large"
                  ref={(node) => {
                    unloadRef = node;
                  }}
                  onClick={unloadButtonClick}
                >
                  UNLOAD
                </Button>
              </Grid>
              <Grid item xs={12} justifyContent={'left'} alignSelf={'center'}>
                <Button
                  id="cancel-button"
                  fullWidth
                  variant="contained"
                  size="small"
                  onClick={() => {
                    navigate('/ManifestMenu');
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Typography>
        </Stack>
      </Stack>
    </div>
  );
}

export default ExternalOrderManifest;
