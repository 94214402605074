import { createTheme, Theme } from '@mui/material/styles';

const customTheme: Theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'black !important',
        },
        input: {
          '&::placeholder': {
            color: '#767676',
            opacity: 1,
          },
          paddingTop: 4.3,
          paddingBottom: 4.3,
        },
        root: {
          '& fieldset': {
            borderColor: 'black',
          },
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          border: '1px #797979 solid',
          '&:focus': {
            color: 'black',
            backgroundColor: '#8FD0EC',
          },
        },
        sizeLarge: {
          fontSize: 'large',
        },
        contained: {
          backgroundColor: '#8FD0EC',
          color: 'black',
          boxShadow: 'none',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#8FD0EC',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiDialogTitle-root': {
            color: 'black',
          },
        },
      },
    },
  },
});

export { customTheme };
