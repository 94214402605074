import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DisplayValue } from './Utils/DisplayValue';
import AlertDialog from './Dialogs/AlertDialog';

import { useEffect, useRef, useState } from 'react';
import Validation, {
  IDValidationResult,
  ValidationResult,
} from '../Services/validation.service';
import AppService, { ValidateStockResponse } from '../Services/app.service';
import { DialogMetaData } from './Utils/DialogMetaData';
import { IDType } from '../Services/IDValidationTypes';
import { SessionManager } from './SessionManager';
import Utilities from './Utils/Utilities';
import { validateTime } from '@mui/lab/internal/pickers/time-utils';

const ifScannedInput = (value: DisplayValue, callback: Function) => {
  if (value.getOriginalValue().search(/\n/) > 0) {
    callback();
  }
};

function PrintPage() {
  const idSkuRef = useRef<HTMLInputElement>();
  const locationRef = useRef<HTMLInputElement>();
  const quantityRef = useRef<HTMLInputElement>();
  const navigate = useNavigate();
  let enterButton: HTMLButtonElement | null;
  let locationField: HTMLInputElement | null;
  let quantityField: HTMLInputElement | null;

  const [validationResult, setValidationResult] = useState<IDValidationResult>();
  const [isEnterDisabled, setIsEnterDisabled] = useState<boolean>(true);
  const [locationDisabled, setLocationDisabled] = useState<boolean>(true);
  const [quantityDisabled, setQuantityDisabled] = useState<boolean>(true);
  const [idSkuEntry, setIdSkuEntry] = useState(new DisplayValue(''));
  const [locationEntry, setlocationEntry] = useState(new DisplayValue(''));
  const [quantityEntry, setQuantityEntry] = useState(new DisplayValue(''));
  const [validateStockResponse, setValidateStockResponse] =
    useState<ValidateStockResponse>();
  const [alertDialogData, setAlertDialogData] = useState<DialogMetaData>({
    showDialog: false,
    textFieldRefToBeFocused: undefined,
  });
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [locationValidationResult, setLocationValidationResult] =
    useState<ValidationResult>();
  const [quantityValidationResult, setQuantityValidationResult] =
    useState<ValidationResult>();

  useEffect(() => {
    if (idSkuEntry.getDisplayValue() === '') {
      setValidateStockResponse(undefined);
      setQuantityDisabled(true);
      setLocationDisabled(true);
    }
    ifScannedInput(idSkuEntry, () => {
      idSkuRef.current?.blur();
    });
  }, [idSkuEntry]);

  useEffect(() => {
    if (Utilities.isScannedInput(locationEntry.getOriginalValue())) {
      validateLocationInput();
    }
  }, [locationEntry]);

  useEffect(() => {
    const isLOcationNeeded =
      validationResult?.type === IDType.SKU || validationResult?.type === IDType.PICKID;
    if (
      validateStockResponse?.valid &&
      validationResult?.isValid &&
      (locationValidationResult?.isValid || !isLOcationNeeded)
    ) {
      setIsEnterDisabled(false);
    } else {
      setIsEnterDisabled(true);
    }
  }, [validateStockResponse, validationResult, locationValidationResult]);

  useEffect(() => {
    if (Utilities.isScannedInput(quantityEntry.getOriginalValue())) {
      validateQuantityInput();
    }
  }, [quantityEntry]);

  const showQuantityAndLocationForSKUOrPickID = () => {
    if (
      validateStockResponse?.valid &&
      (validationResult?.type === IDType.PICKID || validationResult?.type === IDType.SKU)
    ) {
      setQuantityDisabled(false);
      setLocationDisabled(false);
    }
  };

  useEffect(showQuantityAndLocationForSKUOrPickID, [
    validateStockResponse,
    validationResult,
  ]);

  const setFocus = () => {
    if (alertDialogData.showDialog) {
      return;
    }

    if (!validateStockResponse?.valid) {
      return idSkuRef.current?.focus();
    }

    const isSKUorPICKID =
      validationResult?.type === IDType.PICKID || validationResult?.type === IDType.SKU;

    if (isSKUorPICKID && !locationValidationResult?.isValid) {
      return locationField?.focus();
    }

    if (isSKUorPICKID && !quantityValidationResult?.isValid) {
      return quantityField?.focus();
    }

    return enterButton?.focus();
  };

  useEffect(setFocus, [
    locationDisabled,
    quantityDisabled,
    validateStockResponse,
    validationResult,
    locationValidationResult,
    quantityValidationResult,
  ]);

  useEffect(() => {
    if (!locationValidationResult?.isValid && locationValidationResult !== undefined) {
      setAlertMessage(locationValidationResult.message);
      setAlertDialogData({
        showDialog: true,
        textFieldRefToBeFocused: locationRef,
      });
    }
  }, [locationValidationResult]);

  useEffect(() => {
    if (!quantityValidationResult?.isValid && quantityValidationResult !== undefined) {
      setAlertMessage(quantityValidationResult.message);
      setAlertDialogData({
        showDialog: true,
        textFieldRefToBeFocused: quantityRef,
      });
    }
  }, [quantityValidationResult]);

  useEffect(Utilities.onDialogClosedFocusField(alertDialogData), [alertDialogData]);

  const onLocationBlur = async () => {
    if (locationEntry.getDisplayValue() === '') {
      return;
    }
    validateLocationInput();
  };

  const onIdInputBlur = async () => {
    if (idSkuEntry.getDisplayValue() === '') {
      return;
    }
    validateSkuInput();
  };

  const onQuantityBlur = async () => {
    validateQuantityInput();
  };

  const validateSkuInput = async () => {
    const validationResult = new Validation().validateId(idSkuEntry.getDisplayValue());
    if (validationResult.isValid) {
      setValidationResult(validationResult);
      let result = await new AppService().validateStock(
        validationResult.type,
        validationResult.id
      );
      if (!result.valid) {
        setAlertMessage(result.error);
        setAlertDialogData({
          showDialog: true,
          textFieldRefToBeFocused: idSkuRef,
        } as DialogMetaData);
      } else {
        setValidateStockResponse(result);
      }
    } else {
      setAlertMessage(`ID :${validationResult.type.toString()}`);
      setAlertDialogData({
        showDialog: true,
        textFieldRefToBeFocused: idSkuRef,
      } as DialogMetaData);
    }
  };

  const validateLocationInput = async () => {
    let validation = new Validation();
    setLocationValidationResult(
      await validation.validateLocation(locationEntry.getDisplayValue())
    );
  };

  const validateQuantityInput = () => {
    const validation = new Validation();
    setQuantityValidationResult(
      validation.validatePrintQuantity(quantityEntry.getDisplayValue())
    );
  };

  function closeDialog() {
    setAlertDialogData({
      showDialog: false,
      textFieldRefToBeFocused: alertDialogData.textFieldRefToBeFocused,
    });
  }

  async function onEnterButtonClick() {
    let sessionManager = new SessionManager();
    let printResponse = await new AppService().printLabels(
      validationResult?.type ? validationResult.type : IDType.INVALID,
      validationResult?.id ? validationResult.id : '',
      sessionManager.getPrinter(),
      quantityEntry.getDisplayValue(),
      locationEntry.getDisplayValue()
    );
    if (!printResponse.valid) {
      setAlertMessage(printResponse.error);
      setAlertDialogData({
        showDialog: true,
        textFieldRefToBeFocused: undefined,
      } as DialogMetaData);
    } else {
      setQuantityEntry(new DisplayValue(''));
      setlocationEntry(new DisplayValue(''));
      setIdSkuEntry(new DisplayValue(''));
      setQuantityValidationResult(undefined);
      setLocationValidationResult(undefined);
      setValidationResult(undefined);
      setValidateStockResponse(undefined);
    }
  }

  return (
    <div>
      <AlertDialog
        message={alertMessage}
        showDialog={alertDialogData.showDialog}
        closeDialog={() => {
          closeDialog();
        }}
      />
      <Stack
        direction="column"
        justifyContent="space-between"
        alignContent={'stretch'}
        spacing={1}
        marginRight="1em"
        marginLeft="1em"
        display={'flex'}
        height={'86vh'}
        flexDirection={'column'}
        boxSizing="border-box"
      >
        <Stack spacing={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="baseline">
            <Typography paddingTop={3} marginBottom={0.5} variant="h6">
              <label>Print a Label</label>
            </Typography>
            <Button
              sx={{ width: '100px' }}
              variant="contained"
              size="small"
              onClick={() => {
                navigate('/Main');
              }}
            >
              DONE
            </Button>
          </Stack>
          <Grid container rowSpacing={2}>
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              <label htmlFor="id-sku">ID / SKU</label>
            </Grid>
            <Grid item xs={9}>
              <TextField
                inputRef={idSkuRef}
                value={idSkuEntry.getDisplayValue()}
                onChange={(eventData) => {
                  setIdSkuEntry(new DisplayValue(eventData.target.value));
                }}
                onBlur={onIdInputBlur}
                autoFocus
                id="id-sku"
                minRows={1}
                maxRows={1}
                multiline
                size="small"
                fullWidth
              />
            </Grid>
            {!locationDisabled && (
              <>
                <Grid item xs={3} alignSelf={'center'}>
                  <label htmlFor="locationField">LOCATION</label>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    inputRef={locationRef}
                    value={locationEntry.getDisplayValue()}
                    onChange={(eventData) => {
                      setlocationEntry(new DisplayValue(eventData.target.value));
                    }}
                    id="locationField"
                    name="locationField"
                    fullWidth
                    multiline
                    onBlur={onLocationBlur}
                    disabled={locationDisabled}
                    inputProps={{ 'aria-label': 'location' }}
                    ref={(div) =>
                      (locationField = div?.children[0].children[0] as HTMLInputElement)
                    }
                  />
                </Grid>
              </>
            )}
            {!quantityDisabled && (
              <>
                <Grid item xs={3} alignSelf={'center'}>
                  <label htmlFor="quantityField">QUANTITY</label>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    inputRef={quantityRef}
                    value={quantityEntry.getDisplayValue()}
                    onChange={(eventData) => {
                      setQuantityEntry(new DisplayValue(eventData.target.value));
                    }}
                    id="quantityField"
                    name="quantityField"
                    fullWidth
                    multiline
                    onBlur={onQuantityBlur}
                    disabled={quantityDisabled}
                    inputProps={{ 'aria-label': 'quantity', inputMode: 'numeric' }}
                    ref={(div) =>
                      (quantityField = div?.children[0].children[0] as HTMLInputElement)
                    }
                  />
                </Grid>
              </>
            )}
            {validateStockResponse &&
              validationResult?.type !== IDType.SKU &&
              validationResult?.type !== IDType.PICKID && (
                <p style={{ paddingTop: '.5em' }}>
                  {`epc: ${validateStockResponse.epc}`} <br />
                  {`VSN: ${validateStockResponse.vsn}`} <br />
                  {`poid: ${validateStockResponse.polineitemlabel}`} <br />
                  {`salesstatus: ${validateStockResponse.salesstatus}`} <br />
                  {`Description: ${validateStockResponse.description}`}
                </p>
              )}
            {validateStockResponse && validationResult?.type === IDType.SKU && (
              <p style={{ paddingTop: '.5em' }}>
                {`SKU: ${validateStockResponse.sku}`} <br />
                {`VSN: ${validateStockResponse.vsn}`} <br />
                {`Description: ${validateStockResponse.description}`}
              </p>
            )}
            {validateStockResponse && validationResult?.type === IDType.PICKID && (
              <p style={{ paddingTop: '.5em' }}>
                {`PICKID: ${validateStockResponse.pickid}`} <br />
                {`SKU: ${validateStockResponse.sku}`} <br />
                {`VSN: ${validateStockResponse.vsn}`} <br />
                {`Description: ${validateStockResponse.description}`}
              </p>
            )}
          </Grid>
        </Stack>
        <Stack
          direction="column"
          justifyContent="space-between"
          spacing={2}
          marginRight="1em"
          marginLeft="1em"
        >
          <Button
            variant="contained"
            size="large"
            ref={(button) => (enterButton = button)}
            disabled={isEnterDisabled}
            onClick={onEnterButtonClick}
          >
            ENTER
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}
export default PrintPage;
