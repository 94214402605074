import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useEffect, useRef, useState } from 'react';
import Validation from '../Services/validation.service';
import { DisplayValue } from './Utils/DisplayValue';
import { IDType } from '../Services/IDValidationTypes';
import { DialogMetaData } from './Utils/DialogMetaData';
import AlertDialog from './Dialogs/AlertDialog';
import AppService from '../Services/app.service';
import Utilities from './Utils/Utilities';
import { SessionManager } from '../component/SessionManager';
import { DateWrapper } from './Utils/DateWrapper';

let localdata = {
  epc: '',
  poid: '',
  sourceStatus: '',
  pickid: '',
  ack: '',
  salesStatus: '',
  description: '',
  vsn: '',
};

let action = 'A';
let truck = '';

function ManifestMove() {
  const navigate = useNavigate();
  const sessionManager = new SessionManager();
  const validation: Validation = new Validation();
  const appService: AppService = new AppService();
  const [stopNum, setStopNum] = useState(new DisplayValue('# #'));
  const [truckEntry, setTruckEntry] = useState(new DisplayValue(''));
  const [idEntry, setIdEntry] = useState(new DisplayValue(''));
  const [datePicker, setDatePicker] = useState<Date | null>(DateWrapper.today());

  const [alertMessage, setAlertMessage] = useState<string>('');
  const [alertDialogData, setAlertDialogData] = useState<DialogMetaData>({
    showDialog: false,
    textFieldRefToBeFocused: undefined,
  });
  const idRef = useRef<HTMLInputElement>();
  const truckRef = useRef<HTMLInputElement>();

  useEffect(Utilities.checkForScannedData(idEntry, truckRef), [idEntry]);
  useEffect(Utilities.checkForScannedDataAndFocusNone(truckEntry, truckRef), [
    truckEntry,
  ]);

  useEffect(Utilities.onDialogClosedFocusField(alertDialogData), [alertDialogData]);
  useEffect(() => {
    localdata.epc = '';
    localdata.poid = '';
    action = 'A';
  }, []);

  useEffect(() => {
    idRef.current?.focus();
  }, [datePicker]);

  async function attemptAdd() {
    const validateStockResult = await appService.validateStockWithAction(
      action,
      formatDate(datePicker),
      truck,
      localdata.epc,
      localdata.poid,
      localdata.pickid
    );

    if (!validateStockResult.valid) {
      setAlertMessage(validateStockResult.error);
      setAlertDialogData({
        showDialog: true,
        textFieldRefToBeFocused: idRef,
      });
    } else {
      clearAllFields();
    }
  }
  async function attemptAddAndLoad() {
    const validateStockResult = await appService.validateStockWithAction(
      action,
      formatDate(datePicker),
      truck,
      localdata.epc,
      localdata.poid,
      localdata.pickid
    );

    if (!validateStockResult.valid) {
      setAlertMessage(validateStockResult.error);
      setAlertDialogData({
        showDialog: true,
        textFieldRefToBeFocused: undefined,
      });
    } else {
      let result = await checkManifest();
      if (result) {
        updateBarcodeTable();
        clearAllFields();
      } else {
        setAlertMessage('Item added but unable to Load as error was detected');
        setAlertDialogData({
          showDialog: true,
          textFieldRefToBeFocused: undefined,
        });
      }
    }
  }

  async function updateBarcodeTable() {
    const profitCenterId = sessionManager.getProfitCenterID();
    const xml = Utilities.buildXML(
      profitCenterId,
      truck,
      'MAN',
      localdata.poid,
      localdata.ack,
      localdata.epc,
      'DOCK',
      'TRK',
      ''
    );
    const response = await appService.sendBarcode(xml);
    if (!response.valid) {
      setAlertMessage(response.error);
      setAlertDialogData({
        showDialog: true,
        textFieldRefToBeFocused: idRef,
      });
    }
  }

  async function checkManifest(): Promise<boolean> {
    let date = new Date();
    if (datePicker != null) {
      date = datePicker;
    }
    if (localdata.sourceStatus !== 'T') {
      return Promise.resolve(false);
    } else {
      const result = await appService.manifestLoad(
        '',
        date,
        truck,
        localdata.epc,
        localdata.poid,
        localdata.pickid,
        ''
      );
      return Promise.resolve(result.valid);
    }
  }

  function onChangeTruck() {
    action = 'C';
  }

  function setTruck(truckString: string) {
    truck = truckString.trim();
    setTruckEntry(new DisplayValue(truckString));
  }

  function focusLostTruck() {
    if (truck !== '') {
      const validationResults = validation.validateTruck(truck);
      if (!validationResults.isValid) {
        setAlertMessage(validationResults.message);
        setAlertDialogData({
          showDialog: true,
          textFieldRefToBeFocused: truckRef,
        });
      }
    }
  }

  function clearAllFields() {
    setIdEntry(new DisplayValue(''));
    setTruck('');
    action = 'A';
    idRef.current?.focus();
  }

  async function focusLostID() {
    if (idEntry.getDisplayValue() !== '') {
      let idValidationResult = validation.validateId(idEntry.getDisplayValue());
      localdata.epc = '';
      localdata.ack = '';
      localdata.poid = '';
      localdata.pickid = '';
      localdata.salesStatus = '';
      localdata.sourceStatus = '';
      setTruck('');
      setStopNum(new DisplayValue('# #'));

      if (!idValidationResult.isValid) {
        setAlertMessage(idValidationResult.message);
        setAlertDialogData({
          showDialog: true,
          textFieldRefToBeFocused: idRef,
        });
      } else if (
        idValidationResult.type === IDType.SKU ||
        idValidationResult.type === IDType.PICKID
      ) {
        setAlertMessage(`ID Invalid: ${idValidationResult.type}`);
        setAlertDialogData({
          showDialog: true,
          textFieldRefToBeFocused: undefined,
        });
      } else {
        if (idValidationResult.type === IDType.EPC) {
          localdata.epc = idEntry.getDisplayValue();
        } else if (idValidationResult.type === IDType.POID) {
          localdata.poid = idEntry.getDisplayValue();
        } else if (idValidationResult.type === IDType.ACK2EPC) {
          localdata.epc = idValidationResult.id;
        }
        const validateStockWithActionResult = await appService.validateStockWithAction(
          '',
          formatDate(datePicker),
          '',
          localdata.epc,
          localdata.poid,
          ''
        );
        if (validateStockWithActionResult.valid) {
          action = 'A';
          setTruck(validateStockWithActionResult.truck);
          setStopNum(new DisplayValue(validateStockWithActionResult.stop));
          localdata.epc = validateStockWithActionResult.epc;
          localdata.ack = validateStockWithActionResult.acknum;
          localdata.poid = validateStockWithActionResult.polineitemlabel;
          localdata.pickid = validateStockWithActionResult.pickid;
          localdata.sourceStatus = validateStockWithActionResult.sourcestatus;

          let stype = validateStockWithActionResult.type;
          let pickup = validateStockWithActionResult.pickup;
          let deliverytype = validateStockWithActionResult.deliverytype;
          let sealed = validateStockWithActionResult.sealed;
          let manifestStatus = validateStockWithActionResult.manifest_status;

          if (stype === 'IST' && pickup === 'true') {
            setAlertMessage('Item is pickup');
            setAlertDialogData({
              showDialog: true,
              textFieldRefToBeFocused: undefined,
            });
          } else if (stype === 'DEL' && deliverytype === 'r') {
            setAlertMessage('Item is a home return, use returns screen.');
            setAlertDialogData({
              showDialog: true,
              textFieldRefToBeFocused: undefined,
            });
          } else if (sealed !== 'f' || manifestStatus !== 'O') {
            setAlertMessage('Invalid Function - check screen usage.');
            setAlertDialogData({
              showDialog: true,
              textFieldRefToBeFocused: undefined,
            });
          }
        } else {
          const checkResult = await appService.validateStock(
            localdata.poid ? IDType.POID : IDType.EPC,
            localdata.poid ? localdata.poid : localdata.epc
          );
          if (checkResult.valid) {
            localdata.epc = checkResult.epc;
            localdata.ack = checkResult.acknum;
            localdata.poid = checkResult.polineitemlabel;
            localdata.pickid = checkResult.pickid;
            localdata.salesStatus = checkResult.salesstatus;
            localdata.sourceStatus = checkResult.sourcestatus;
            localdata.description = checkResult.description;
            localdata.vsn = checkResult.vsn;

            truckRef.current?.focus();
          } else {
            setAlertMessage(checkResult.error);
            setAlertDialogData({
              showDialog: true,
              textFieldRefToBeFocused: undefined, 
            });
            setIdEntry(new DisplayValue(''));
          }
        }
      }
    }
  }

  function formatDate(dateEntry: Date | null): Date {
    if (dateEntry === null) {
      return DateWrapper.today();
    }
    return dateEntry;
  }

  function closeDialog() {
    setAlertDialogData({
      showDialog: false,
      textFieldRefToBeFocused: alertDialogData.textFieldRefToBeFocused,
    });
  }

  return (
    <div>
      <AlertDialog
        message={alertMessage}
        showDialog={alertDialogData.showDialog}
        closeDialog={() => {
          closeDialog();
        }}
      />
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignContent={'stretch'}
        spacing={1}
        marginRight="1em"
        display={'flex'}
        height={'86vh'}
      >
        <Stack height={'442px'} flexShrink={0}>
          <Stack direction="row" justifyContent="space-between" alignItems="baseline">
            <Typography paddingTop={3} paddingLeft={2} marginBottom={3} variant="h6">
              Manifest
            </Typography>
            <Button
              variant="contained"
              size="medium"
              onClick={() => {
                navigate('/Main');
              }}
            >
              Done
            </Button>
          </Stack>

          <Typography variant="subtitle1" lineHeight={1}>
            <Grid
              container
              direction={'row'}
              rowSpacing={2}
              alignItems={'flex-start'}
              justifyContent={'flex-end'}
              columnSpacing={1}
              paddingLeft={2}
            >
              <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
                Date
              </Grid>
              <Grid item xs={9} data-testid="mobileDatePicker">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    inputFormat="MM-dd-yyyy"
                    onChange={(newValue) => {
                      setDatePicker(newValue);
                    }}
                    value={datePicker}
                    renderInput={(params) => <TextField {...params} />}
                  ></MobileDatePicker>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
                ID
              </Grid>
              <Grid item xs={9}>
                <TextField
                  inputProps={{ 'aria-label': 'idInput' }}
                  onBlur={() => {
                    focusLostID();
                  }}
                  onChange={(eventData) => {
                    setIdEntry(new DisplayValue(eventData.target.value));
                  }}
                  value={idEntry.getDisplayValue()}
                  inputRef={idRef}
                  autoFocus
                  multiline
                  minRows={1}
                  maxRows={1}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
                Truck
              </Grid>
              <Grid item xs={9}>
                <TextField
                  inputProps={{ 'aria-label': 'truckInput' }}
                  multiline
                  minRows={1}
                  maxRows={1}
                  size="small"
                  fullWidth
                  onChange={(eventData) => {
                    setTruck(eventData.target.value);

                    onChangeTruck();
                  }}
                  onBlur={focusLostTruck}
                  value={truckEntry.getDisplayValue()}
                  inputRef={truckRef}
                />
              </Grid>
              <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
                Stop {stopNum.getDisplayValue()}
              </Grid>
              <Grid item xs={9}>
                <Typography></Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent={'left'}
                alignSelf={'center'}
                marginTop={11}
              >
                <Button fullWidth variant="contained" size="large" onClick={attemptAdd}>
                  ADD
                </Button>
              </Grid>
              <Grid item xs={12} justifyContent={'left'} alignSelf={'center'}>
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  onClick={attemptAddAndLoad}
                >
                  ADD & LOAD
                </Button>
              </Grid>
              <Grid item xs={12} justifyContent={'left'} alignSelf={'center'}>
                <Button
                  id="cancel-button"
                  fullWidth
                  variant="contained"
                  size="small"
                  onClick={() => {
                    navigate('/PrintLabels');
                  }}
                >
                  CANCEL
                </Button>
              </Grid>
            </Grid>
          </Typography>
        </Stack>
      </Stack>
    </div>
  );
}

export default ManifestMove;
