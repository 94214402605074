import { Button, Grid, Typography, Checkbox, TextField, Stack } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './printLabels.css';
import AppService, { Printer } from '../../Services/app.service';
import { SessionManager } from '../SessionManager';
import Utilities from '../Utils/Utilities';
import { DisplayValue } from '../Utils/DisplayValue';

function PrintLabels() {
  const [printers, setPrinters] = useState<Printer[]>([]);
  const [printLabels, setPrintLabels] = useState(false);
  const [batchNumber, setBatchNumber] = useState(new DisplayValue(''));
  const appService: AppService = new AppService();
  const navigate = useNavigate();
  const sessionManager: SessionManager = new SessionManager();

  useEffect(() => {
    sessionManager.setPrinter('');
    if (sessionManager.getPrintLabelsNextPath() !== '/RDCCPU') {
      appService.getPrinterList(sessionManager.getProfitCenterID()).then((printers) => {
        setPrinters(printers);
      });
    } else {
      appService.getReportPrinterList().then((printers) => {
        setPrinters(printers);
      });
    }
  }, []);

  function handleChange() {
    setPrintLabels(!printLabels);
  }

  function setPrinter(printer: Printer) {
    sessionManager.setPrinter(printer.name);
    sessionManager.setPrinterID(printer.id);
  }

  const hidePrinting = Utilities.stringToBool(process.env.REACT_APP_HIDE_PRINT);
  const hideRealListofPrinters = Utilities.stringToBool(
    process.env.REACT_APP_HIDE_REAL_LIST_OF_PRINTERS
  );

  return (
    <Stack direction="column" justifyContent="flex-start" spacing={1} margin="1em">
      <Stack direction="row" justifyContent="space-between" alignItems="baseline">
        <Typography variant="h6">
          <label>Print Labels</label>
        </Typography>
        <Button
          sx={{ width: '100px' }}
          variant="contained"
          size="small"
          id="next-button"
          onClick={() => {
            sessionManager.setBatchNumber(batchNumber.getDisplayValue());
            navigate(sessionManager.getPrintLabelsNextPath());
          }}
        >
          Next
        </Button>
      </Stack>
      <Grid
        container
        columnSpacing={2}
        paddingRight={2}
        paddingLeft={0}
        rowSpacing={2}
        alignItems="center"
      >
        <Grid item xs={2.3} textAlign="right" className="batch-row">
          <Typography variant="subtitle1">
            <label htmlFor="batch-input">Batch</label>
          </Typography>
        </Grid>
        <Grid item xs={9.7} className="batch-row">
          <Typography variant="subtitle1">
            <TextField
              variant="outlined"
              inputProps={{ 'aria-label': 'batch-input' }}
              id="batch-input"
              fullWidth
              size="small"
              value={batchNumber.getDisplayValue()}
              onChange={(eventData) => {
                setBatchNumber(new DisplayValue(eventData.target.value));
              }}
              autoComplete="false"
            ></TextField>
          </Typography>
        </Grid>
        <Grid item xs={1} paddingRight={4}>
          {!hidePrinting && (
            <Checkbox
              checked={printLabels}
              sx={{
                '& .MuiSvgIcon-root': { fontSize: 42 },
              }}
              onChange={handleChange}
            />
          )}
        </Grid>
        <Grid item xs={1}>
          {!hidePrinting && <PrintIcon />}
        </Grid>
        <Grid item xs={8} textAlign="left">
          <Typography variant="subtitle1">
            <label hidden={hidePrinting}>Print Labels</label>
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="center" paddingLeft={0.5}>
          <Typography variant="subtitle1">
            {!hideRealListofPrinters && (
              <select
                data-testid="select"
                onChange={(eventData) => {
                  if (eventData.target.value !== '- SELECT -') {
                    let printer = printers.filter(
                      (printer) => printer.name === eventData.target.value
                    );

                    setPrinter(printer[0]);
                  }
                }}
                hidden={hidePrinting}
                id="printers"
                disabled={!printLabels}
              >
                <option value={'- SELECT -'}>- SELECT -</option>
                {printers.map((printer) => {
                  return (
                    <option
                      data-testid="select-option"
                      key={printer.name}
                      value={printer.name}
                    >
                      {printer.name}
                    </option>
                  );
                })}
              </select>
            )}
            {hideRealListofPrinters && (
              <select
                data-testid="select"
                onChange={(eventData) => {
                  setPrinter({ name: eventData.target.value, id: '' });
                }}
                hidden={hidePrinting}
                id="printers"
                disabled={!printLabels}
              >
                <option value={''}>- SELECT -</option>
                <option
                  data-testid="select-option"
                  key={'TEST DESKTOP Zebra'}
                  value={'TEST DESKTOP Zebra'}
                >
                  TEST DESKTOP Zebra
                </option>
              </select>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} textAlign="center">
          {!hidePrinting && (
            <Typography variant="subtitle1" color={printLabels ? 'black' : 'gray'}>
              Select a printer from the dropdown
            </Typography>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
}

export default PrintLabels;
