const usernameKey: string = 'usernames';
const storeNumberKey: string = 'storeNumbers';

export class LoginConfiguration {
  addUser(username: string): void {
    const usernames = localStorage.getItem(usernameKey);
    if (usernames) {
      const arrayOfUsernames = JSON.parse(usernames);
      arrayOfUsernames.push(username);
      localStorage.setItem(usernameKey, JSON.stringify(arrayOfUsernames));
    } else {
      localStorage.setItem(usernameKey, JSON.stringify([username]));
    }
  }

  removeUser(username: string): void {
    const usernames = localStorage.getItem(usernameKey);
    if (usernames) {
      const arrayOfUsernames: string[] = JSON.parse(usernames);
      const index = arrayOfUsernames.indexOf(username);

      if (index > -1) {
        arrayOfUsernames.splice(index, 1);
        localStorage.setItem(usernameKey, JSON.stringify(arrayOfUsernames));
      }
    }
  }

  getAllUsernames(): string[] {
    const usernames = localStorage.getItem(usernameKey);
    if (usernames) return JSON.parse(usernames);
    return [];
  }

  addStoreNumber(storeNumber: string): void {
    const storeNumbers = localStorage.getItem(storeNumberKey);
    if (storeNumbers) {
      const arrayOfStoreNumbers = JSON.parse(storeNumbers);
      arrayOfStoreNumbers.push(storeNumber);
      localStorage.setItem(storeNumberKey, JSON.stringify(arrayOfStoreNumbers));
    } else {
      localStorage.setItem(storeNumberKey, JSON.stringify([storeNumber]));
    }
  }

  removeStoreNumber(storeNumber: string): void {
    const storeNumbers = localStorage.getItem(storeNumberKey);
    if (storeNumbers) {
      const arrayOfStoreNumbers: string[] = JSON.parse(storeNumbers);
      const index = arrayOfStoreNumbers.indexOf(storeNumber);

      if (index > -1) {
        arrayOfStoreNumbers.splice(index, 1);
        localStorage.setItem(storeNumberKey, JSON.stringify(arrayOfStoreNumbers));
      }
    }
  }

  getAllStoreNumbers(): string[] {
    const storeNumbers = localStorage.getItem(storeNumberKey);
    if (storeNumbers) return JSON.parse(storeNumbers);
    return [];
  }
}
