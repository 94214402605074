import { Grid } from '@mui/material';
import ReasonComponent from '../Returns Components/ReasonComponent';
import { GetReturnTypeResponse } from '../../Services/app.service';
import LocationComponent from '../Returns Components/LocationComponent';
import PriorityFillComponent from '../Returns Components/PriorityFillComponent';
import ManifestDate from '../Returns Components/ManifestDate';
import TruckField from '../Returns Components/TruckField';
import Utilities from '../Utils/Utilities';

interface ScheduledReturnsProps {
  returnTypeResponse: GetReturnTypeResponse;
  updateLocation: Function;
  updateReason: Function;
  updatePriorityFill: (priorityFill: string) => void;
}

function ScheduledReturns(props: ScheduledReturnsProps) {
  return (
    <>
      <TruckField label={'Truck'} truckName={props.returnTypeResponse.truck} />
      <LocationComponent updateLocation={props.updateLocation} />
      <ReasonComponent updateReason={props.updateReason} />
      <PriorityFillComponent
        updatePriorityFill={props.updatePriorityFill}
        returnTypeResponse={props.returnTypeResponse}
      />
      <ManifestDate manifestDateValue={props.returnTypeResponse.manifestDate} />
    </>
  );
}
export default ScheduledReturns;
