import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import Utilities from './Utils/Utilities';
import { DisplayValue } from './Utils/DisplayValue';
import RdcCpuScanner from './RdcCpuScanner';
import AppService, { Ticket, TicketResponse } from '../Services/app.service';
import AlertDialog from './Dialogs/AlertDialog';
import { DialogMetaDataWithMessage } from './DialogMetaDataWithMessage';

function RDCCPU() {
  const navigate = useNavigate();
  const appService = new AppService();
  const ticketRef = useRef<HTMLInputElement>();
  const [ticketEntry, setTicketEntry] = useState(new DisplayValue(''));
  const [isTicketFieldDisabled, setIsTicketFieldDisabled] = useState(false);
  const [dealerEntry, setDealerEntry] = useState<Ticket>();
  const [selectedTicket, setSelectedTicket] = useState<Ticket>();
  const [disabledFieldColor, setDisabledFieldColor] =
    useState<string>('rgb(143, 208, 236)');
  const [ticketSearchResponseTickets, setTicketSearchResponseTickets] = useState<
    Ticket[]
  >([]);
  const [alertDialogData, setAlertDialogData] = useState<DialogMetaDataWithMessage>({
    message: '',
    metaData: {
      showDialog: false,
      textFieldRefToBeFocused: undefined,
    },
  });
  useEffect(Utilities.onDialogWithMessageClosedFocusField(alertDialogData), [
    alertDialogData,
  ]);

  useEffect(Utilities.checkForScannedDataAndFocusNone(ticketEntry, ticketRef), [
    ticketEntry,
  ]);

  useEffect(() => {
    if (isTicketFieldDisabled) {
      setDisabledBackgroundColor(true);
    } else {
      setDisabledBackgroundColor(false);
    }
  }, [isTicketFieldDisabled]);

  function setDisabledBackgroundColor(setDisabled: boolean) {
    if (setDisabled) {
      setDisabledFieldColor('rgb(50, 50, 50, 0.15)');
    } else {
      setDisabledFieldColor('rgb(255, 255, 255)');
    }
  }

  async function focusLostTicket() {
    let ticket = ticketEntry.getDisplayValue();
    if (ticket !== '') {
      setIsTicketFieldDisabled(true);
      appService
        .rdcCpuTicketSearch(ticket)
        .then((result: TicketResponse) => {
          if (!result.valid) {
            setIsTicketFieldDisabled(false);
            setAlertDialogData({
              message: result.error,
              metaData: { showDialog: true, textFieldRefToBeFocused: ticketRef },
            });
          } else {
            setTicketSearchResponseTickets(result.tickets);
            if (result.tickets.length === 1) {
              setSelectedTicket(result.tickets[0]);
            }
          }
        })
        .catch((error) => {
          setIsTicketFieldDisabled(false);
        });
    }
  }

  async function enterButtonClicked() {
    if (dealerEntry !== undefined) {
      setSelectedTicket(dealerEntry);
    } else {
      setAlertDialogData({
        message: 'Please select a dealer.',
        metaData: { showDialog: true, textFieldRefToBeFocused: undefined },
      });
    }
  }

  function deliveryStatementButtonClicked() {
    setSelectedTicket(undefined);
    setTicketEntry(new DisplayValue(''));
    setIsTicketFieldDisabled(false);
    ticketRef.current?.focus();
  }
  return (
    <>
      <AlertDialog
        message={alertDialogData?.message}
        showDialog={alertDialogData?.metaData.showDialog}
        closeDialog={() => {
          setAlertDialogData({
            message: '',
            metaData: {
              showDialog: false,
              textFieldRefToBeFocused: alertDialogData.metaData.textFieldRefToBeFocused,
            },
          });
        }}
      />
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignContent={'stretch'}
        spacing={1}
        marginRight="1em"
        marginLeft="1em"
        display={'flex'}
        height={'76vh'}
        flexDirection={'column'}
      >
        <Stack height={'442px'} flexShrink={0}>
          <Stack direction="row" justifyContent="space-between" alignItems="baseline">
            <Typography paddingTop={3} marginBottom={3} variant="h6">
              <label>RMS Scan - CPU</label>
            </Typography>

            <Button
              sx={{ width: '100px' }}
              variant="contained"
              size="small"
              onClick={() => {
                navigate('/Main');
              }}
            >
              DONE
            </Button>
          </Stack>
          <Grid
            container
            direction={'row'}
            rowSpacing={2}
            alignItems={'flex-start'}
            justifyContent={'flex-end'}
            columnSpacing={1}
          >
            <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
              Ticket
            </Grid>
            <Grid item xs={9}>
              <TextField
                autoFocus
                disabled={isTicketFieldDisabled}
                inputRef={ticketRef}
                style={{ backgroundColor: disabledFieldColor }}
                value={ticketEntry.getDisplayValue()}
                onChange={(eventData) => {
                  setTicketEntry(new DisplayValue(eventData.target.value));
                }}
                onBlur={() => {
                  focusLostTicket();
                }}
                inputProps={{ 'aria-label': 'ticketInput' }}
                data-testid="ticketInput"
                multiline
                minRows={1}
                maxRows={1}
                size="small"
                fullWidth
              />
            </Grid>
            {ticketSearchResponseTickets.length > 1 && !selectedTicket && (
              <>
                <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
                  <label htmlFor="dealerDropdown">Dealer</label>
                </Grid>
                <Grid item xs={9} id="dealerDropdown">
                  <select
                    data-testid="dealerDropdown"
                    style={{
                      borderColor: 'black',
                      borderRadius: '4px',
                      width: '100%',
                      fontSize: 'medium',
                      backgroundColor: 'white',
                      paddingTop: '0.25em',
                      paddingBottom: '0.25em',
                    }}
                    onChange={(eventData) => {
                      let ticket = ticketSearchResponseTickets.filter(
                        (ticket) => ticket.market === eventData.target.value
                      );
                      if (ticket.length !== 0) {
                        setDealerEntry(ticket[0]);
                      }
                    }}
                  >
                    <option value="">- SELECT -</option>
                    {ticketSearchResponseTickets.map((ticket) => {
                      return (
                        <option key={ticket.market} value={ticket.market}>
                          {ticket.market}
                        </option>
                      );
                    })}
                  </select>
                </Grid>
                <Grid item>
                  <Button
                    id="enter-button"
                    fullWidth
                    variant="contained"
                    size="medium"
                    onClick={enterButtonClicked}
                  >
                    ENTER
                  </Button>
                </Grid>
              </>
            )}
            {selectedTicket && (
              <RdcCpuScanner
                ticket={selectedTicket}
                deliveryStatementButtonClicked={deliveryStatementButtonClicked}
              />
            )}
          </Grid>
        </Stack>
        {!selectedTicket && (
          <Stack
            direction="column"
            justifyContent="space-between"
            spacing={2}
            marginRight="1em"
            marginLeft="1em"
          >
            <Button
              fullWidth
              variant="contained"
              sx={{ alignSelf: '', marginTop: 'auto' }}
              size="small"
              onClick={() => {
                navigate('/PrintLabels');
              }}
            >
              CANCEL
            </Button>
          </Stack>
        )}
      </Stack>
    </>
  );
}

export default RDCCPU;
