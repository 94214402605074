import { Grid, TextField } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Validation, { ValidationResult } from '../../Services/validation.service';
import AlertDialog from '../Dialogs/AlertDialog';
import { DialogMetaDataWithMessage } from '../DialogMetaDataWithMessage';
import { DisplayValue } from '../Utils/DisplayValue';
import Utilities from '../Utils/Utilities';

export interface LocationComponentProps {
  updateLocation: Function;
}

function LocationComponent(props: LocationComponentProps) {
  const locationRef = useRef<HTMLInputElement>();

  const [alertDialogData, setAlertDialogData] = useState<DialogMetaDataWithMessage>({
    message: '',
    metaData: {
      showDialog: false,
      textFieldRefToBeFocused: undefined,
    },
  });

  let [locationEntry, setLocationEntry] = useState<DisplayValue>(new DisplayValue(''));

  useEffect(Utilities.onDialogWithMessageClosedFocusField(alertDialogData), [alertDialogData]);
  useEffect(Utilities.checkForScannedDataAndFocusNone(locationEntry, locationRef), [
    locationEntry,
  ]);

  async function locationFocusLost() {
    const location = locationEntry.getDisplayValue();
    const locationValidationResult: ValidationResult =
      await new Validation().validateLocation(location);
    if (locationValidationResult.isValid) {
      props.updateLocation(location);
    } else {
      setAlertDialogData({
        message: locationValidationResult.message,
        metaData: {
          showDialog: true,
          textFieldRefToBeFocused: locationRef,
        },
      });
    }
  }

  return (
    <>
      <AlertDialog
        message={alertDialogData.message}
        showDialog={alertDialogData.metaData.showDialog}
        closeDialog={() => {
          setAlertDialogData({
            message: '',
            metaData: {
              showDialog: false,
              textFieldRefToBeFocused: alertDialogData.metaData.textFieldRefToBeFocused,
            },
          });
        }}
      ></AlertDialog>
      <Grid item xs={3} justifyContent={'left'} alignSelf={'center'}>
        Location
      </Grid>
      <Grid item xs={9}>
        <TextField
          inputProps={{ 'aria-label': 'locationInput' }}
          data-testid="locationInput"
          multiline
          minRows={1}
          maxRows={1}
          size="small"
          fullWidth
          inputRef={locationRef}
          value={locationEntry.getDisplayValue()}
          onBlur={locationFocusLost}
          onChange={(eventData) => {
            setLocationEntry(new DisplayValue(eventData.target.value));
          }}
        />
      </Grid>
    </>
  );
}

export default LocationComponent;
