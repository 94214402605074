import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertDialog from './component/Dialogs/AlertDialog';

function SessionErrorDialog() {
  const navigate = useNavigate();
  const [showSessionTimeoutDialog, setShowSessionTimeoutDialog] = useState(false);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        let elementLoginResult: Element | null = parseXmlResponse(
          response,
          'loginResult'
        );
        let xmlResponseElement: Element | null = parseXmlResponse(
          response,
          'xmlResponse'
        );

        let loggedIn: string | null | undefined =
          elementLoginResult?.getAttribute('loggedin');
        let errorLoginResult: string | null | undefined =
          elementLoginResult?.getAttribute('error');
        let errorXmlResponse: string | null | undefined =
          xmlResponseElement?.getAttribute('error');

        if (
          loggedIn === 'false' &&
          errorLoginResult === 'Please enter a Username and Password.'
        ) {
          setShowSessionTimeoutDialog(true);
          return new Promise(() => {});
        } else if (
          errorXmlResponse &&
          !response.config.url?.includes('XMLGetReportPrinters.xdo')
        ) {
          setShowErrorDialog(true);
          setErrorMessage(errorXmlResponse);
        }

        return response;
      },
      function (error) {
        setErrorMessage(error.message);
        setShowErrorDialog(true);
        return Promise.reject(error);
      }
    );
  });

  return (
    <div>
      <AlertDialog
        message={'Session timed out. Please log in again.'}
        showDialog={showSessionTimeoutDialog}
        closeDialog={() => {
          setShowSessionTimeoutDialog(false);
          navigate('/');
        }}
      ></AlertDialog>
      <AlertDialog
        message={
          'Error: Unable to connect to the RMS Server. Error message is "' +
          errorMessage +
          '"'
        }
        showDialog={showErrorDialog}
        closeDialog={() => {
          setShowErrorDialog(false);
        }}
      ></AlertDialog>
    </div>
  );
}

function parseXmlResponse(
  response: AxiosResponse<any, any>,
  xmlNodeName: string
): Element | null {
  const parser = new DOMParser();
  const xml: Document = parser.parseFromString(response['data'], 'text/xml');
  return xml.querySelector(xmlNodeName);
}

export default SessionErrorDialog;
